import { fromJSDateToISO } from "src/common/utils/dataFormat";
import { IVanFilter } from "src/models/IVanFilter";

const transformValueFromURL = (name: keyof IVanFilter, value: any) => {
  switch (name) {
    case "start_date": {
      return fromJSDateToISO(+value * 1000);
    }
    case "end_date": {
      return fromJSDateToISO(+value * 1000);
    }
    case "features": {
      return value.length
        ? (value as string).split("_").map((v) => v.replaceAll("-", " "))
        : [];
    }
    case "price": {
      const price = value.split("-");
      return {
        startPrice: +price[0],
        endPrice: +price[1],
      };
    }
    case "seats": {
      return +value;
    }
    case "page": {
      return +value;
    }
    case "take": {
      return +value;
    }
    case "tags": {
      return value.length ? value : [];
    }
    default:
      return value;
  }
};

export default transformValueFromURL;
