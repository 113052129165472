import {
    SubLocationAction,
    SubLocationActionEnum,
    SubLocationState,
  } from './types';
  
  const initialState: SubLocationState = {
    sublocationList: [],
    isLoading: {
      all: false,
    },
  };
  
  const sublocation = (
    state = initialState,
    action: SubLocationAction
  ): SubLocationState => {
    switch (action.type) {
      case SubLocationActionEnum.REQUEST_SUBLOCATIONS:
        return { ...state, isLoading: { ...state.isLoading, all: true } };
      case SubLocationActionEnum.RESULT_SUBLOCATIONS:
        return {
          ...state,
          sublocationList: action.data,
          isLoading: { ...state.isLoading, all: !action.data },
        };
      default:
        return state;
    }
  };
  export default sublocation;
  