import { IBase } from "src/models/types";

export enum FeatureActionEnum {
  REQUEST_FEATURES = "REQUEST_FEATURES",
  RESULT_FEATURES = "RESULT_FEATURES",
  ERROR_FEATURES = "ERROR_FEATURES",
}

export interface FeatureState {
  featureList: IBase[];
  total?: number;
  isLoading: {
    all: boolean;
  };
}

export interface RequestFeatureAction {
  type: FeatureActionEnum.REQUEST_FEATURES;
  payload?: any;
}

export interface ResultFeatureAction {
  type: FeatureActionEnum.RESULT_FEATURES;
  data: IBase[];
}

export interface ErrorFeatureAction {
  type: FeatureActionEnum.ERROR_FEATURES;
  payload?: any;
}

export type FeatureAction =
  | RequestFeatureAction
  | ResultFeatureAction
  | ErrorFeatureAction;
