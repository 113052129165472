import { ContractAction, ContractActionEnum, ContractState } from "./types";

const initialState: ContractState = {
  isLoading: {
    get: false,
    sign: false,
  },
};

const contract = (
  state = initialState,
  action: ContractAction
): ContractState => {
  switch (action.type) {
    case ContractActionEnum.REQUEST_CONTRACT:
      return {
        ...state,
        isLoading: { ...state.isLoading, get: true },
      };
    case ContractActionEnum.RESULT_CONTRACT:
      return {
        ...state,
        contract: action.data,
        isLoading: { ...state.isLoading, get: !action.data },
        error: action.data.id && 'The contract has already been signed',
      };
    case ContractActionEnum.SIGN_CONTRACT:
      return {
        ...state,
        isLoading: { ...state.isLoading, sign: true },
      };
    case ContractActionEnum.RESULT_SIGN_CONTRACT:
      return {
        ...state,
        isLoading: { ...state.isLoading, sign: false },
        contract: action.data,
        result: action.status,
      };
    case ContractActionEnum.ERROR_SIGN_CONTRACT: {
      return {
        ...state,
        isLoading: { ...state.isLoading, sign: false },
        error: action.statusText,
      };
    }
    default:
      return state;
  }
};
export default contract;
