import axios from "axios";
import { put, takeLatest } from "redux-saga/effects";
import { LocationActionEnum } from "src/store/reducers/location/types";
import { LocationActionCreators } from "src/store/reducers/location/action-creators";

function* fetchGetLocations() {
  try {
    const { data } = yield axios.get(`${process.env.REACT_APP_API}/location`);
    yield put(LocationActionCreators.resultGetLocations(data));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetLocations() {
  yield takeLatest(LocationActionEnum.REQUEST_LOCATIONS, fetchGetLocations);
}
