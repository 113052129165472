import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { VanActionEnum } from "src/store/reducers/van/types";
import { VanActionCreators } from "src/store/reducers/van/action-creators";

function* fetchGetVan({ id }: ReturnType<typeof VanActionCreators.getVan>) {
  try {
    const { data } = yield instance.get(`van/${id}`);
    yield put(VanActionCreators.resultGetVan(data));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetVan() {
  yield takeLatest(VanActionEnum.REQUEST_VAN, fetchGetVan);
}
