import { IBase } from "src/models/types";
import {
  FeatureActionEnum,
  RequestFeatureAction,
  ResultFeatureAction,
} from "./types";

export const FeatureActionCreators = {
  getFeatures: (): RequestFeatureAction => ({
    type: FeatureActionEnum.REQUEST_FEATURES,
  }),
  resultGetFeatures: (data: IBase[]): ResultFeatureAction => ({
    type: FeatureActionEnum.RESULT_FEATURES,
    data,
  }),
};
