import { Currency } from "dinero.js";

export enum SubPriceActionEnum {
  REQUEST_SUBPRICES = 'REQUEST_SUBPRICES',
  RESULT_SUBPRICES = 'RESULT_SUBPRICES',
  ERROR_SUBPRICES = 'ERROR_SUBPRICES',
}

export interface SubPrice {
  id: number;
  price: number;
  currency: Currency;
  location: string;
  isDefault: boolean;
  host_id?: number;
}

export interface SubPriceState {
    subPriceList: SubPrice[];
    isLoading: {
    all: boolean;
  };
}

export interface RequestSubPriceAction {
  type: SubPriceActionEnum.REQUEST_SUBPRICES;
}

export interface ResultSubPriceAction {
  type: SubPriceActionEnum.RESULT_SUBPRICES;
  data: SubPrice[];
}

export interface ErrorSubPriceAction {
  type: SubPriceActionEnum.ERROR_SUBPRICES;
  payload?: any;
}

export type SubPriceAction =
  | RequestSubPriceAction
  | ResultSubPriceAction
  | ErrorSubPriceAction;
