import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "src/components/App";
import { Provider } from "react-redux";
import "src/translation/init";
import { store } from "src/store";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
