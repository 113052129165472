import { Checkbox, FormControlLabel } from "@mui/material";
import { FC } from "react";
import Icons from "src/common/icons";

const Extra: FC = () => {
  const extraArray = [
    "Unlimited Miles",
    "Delivered to/from you",
    "Unmarked/No logos",
  ];

  return (
    <div className="filter-extra">
      <div className="filter__title">Extras</div>
      <div className="filter__list">
        {extraArray.map((extra, i) => (
          <FormControlLabel
            key={i}
            control={<Checkbox checked checkedIcon={<Icons.Check />} />}
            label={extra}
          />
        ))}
      </div>
    </div>
  );
};
export default Extra;
