import { put, takeLatest } from 'redux-saga/effects';
import { DriverActionCreators } from 'src/store/reducers/driver/action-creators';
import { DriverActionEnum } from 'src/store/reducers/driver/types';
import instance from '../set';

function* addDriver({
  drivers,
  id,
}: ReturnType<typeof DriverActionCreators.addDriver>) {
  try {
    const { status } = yield instance.post(`driver/add/${id}`, { drivers });
    yield put(DriverActionCreators.resultAddDriver(status));
  } catch (error) {
    console.log('error :>> ', error);
  }
}
export function* actionWatcherAddDriver() {
  yield takeLatest(DriverActionEnum.ADD_DRIVER, addDriver);
}
