import { DriverAction, DriverActionEnum, DriverState } from './types';

const initialState: DriverState = {
  isLoading: {
    add: false,
  },
  driver: {},
};

const driver = (state = initialState, action: DriverAction): DriverState => {
  switch (action.type) {
    case DriverActionEnum.ADD_DRIVER:
      return {
        ...state,
        isLoading: { ...state.isLoading, add: true },
        driver: action.drivers,
      };
    case DriverActionEnum.RESULT_ADD_DRIVER:
      return {
        ...state,
        isLoading: { ...state.isLoading, add: false },
        driver: action.driver,
      };

    default:
      return state;
  }
};
export default driver;
