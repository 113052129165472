import { FC } from 'react';
import Icons from 'src/common/icons';
import './styles.scss';

interface IProps {
  title: string;
  status: string;
  statusText: string;
  handleClick: any;
}

const Step: FC<IProps> = ({ title, status, statusText, handleClick }) => {

  let icon = <></>;
  let description = '';
  let statusIcon = <></>;

  switch (title) {
    case 'Upload Docs':
      icon = <Icons.UploadDocs />;
      description = '';
      break;
    case 'Invite Drivers':
      icon = <Icons.InviteDrivers />;
      description = 'By email, free, easy, optional';
      break;
    case 'Payment':
      icon = <Icons.Payments />;
      description = 'Securely pay 72h before your trip';
      break;
    case 'E-sign':
      icon = <Icons.Esign />;
      description = 'We email a PDF, Printing optional';
      break;
    case 'Pickup info & keys':
      icon = <Icons.PickupInfo />;
      description = 'Your host will text or email.';
      break;
    default:
      icon = <></>;
      description = '';
      break;
  }

  switch (status) {
    case 'complete':
      statusIcon = <Icons.CompleteStatus />;
      break;
    case 'completed':
      statusIcon = <Icons.CompletedStatus />;
      break;
    case 'closed':
      statusIcon = <Icons.ClosedStatus />;
      break;
    default:
      statusIcon = <></>;
      break;
  }

  return (
    <div className={
      `${(status === 'complete' || title === 'Invite Drivers') && 'active'}`
    }>
      <div
        className='step'
        onClick={handleClick}
      >
        <>{icon}</>
        <h5>{title}</h5>
        <h6>{description}</h6>
        {title === 'Upload Docs' && (
          status === 'complete'
            ? <div className='step__docs missing'>Missing</div>
            : <div className='step__docs done'>Done</div>
        )}
        <div className='step__status'>
          <>{statusIcon}</>
          <span className={`step__status__title ${status}`}>{statusText}</span>
        </div>
      </div>
    </div>
  );
};

export default Step;
