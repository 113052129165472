import { FC } from "react";
import { colorSet } from "src/theme";
interface IIcon {
  classes?: string;
}

export const GradientIcon: FC<{
  name: string;
  color1: string;
  color2: string;
}> = ({ name, color1, color2 }) => (
  <defs>
    <linearGradient id={name} gradientTransform="rotate(35)">
      <stop offset="0%" stopColor={color1} />
      <stop offset="100%" stopColor={color2} />
    </linearGradient>
  </defs>
);

const Point = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="37"
    height="37"
    viewBox="0 0 37 37"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="18.5"
      cy="18.5"
      r="18.25"
      fill="#F7F9FC"
      stroke="#7D879C"
      strokeWidth="0.5"
    />
    <path
      d="M24.75 16.5C24.75 21.75 18 26.25 18 26.25C18 26.25 11.25 21.75 11.25 16.5C11.25 14.7098 11.9612 12.9929 13.227 11.727C14.4929 10.4612 16.2098 9.75 18 9.75C19.7902 9.75 21.5071 10.4612 22.773 11.727C24.0388 12.9929 24.75 14.7098 24.75 16.5Z"
      fill="#2B3445"
    />
    <path
      d="M18 18.75C19.2426 18.75 20.25 17.7426 20.25 16.5C20.25 15.2574 19.2426 14.25 18 14.25C16.7574 14.25 15.75 15.2574 15.75 16.5C15.75 17.7426 16.7574 18.75 18 18.75Z"
      fill="#F7F9FC"
    />
  </svg>
);
const Bagadge = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="17"
    height="23"
    viewBox="0 0 17 23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 0.766667C4 0.563334 4.07902 0.368329 4.21967 0.224551C4.36032 0.0807735 4.55109 0 4.75 0H12.25C12.4489 0 12.6397 0.0807735 12.7803 0.224551C12.921 0.368329 13 0.563334 13 0.766667C13 0.969999 12.921 1.165 12.7803 1.30878C12.6397 1.45256 12.4489 1.53333 12.25 1.53333H11.875V3.06667H13.375C14.2701 3.06667 15.1285 3.43015 15.7615 4.07715C16.3944 4.72415 16.75 5.60167 16.75 6.51667V18.0167C16.7502 18.8654 16.4443 19.6844 15.8908 20.3171C15.3373 20.9497 14.5751 21.3517 13.75 21.446V22.2333C13.75 22.4367 13.671 22.6317 13.5303 22.7754C13.3897 22.9192 13.1989 23 13 23C12.8011 23 12.6103 22.9192 12.4697 22.7754C12.329 22.6317 12.25 22.4367 12.25 22.2333V21.4667H4.75V22.2333C4.75 22.4367 4.67098 22.6317 4.53033 22.7754C4.38968 22.9192 4.19891 23 4 23C3.80109 23 3.61032 22.9192 3.46967 22.7754C3.32902 22.6317 3.25 22.4367 3.25 22.2333V21.446C2.42486 21.3517 1.66267 20.9497 1.10921 20.3171C0.555743 19.6844 0.249841 18.8654 0.25 18.0167V6.51667C0.25 5.60167 0.605579 4.72415 1.23851 4.07715C1.87145 3.43015 2.72989 3.06667 3.625 3.06667H5.5V1.53333H4.75C4.55109 1.53333 4.36032 1.45256 4.21967 1.30878C4.07902 1.165 4 0.969999 4 0.766667ZM7 1.53333V3.06667H10.375V1.53333H7ZM4.75 7.66667C4.55109 7.66667 4.36032 7.74744 4.21967 7.89122C4.07902 8.035 4 8.23 4 8.43333C4 8.63667 4.07902 8.83167 4.21967 8.97545C4.36032 9.11923 4.55109 9.2 4.75 9.2H12.25C12.4489 9.2 12.6397 9.11923 12.7803 8.97545C12.921 8.83167 13 8.63667 13 8.43333C13 8.23 12.921 8.035 12.7803 7.89122C12.6397 7.74744 12.4489 7.66667 12.25 7.66667H4.75Z"
      fill="#2B3445"
    />
  </svg>
);

const Bycicle = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="22"
    height="17"
    viewBox="0 0 22 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0.800029C10.0536 0.786029 10.0536 2.21403 11 2.20003H13.1V3.97243L9.1758 7.24003L7.8122 3.60003H8.2C9.1464 3.61403 9.1464 2.18603 8.2 2.20003H5.4C4.4536 2.18603 4.4536 3.61403 5.4 3.60003H6.3128L7.5602 6.92503C6.89075 6.58444 6.15109 6.40468 5.4 6.40003C2.7022 6.40003 0.5 8.60223 0.5 11.3C0.5 13.9978 2.7022 16.2 5.4 16.2C8.0978 16.2 10.3 13.9978 10.3 11.3C10.3 10.362 10.02 9.49263 9.558 8.74643L13.5074 5.45643L14.1556 7.08043C12.694 7.93163 11.7 9.49543 11.7 11.3C11.7 13.9978 13.9022 16.2 16.6 16.2C19.2978 16.2 21.5 13.9978 21.5 11.3C21.5 8.60223 19.2978 6.40003 16.6 6.40003C16.2038 6.40003 15.8244 6.46023 15.4548 6.54983L14.5 4.16283V1.50003C14.5 1.31438 14.4263 1.13633 14.295 1.00505C14.1637 0.873779 13.9857 0.800029 13.8 0.800029H11ZM5.4 7.80003C6.19277 7.80199 6.961 8.07519 7.577 8.57423L4.952 10.761C4.2366 11.3588 5.1312 12.434 5.848 11.839L8.473 9.65083C8.7362 10.1436 8.9 10.7008 8.9 11.3C8.9 13.2418 7.3418 14.8 5.4 14.8C3.4582 14.8 1.9 13.2418 1.9 11.3C1.9 9.35823 3.4582 7.80003 5.4 7.80003ZM16.6 7.80003C18.5418 7.80003 20.1 9.35823 20.1 11.3C20.1 13.2418 18.5418 14.8 16.6 14.8C14.6582 14.8 13.1 13.2418 13.1 11.3C13.0987 10.7202 13.2421 10.1493 13.5171 9.63886C13.7921 9.12844 14.1901 8.69467 14.675 8.37683L15.949 11.5604C16.2934 12.4326 17.601 11.9104 17.251 11.0396L15.9798 7.86163C16.18 7.82803 16.3872 7.80003 16.6 7.80003Z"
      fill="#7D879C"
    />
  </svg>
);

const Seat = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="15"
    height="21"
    viewBox="0 0 15 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 16C3 16 0 8 0 4C0 0 2 0 2 0H3C3 0 4 0 4 1C4 2 3 2 3 4C3 6 6 8 6 11C6 14 3 16 3 16ZM8 15C7 15 4 17.5 4 17.5C3.7 17.7 3.8 18 4 18.3C4 18.3 5 20.1 7 20.1H13C14.1 20.1 15 19.2 15 18.1V17.1C15 16 14.1 15.1 13 15.1H8V15Z"
      fill="#2B3445"
    />
  </svg>
);

const DoubleArrows = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="23"
    height="16"
    viewBox="0 0 23 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3535 9.30002L11.319 5.70002C11.2069 5.60002 11.1277 5.49169 11.0814 5.37502C11.0343 5.25836 11.0108 5.13336 11.0108 5.00002C11.0108 4.86669 11.0343 4.74169 11.0814 4.62502C11.1277 4.50836 11.2069 4.40002 11.319 4.30002L15.3535 0.700024C15.5776 0.500024 15.8391 0.400024 16.138 0.400024C16.4368 0.400024 16.6983 0.500024 16.9224 0.700024C17.1466 0.900024 17.2586 1.13736 17.2586 1.41202C17.2586 1.68736 17.1466 1.92502 16.9224 2.12502L14.8211 4.00002H21.7414C22.0589 4.00002 22.3249 4.09569 22.5393 4.28702C22.7545 4.47902 22.8621 4.71669 22.8621 5.00002C22.8621 5.28336 22.7545 5.52069 22.5393 5.71202C22.3249 5.90402 22.0589 6.00002 21.7414 6.00002H14.8211L16.9224 7.87502C17.1466 8.07502 17.2586 8.30836 17.2586 8.57502C17.2586 8.84169 17.1466 9.07502 16.9224 9.27502C16.6983 9.47502 16.4417 9.58336 16.1525 9.60003C15.8626 9.61669 15.5963 9.51669 15.3535 9.30002ZM6.38796 15.275C6.6121 15.475 6.87359 15.579 7.17244 15.587C7.47129 15.5957 7.73279 15.5 7.95692 15.3L11.9914 11.7C12.1035 11.6 12.183 11.4917 12.2301 11.375C12.2764 11.2584 12.2996 11.1334 12.2996 11C12.2996 10.8667 12.2764 10.7417 12.2301 10.625C12.183 10.5084 12.1035 10.4 11.9914 10.3L7.95692 6.70002C7.73279 6.50002 7.47129 6.40002 7.17244 6.40002C6.87359 6.40002 6.6121 6.50002 6.38796 6.70002C6.16382 6.90002 6.05175 7.13736 6.05175 7.41202C6.05175 7.68736 6.16382 7.92502 6.38796 8.12502L8.48925 10H1.56899C1.25146 10 0.985487 10.0957 0.771062 10.287C0.55589 10.479 0.448303 10.7167 0.448303 11C0.448303 11.2834 0.55589 11.5207 0.771062 11.712C0.985487 11.904 1.25146 12 1.56899 12H8.48925L6.38796 13.875C6.16382 14.075 6.05175 14.3084 6.05175 14.575C6.05175 14.8417 6.16382 15.075 6.38796 15.275Z"
      fill="#757575"
    />
  </svg>
);

const Arrow = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="8"
    height="5"
    viewBox="0 0 8 5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z" />
  </svg>
);

const BigArrow = ({ classes }: IIcon) => (
  <svg
    className={classes}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 47 47"
  >
    <g>
      <path
        d="M46.255,35.941c-0.256,0-0.512-0.098-0.707-0.293l-21.921-21.92l-21.92,21.92c-0.391,0.391-1.023,0.391-1.414,0
		c-0.391-0.391-0.391-1.023,0-1.414L22.92,11.607c0.391-0.391,1.023-0.391,1.414,0l22.627,22.627c0.391,0.391,0.391,1.023,0,1.414
		C46.767,35.844,46.511,35.941,46.255,35.941z"
      />
    </g>
  </svg>
);

const Folder = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00043 31.7332C1.00043 34.0895 2.90336 35.9999 5.25043 35.9999H30.7504C33.0975 35.9999 35.0004 34.0895 35.0004 31.7332V13.5999C35.0004 11.2436 33.0975 9.33321 30.7504 9.33321H21.1879C17.4033 9.33321 18.0004 3.99988 12.2236 3.99988H5.25043C2.90336 3.99988 1.00043 5.91028 1.00043 8.26655V31.7332Z"
      fill={colorSet.primaryGreen}
    />
    <path
      d="M35.1796 13.9999H28.2435C22.4956 13.9999 22.5537 19.238 18.7876 19.238H9.27462C6.93971 19.238 4.8553 21.1143 4.61748 23.4284L4.09743 27.4869L3.54357 31.8094L3.52243 31.8073C3.40087 32.4422 2.81212 32.8539 2.14833 32.8539C1.46868 32.8539 0.979284 32.3227 1.01311 31.6564C1.01099 31.7078 1.00043 31.757 1.00043 31.8094C1.00043 33.9088 2.56267 35.6311 4.59422 35.936C4.78131 35.9758 4.98743 35.9999 5.22842 35.9999H32.7104C35.0453 35.9999 37.1308 34.1236 37.3676 31.8094L38.9784 18.1904C39.2152 15.8762 37.5145 13.9999 35.1796 13.9999Z"
      fill={colorSet.primaryGreen}
    />
  </svg>
);
const Close = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00004 0.333374C3.31337 0.333374 0.333374 3.31337 0.333374 7.00004C0.333374 10.6867 3.31337 13.6667 7.00004 13.6667C10.6867 13.6667 13.6667 10.6867 13.6667 7.00004C13.6667 3.31337 10.6867 0.333374 7.00004 0.333374ZM9.86671 9.86671C9.60671 10.1267 9.18671 10.1267 8.92671 9.86671L7.00004 7.94004L5.07337 9.86671C4.81337 10.1267 4.39337 10.1267 4.13337 9.86671C3.87337 9.60671 3.87337 9.18671 4.13337 8.92671L6.06004 7.00004L4.13337 5.07337C3.87337 4.81337 3.87337 4.39337 4.13337 4.13337C4.39337 3.87337 4.81337 3.87337 5.07337 4.13337L7.00004 6.06004L8.92671 4.13337C9.18671 3.87337 9.60671 3.87337 9.86671 4.13337C10.1267 4.39337 10.1267 4.81337 9.86671 5.07337L7.94004 7.00004L9.86671 8.92671C10.12 9.18004 10.12 9.60671 9.86671 9.86671Z"
      fill="#6D727A"
    />
  </svg>
);
const BiGCamera = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="90"
    height="74"
    viewBox="0 0 90 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="12" width="90" height="62" rx="7" fill="#2E3541" />
    <circle cx="45" cy="43" r="26" fill="white" fillOpacity="0.2" />
    <circle cx="45" cy="43" r="17" fill="#252A34" />
    <rect
      x="73"
      y="17"
      width="12"
      height="8"
      rx="1"
      fill="white"
      fillOpacity="0.2"
    />
    <circle cx="11" cy="63" r="5" fill="white" fillOpacity="0.2" />
    <path
      d="M31.1312 2.82366C31.6476 1.14529 33.1983 0 34.9543 0H55.0457C56.8017 0 58.3524 1.14529 58.8688 2.82366L62 13H28L31.1312 2.82366Z"
      fill="#2E3541"
    />
    <path
      d="M36.4805 6.21216C36.7957 5.47679 37.5187 5 38.3188 5H51.6812C52.4813 5 53.2043 5.47679 53.5195 6.21216L56 12H34L36.4805 6.21216Z"
      fill="white"
      fillOpacity="0.2"
    />
    <circle cx="38" cy="36" r="3" fill="white" fillOpacity="0.45" />
    <circle cx="42" cy="44" r="4" fill="white" fillOpacity="0.45" />
  </svg>
);

const NewLogoGr = ({ classes }: IIcon) => (
  <div className={classes}>
    <svg
      width="100pt"
      height="40pt"
      viewBox="0 40 200 80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          x1="464.153"
          y1="-441.091"
          x2="464.153"
          y2="213.825"
          id="gradient-0"
          gradientTransform="matrix(-0.754591, -0.656194, 0.336395, -0.386837, 1393.025762, 1140.541748)"
        >
          <stop offset="0" stopColor="rgb(75, 86, 107)" />
          <stop offset="1" stopColor="rgb(195, 255, 75)" />
        </linearGradient>
      </defs>
      <g transform="matrix(0.1, 0, 0, -0.1, -5.421927, 154.524918)">
        <path
          d="M 825 1095 C -128.635 1093.917 324.622 1050.981 1068.555 1061.445 C 1341.677 1065.287 1412 1048 1489 981 C 1517 956 1523 957 1375 958 C 1320 959 1283 957 1292 954 C 1301 951 1312 936 1315 920 C 1318 904 1327 886 1335 880 C 1362 858 1523 810 1593 804 C 1664 797 1713.239 770.99 1763.773 740.821 C 1795.175 722.074 1791.429 737.687 1810.59 659.913 C 1811.786 655.058 1817 624 1817 616 C 1817 597 1798 601 1772 626 C 1744 652 1677 656 1628 634 C 1607 625 1593 614 1595 609 C 1598 605 1596 599 1591 596 C 1580 589 1566 629 1581 644 C 1593 657 1615 661 1679 661 L 1763 662 L 1729 682 C 1710 693 1693 706 1690 710 C 1687 714 1638 721 1580 725 C 1442 735 1035.807 579.026 791.91 614.066 C 605.557 640.839 468.465 596.206 317.834 659.342 C 281.336 718.478 278.76 921.183 278.76 926.183 C 282.76 947.183 294.25 1080.631 287.25 1080.631 C 270.25 1080.631 240 861 240 771 C 240 721 236 672 231 662 C 201 603 198 592 209 572 C 219 553 277 536 365 527 C 450 518 487 507 498 489 C 510 471 506 470 418 470 C 314 470 270 462 292 449 C 311 436 1880 438 1880 450 C 1880 456 1865 460 1848 461 C 1815 461 1741.154 478.874 1766.154 479.874 C 1773.154 480.874 1792 500 1797 499 C 1842 491 1898 519 1884 541 C 1881 547 1883 557 1889 563 C 1905 583 1891 716 1871 733 C 1862 741 1835 755 1811 764 C 1787 773 1740 807 1706 840 C 1643 902 1552 975 1463 1036 C 1425 1062 1394 1075 1348 1082 C 1313 1087 1078.008 1095.287 825 1095 Z M 1560 485 C 1562.923 467.961 1525 470 1152 470 C 782 470 710.232 461.616 736 485 C 744.312 492.543 760.967 542.243 952.487 543.175 C 1029.635 543.55 1091.159 543.662 1160.579 543.432 C 1218.895 543.239 1313.834 537.923 1367.398 536.943 C 1502.016 534.48 1558.942 491.168 1560 485 Z"
          fill="url(#gradient-0)"
        />
      </g>
      <path
        d="M 109.114 79.658 C 109.114 79.658 96.604 80.647 96.595 80.644 L 104.039 95.096 L 99.425 97.02 C 99.425 97.02 92.313 82.434 92.271 82.442 C 92.146 82.369 83.51 90.023 83.51 90.023 L 83.003 57.562 L 109.114 79.658 Z"
        stroke="rgb(75, 86, 107)"
        fill="rgb(255, 255, 255)"
        transform="matrix(0.994522, -0.104532, 0.104532, 0.994522, -7.551883, 10.462329)"
      />
    </svg>
    <div className="logo__text">
      SPRINTER <b>RENTALS</b>
    </div>
  </div>
);

const Check = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="24"
    height="24"
    fill="none"
    viewBox="-3 -5 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 1L6 12L1 7"
      stroke="#5cdb47"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const NoResult = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="450"
    height="226"
    viewBox="0 0 450 226"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99296 209.057C-12.6386 187.341 9.43162 129.245 42.7105 93.7168C83.178 50.5214 138.967 14.1864 246.801 26.5424C335.846 36.75 391.13 81.5083 409.935 104.565C439.836 141.209 461.44 192.911 443.431 214.294C413.21 250.186 288.347 189.116 220.619 187.871C152.891 186.627 54.0625 257.466 7.99296 209.057Z"
      fill="#E1ECFF"
    />
    <path
      d="M327.977 174.363H92.9338L43.4966 13.2874C43.0236 11.7489 42.9197 10.1213 43.1933 8.53557C43.4668 6.94981 44.1101 5.45022 45.0714 4.15754C46.0327 2.86485 47.2851 1.8152 48.7278 1.09308C50.1705 0.370962 51.7631 -0.00343911 53.3776 2.38046e-05H266.827C269.036 -0.000414439 271.188 0.706271 272.964 2.01601C274.74 3.32576 276.048 5.16934 276.694 7.27532L327.977 174.363Z"
      fill="#535E6C"
    />
    <path
      d="M289.485 155.188H120.861C118.631 155.188 116.46 154.475 114.667 153.154C112.875 151.832 111.555 149.973 110.901 147.848L73.4635 25.8647C73.2253 25.0906 73.1727 24.2717 73.31 23.4737C73.4473 22.6757 73.7705 21.921 74.2538 21.2703C74.7371 20.6195 75.3669 20.0909 76.0926 19.727C76.8182 19.363 77.6194 19.1739 78.4317 19.1749H247.056C249.285 19.175 251.456 19.8881 253.249 21.2093C255.042 22.5305 256.362 24.3901 257.015 26.5147L294.453 148.498C294.691 149.272 294.744 150.091 294.606 150.889C294.469 151.687 294.146 152.442 293.663 153.093C293.179 153.743 292.55 154.272 291.824 154.636C291.098 155 290.297 155.189 289.485 155.188V155.188Z"
      fill="#ECF2FC"
    />
    <path
      d="M92.9341 174.363H408.648C410.778 174.363 412.821 175.206 414.327 176.707C415.833 178.208 416.679 180.244 416.679 182.367V193.058C416.679 195.181 415.833 197.217 414.327 198.718C412.821 200.219 410.778 201.062 408.648 201.062H100.965C99.9101 201.062 98.8658 200.855 97.8915 200.453C96.9172 200.051 96.0319 199.461 95.2862 198.718C94.5405 197.975 93.949 197.092 93.5454 196.121C93.1418 195.15 92.9341 194.109 92.9341 193.058V174.363Z"
      fill="#303439"
    />
    <path
      d="M131.413 93.0575L150.615 71.6973"
      stroke="#5893F9"
      strokeWidth="18"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M155.148 93.0575L126.231 71.6973"
      stroke="#5893F9"
      strokeWidth="18"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M226.235 93.0575L245.437 71.6973"
      stroke="#5893F9"
      strokeWidth="18"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M249.97 93.0575L221.049 71.6973"
      stroke="#5893F9"
      strokeWidth="18"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M178.773 106.557C178.773 106.557 193.825 98.2858 209.114 106.557"
      stroke="#5893F9"
      strokeWidth="18"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M343.164 81.3469C341.424 81.3424 339.738 80.7458 338.384 79.6559C337.03 78.5659 336.091 77.048 335.721 75.3533C334.305 68.9955 333.408 64.1407 334.657 59.5994C336.156 54.2144 340.111 51.0608 344.371 48.0456C347.244 46.017 349.238 42.9372 349.46 40.2078C349.599 38.3959 348.96 36.8698 347.448 35.4129C342.979 31.0929 335.744 37.815 335.42 38.1054C335.42 38.1054 329.342 43.2323 324.827 37.8565C320.312 32.4807 324.827 27.151 324.827 27.151C327.739 24.456 331.09 22.2734 334.736 20.6964C343.428 17.008 351.708 18.3912 358.055 24.5C362.801 29.0828 365.146 35.081 364.661 41.3881C364.101 48.6634 359.813 55.805 353.184 60.48C350.071 62.6792 349.483 63.4583 349.34 63.7073C348.983 64.8323 350.158 70.102 350.603 72.0891C350.848 73.1999 350.84 74.3512 350.581 75.4588C350.321 76.5663 349.816 77.6019 349.103 78.4896C348.39 79.3774 347.486 80.0948 346.458 80.5892C345.431 81.0836 344.305 81.3425 343.164 81.3469V81.3469Z"
      fill="#5893F9"
    />
    <path
      d="M346.842 107.728C351.729 107.728 355.691 103.779 355.691 98.9082C355.691 94.0372 351.729 90.0884 346.842 90.0884C341.954 90.0884 337.992 94.0372 337.992 98.9082C337.992 103.779 341.954 107.728 346.842 107.728Z"
      fill="#5893F9"
    />
  </svg>
);
const CompletedArrowStepper = ({ classes }: IIcon) => (
  <svg
    className={classes}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 98 32"
    fill="#4b566b"
  >
    <path
      d="M97,17.1L86.1,28c-0.6,0.6-1.6,0.6-2.2,0c-0.6-0.6-0.6-1.6,0-2.2l8.3-8.3c-4.7,0-13.4,0-17.3,0
	c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5c3.8,0,12.5,0,17.3,0L84,6.1c-0.6-0.6-0.6-1.6,0-2.2c0.6-0.6,1.6-0.6,2.2,0L97,14.9
	C97.6,15.5,97.6,16.5,97,17.1z M68,18.5c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5
	C70.5,17.4,69.4,18.5,68,18.5z M61,17.5h-7c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5h7c0.8,0,1.5,0.7,1.5,1.5
	C62.5,16.8,61.8,17.5,61,17.5z M49,17.5H31.4c-0.8,7.9-7.4,14-15.4,14c-4,0-7.7-1.6-10.5-4.1c-0.5-0.4-1-0.8-1-1.4
	c0-0.8,0.7-1.5,1.5-1.5c0.7,0,1.7,0.8,1.7,0.8c2.2,2,5.1,3.2,8.3,3.2c6.9,0,12.5-5.6,12.5-12.5c0-6.8-5.4-12.3-12.2-12.5
	c0,0-0.2,0-0.3,0c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5c0.2,0,0.5,0,0.5,0c7.8,0.2,14.2,6.3,14.9,14H49c0.8,0,1.5,0.7,1.5,1.5
	C50.5,16.8,49.8,17.5,49,17.5z M23.1,11.9c0.6,0.6,0.6,1.6,0,2.1l-7.8,8c-0.6,0.6-1.5,0.6-2,0l-3.4-3.6c-0.6-0.6-0.6-1.6,0-2.1
	c0.6-0.6,1.5-0.6,2,0l2.4,2.5l6.7-6.9C21.6,11.3,22.5,11.3,23.1,11.9z M8.5,6.5c-1.1,0-2-0.9-2-2s0.9-2,2-2c1.1,0,2,0.9,2,2
	S9.6,6.5,8.5,6.5z M3.5,12.5c-1.1,0-2-0.9-2-2s0.9-2,2-2c1.1,0,2,0.9,2,2S4.6,12.5,3.5,12.5z M2.5,16.5c1.1,0,2,0.9,2,2s-0.9,2-2,2
	c-1.1,0-2-0.9-2-2S1.4,16.5,2.5,16.5z"
    />
  </svg>
);

const ArrowStepper = ({ classes }: IIcon) => (
  <svg
    className={classes}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 98 32"
    fill="#4b566b"
  >
    <path
      d="M95.5,17.1L84.7,28c-0.6,0.6-1.6,0.6-2.2,0c-0.6-0.6-0.6-1.6,0-2.2l8.3-8.3H74v-0.1c-0.2,0.1-0.3,0.1-0.5,0.1
	c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5c0.2,0,0.3,0,0.5,0.1v-0.1h16.8l-8.3-8.4c-0.6-0.6-0.6-1.6,0-2.2
	c0.6-0.6,1.6-0.6,2.2,0l10.9,10.9C96.2,15.5,96.2,16.5,95.5,17.1z M64.5,18.5c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5
	S67,14.6,67,16C67,17.4,65.9,18.5,64.5,18.5z M54.5,18.5c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5
	C57,17.4,55.9,18.5,54.5,18.5z M44.5,18.5c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5
	C47,17.4,45.9,18.5,44.5,18.5z M34.5,18.5c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5
	C37,17.4,35.9,18.5,34.5,18.5z M24.5,18.5c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5
	C27,17.4,25.9,18.5,24.5,18.5z M14.5,18.5c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5S17,14.6,17,16C17,17.4,15.9,18.5,14.5,18.5
	z M4.5,18.5C3.1,18.5,2,17.4,2,16c0-1.4,1.1-2.5,2.5-2.5C5.9,13.5,7,14.6,7,16C7,17.4,5.9,18.5,4.5,18.5z"
    />
  </svg>
);
const Edit = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="19"
    height="19"
    viewBox="0 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 15.4601V18.5001C0 18.7801 0.22 19.0001 0.5 19.0001H3.54C3.67 19.0001 3.8 18.9501 3.89 18.8501L14.81 7.94006L11.06 4.19006L0.15 15.1001C0.0500001 15.2001 0 15.3201 0 15.4601ZM17.71 5.04006C18.1 4.65006 18.1 4.02006 17.71 3.63006L15.37 1.29006C14.98 0.900059 14.35 0.900059 13.96 1.29006L12.13 3.12006L15.88 6.87006L17.71 5.04006Z" />
  </svg>
);

const Delete = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="14"
    height="18"
    viewBox="0 0 14 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" />
  </svg>
);
const OutlinedDelete = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 6H5H21"
      stroke='rgb(75, 86, 107)'
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
      stroke='rgb(75, 86, 107)'
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const Cart = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 35" fill="#ccc">
    <path d="M44.9,9.5l-3.2,10.9c-0.3,1.1-1.3,2.4-2.7,2.4H16.4c-0.5,0-0.8,0.4-0.7,0.9l0.3,1.2c0.3,1.5,1.7,2.7,3.2,2.7 h17.6c0,0,0,0,0,0c0,0,0,0,0,0c2.1,0,3.8,1.7,3.8,3.7c0,2.1-1.7,3.7-3.8,3.7c-2.1,0-3.8-1.7-3.8-3.7c0-0.5,0.1-1.1,0.3-1.5H23.3 c0.2,0.5,0.3,1,0.3,1.5c0,2.1-1.7,3.7-3.8,3.7S16,33.3,16,31.3c0-0.8,0.3-1.5,0.7-2.1c-1.5-0.7-2.6-2.1-2.9-3.8l-0.9-4.1L9.1,3.1 C9,2.6,8.5,2.2,8,2.2H1.1C0.5,2.2,0,1.7,0,1.1S0.5,0,1.1,0H8c1.5,0,2.9,1.1,3.2,2.7l0.5,2.2c0.1,0.4,0.4,0.6,0.7,0.6 c4,0,24.6,0,29.1,0c0.7,0,1.5,0.2,2.1,0.7C44.7,7,45.2,8.2,44.9,9.5z M36.8,32.8c0.9,0,1.6-0.7,1.6-1.6c0-0.8-0.7-1.6-1.6-1.6 s-1.6,0.7-1.6,1.6C35.3,32.1,36,32.8,36.8,32.8z M19.8,32.8c0.9,0,1.6-0.7,1.6-1.6c0-0.8-0.7-1.6-1.6-1.6s-1.6,0.7-1.6,1.6 C18.3,32.1,19,32.8,19.8,32.8z M42.5,8.2c-0.2-0.3-0.5-0.4-0.9-0.4H13.2c-0.5,0-0.8,0.4-0.7,0.9l1.7,8l0.7,3.4 c0.1,0.4,0.4,0.6,0.7,0.6l22.5,0c1,0,1.5-0.4,1.7-0.9l2.9-10.6C42.8,8.7,42.7,8.4,42.5,8.2z M30.6,17.6c-0.5,0.5-1.3,0.5-1.8,0 l-1.8-1.8l-1.8,1.8c-0.5,0.5-1.3,0.5-1.8,0c-0.5-0.5-0.5-1.3,0-1.8l1.8-1.8l-1.8-1.8c-0.5-0.5-0.5-1.3,0-1.8c0.5-0.5,1.3-0.5,1.8,0 l1.8,1.8l1.8-1.8c0.5-0.5,1.3-0.5,1.8,0s0.5,1.3,0,1.8l-1.8,1.8l1.8,1.8C31.1,16.3,31.1,17.1,30.6,17.6z"></path>
  </svg>
);

const Circle = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="16"
    height="16"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
      stroke="#25282B"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const Search = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
      stroke="#4B566B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9999 21L16.6499 16.65"
      stroke="#4B566B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const UpArrow = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 15L12 9L6 15"
      stroke="#25282B"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Label = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="11"
    height="15"
    viewBox="0 0 11 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.11719 14.0337C7.45312 12.3575 10.5 8.29492 10.5 6.01297C10.5 3.24539 8.26042 1 5.5 1C2.73958 1 0.5 3.24539 0.5 6.01297C0.5 8.29492 3.54688 12.3575 4.88281 14.0337C5.20312 14.4332 5.79688 14.4332 6.11719 14.0337ZM5.5 7.68396C4.58073 7.68396 3.83333 6.93463 3.83333 6.01297C3.83333 5.09132 4.58073 4.34198 5.5 4.34198C6.41927 4.34198 7.16667 5.09132 7.16667 6.01297C7.16667 6.93463 6.41927 7.68396 5.5 7.68396Z" fill="#C2FF45" stroke="#081B3D" stroke-width="0.5" />
  </svg>
);

const Warning = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="24"
    height="24"
    fill="none"
    viewBox="-3 -5 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 2L0.5 17V18L1.5 18.5H18.5L19.5 18V17L11 2L10 1.5L9 2Z" fill="#C2FF45" />
    <path d="M10.0004 12.5C10.1725 12.5 10.3134 12.3596 10.3134 12.1875V5.9375C10.3134 5.76539 10.1725 5.625 10.0004 5.625C9.82829 5.625 9.68841 5.76539 9.68841 5.9375V12.1875C9.6879 12.3594 9.82853 12.5 10.0004 12.5ZM19.7777 16.2891L11.4457 2.07031C11.1254 1.52344 10.5629 1.25 10.0004 1.25C9.44181 1.25 8.8754 1.52344 8.51993 2.07031L0.191809 16.2891C-0.414441 17.3789 0.38509 18.75 1.66993 18.75H18.334C19.6098 18.75 20.4184 17.3828 19.7777 16.2891ZM19.2426 17.6055C19.0551 17.9297 18.7152 18.125 18.3324 18.125H1.66954C1.28716 18.125 0.947278 17.9304 0.760559 17.6043C0.577825 17.2855 0.57927 16.9211 0.764602 16.6051L9.09415 2.38672C9.28165 2.06641 9.6215 1.875 10.0004 1.875C10.3793 1.875 10.7168 2.0659 10.9043 2.38633L19.2363 16.6051C19.4223 16.9219 19.4223 17.2852 19.2426 17.6055ZM10.0043 14.375C9.6597 14.375 9.37931 14.6554 9.37931 15C9.37931 15.3446 9.65814 15.625 10.0027 15.625C10.3474 15.625 10.6293 15.3446 10.6293 15C10.6293 14.6554 10.3481 14.375 10.0043 14.375Z" fill="#081B3D" />
  </svg>
);

const DeleteOneRow = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke='#7d879c'
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      stroke='#7d879c'
      d="M15 9L9 15"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      stroke='#7d879c'
      d="M9 9L15 15"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const LilPic = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
      stroke='#7d879c'
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z"
      stroke='#7d879c'
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 15L16 10L5 21"
      stroke='#7d879c'
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const BigTrashBin = ({ classes }: IIcon) => (
  <svg
    className={classes}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="57 0 400 550"
  >
    <path
      d="M434.2,101.4h-13.762l5.229-30.72c0-1.707,0-4.267-1.707-5.973c-1.707-1.707-3.413-3.413-5.973-3.413l-64.628-10.44
				L348.867,6.68C348.013,2.413,343.747-1,339.48-1L167.96,13.507c-2.56,0.853-4.267,1.707-5.973,3.413
				c-0.853,1.707-1.707,3.413-1.707,5.973l4.305,39.463L82.627,78.36c-4.267,0.853-7.68,5.12-6.827,9.387l2.438,13.653H75.8
				c-14.507,0-25.6,11.093-25.6,25.6v34.133c0,13.95,10.261,24.739,23.948,25.546l35.785,300.427
				C110.787,500.76,121.88,511,135.533,511h238.933c12.8,0,24.747-10.24,25.6-23.04l35.788-301.281
				c13.686-0.809,23.946-11.596,23.946-25.546V127C459.8,112.493,448.707,101.4,434.2,101.4z M402.627,101.4h-96.398l6.798-40.107
				l93.867,15.36L402.627,101.4z M181.613,58.733L178.2,29.72l153.6-12.8l3.413,30.72l-28.16-4.267
				c-4.267-0.853-8.533,2.56-9.387,6.827l-9.035,51.2h-43.416l-8.135-44.373c0-1.707-1.707-3.413-3.413-5.12
				C231.96,50.2,229.4,50.2,226.84,50.2L181.613,58.733z M94.573,92.867l79.706-15.079c0.33-0.036,0.661-0.098,0.995-0.188
				l6.129-1.16l39.492-7.313l5.945,32.274H95.427L94.573,92.867z M374.467,493.933H135.533c-4.267,0-8.533-3.413-8.533-8.533
				l-0.193-1.61l-3.22-41.057h262.827L383,486.253C383,490.52,378.733,493.933,374.467,493.933z M390.25,425.667H119.832
				L91.16,186.733h327.68L390.25,425.667z M442.733,161.133c0,5.12-3.413,8.533-8.533,8.533h-5.12H81.773H75.8
				c-5.12,0-8.533-3.413-8.533-8.533V127c0-5.12,3.413-8.533,8.533-8.533h12.8h149.333h58.027h114.347H434.2
				c5.12,0,8.533,3.413,8.533,8.533V161.133z"
    />
    <path
      d="M255,391.533c5.12,0,8.533-3.413,8.533-8.533V229.4c0-5.12-3.413-8.533-8.533-8.533c-5.12,0-8.533,3.413-8.533,8.533V383
				C246.467,388.12,249.88,391.533,255,391.533z"
    />
    <path
      d="M167.96,383.853c0.853,4.267,4.267,7.68,8.533,7.68c5.12-0.853,8.533-5.12,8.533-9.387L171.373,229.4
				c-0.853-4.267-4.267-8.533-9.387-7.68c-4.267,0.853-7.68,4.267-7.68,9.387L167.96,383.853z"
    />
    <path
      d="M333.507,391.533c4.267,0,8.533-3.413,8.533-7.68l13.653-152.747c0.853-5.12-2.56-9.387-7.68-9.387
				c-5.12-0.853-9.387,2.56-9.387,7.68l-13.653,152.747C324.973,387.267,328.387,390.68,333.507,391.533z"
    />
  </svg>
);

const Pencil = ({ classes }: IIcon) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
  >
    <path
      d="M13.2599 3.59997L5.04985 12.29C4.73985 12.62 4.43985 13.27 4.37985 13.72L4.00985 16.96C3.87985 18.13 4.71985 18.93 5.87985 18.73L9.09985 18.18C9.54985 18.1 10.1799 17.77 10.4899 17.43L18.6999 8.73997C20.1199 7.23997 20.7599 5.52997 18.5499 3.43997C16.3499 1.36997 14.6799 2.09997 13.2599 3.59997Z"
      stroke="#4B566B"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.8901 5.05005C12.3201 7.81005 14.5601 9.92005 17.3401 10.2"
      stroke="#4B566B"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 22H21"
      stroke="#4B566B"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const Oneway = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="15.25" fill="white" stroke="#081B3D" stroke-width="1.5" />
    <path
      d="M19.1425 8.5H12.8575C10.1275 8.5 8.5 10.1275 8.5 12.8575V19.135C8.5 21.8725 10.1275 23.5 12.8575 23.5H19.135C21.865 23.5 23.4925 21.8725 23.4925 19.1425V12.8575C23.5 10.1275 21.8725 8.5 19.1425 8.5ZM21.5125 13.63C21.4825 13.6975 21.445 13.7575 21.3925 13.81L20.0425 15.16C19.93 15.2725 19.7875 15.325 19.645 15.325C19.5025 15.325 19.36 15.2725 19.2475 15.16C19.03 14.9425 19.03 14.5825 19.2475 14.365L19.6375 13.975H19.525C17.95 13.975 16.66 15.2575 16.66 16.8325V18.3025V19.93C16.66 20.2375 16.405 20.4925 16.0975 20.4925C15.79 20.4925 15.535 20.2375 15.535 19.93V18.3025V16.8325C15.535 15.2575 14.2525 13.975 12.6775 13.975H12.3625L12.7525 14.365C12.97 14.5825 12.97 14.9425 12.7525 15.16C12.64 15.2725 12.4975 15.325 12.355 15.325C12.2125 15.325 12.07 15.2725 11.9575 15.16L10.6075 13.81C10.39 13.5925 10.39 13.2325 10.6075 13.015L11.9575 11.665C12.175 11.4475 12.535 11.4475 12.7525 11.665C12.97 11.8825 12.97 12.2425 12.7525 12.46L12.3625 12.85H12.685C14.1475 12.85 15.415 13.6525 16.105 14.83C16.795 13.6525 18.0625 12.85 19.5325 12.85H19.645L19.255 12.46C19.0375 12.2425 19.0375 11.8825 19.255 11.665C19.4725 11.4475 19.8325 11.4475 20.05 11.665L21.4 13.015C21.4525 13.0675 21.49 13.1275 21.52 13.195C21.5725 13.3375 21.5725 13.495 21.5125 13.63Z"
      fill="#081B3D"
    />
  </svg>
);

const ReservedVan = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="26" height="26" rx="13" fill="white" />
    <path d="M4 14.9914L4.375 7.75L5.125 7.375H17.5L21.625 11.5L21.862 14.9914L21.1818 16.0776H19.9545L19.5455 17.5259L18.625 18.625H16.375L15.25 17.5L15.0455 16.0776L11.125 16L10.75 17.125L10 18.25L8.5 18.625L7 18.25L6.04545 16.8017L5.63636 16.0776H4.81818L4 14.9914Z" fill="#C2FF45" />
    <path d="M20.65 16.375H20.2C20.2 17.7725 18.9906 18.9062 17.5 18.9062C15.9841 18.9062 14.8 17.7725 14.8 16.375H11.2C11.2 17.7725 9.99062 18.9062 8.5 18.9062C6.98406 18.9062 5.8 17.7725 5.8 16.375H5.35C4.60441 16.375 4 15.8081 4 15.1094V8.35938C4 7.66038 4.60441 7.09375 5.35 7.09375H17.1147C17.4212 7.09375 17.7166 7.2124 17.9303 7.42202L21.6653 11.1332C21.8903 11.331 22 11.5894 22 11.8609V15.1094C22 15.8081 21.3953 16.375 20.65 16.375ZM4.45 11.3125H8.5V7.51562H5.35C4.85303 7.51562 4.45 7.89347 4.45 8.35938V11.3125ZM4.45 11.7344V15.1094C4.45 15.5761 4.85303 15.9531 5.35 15.9531H5.83741C6.05144 14.7561 7.16125 13.8438 8.5 13.8438C9.83875 13.8438 10.9497 14.7561 11.1634 15.9531H14.8366C15.0503 14.7561 16.1613 13.8438 17.5 13.8438C18.8387 13.8438 19.9497 14.7561 20.1634 15.9531H20.65C21.1478 15.9531 21.55 15.5761 21.55 15.1094V11.8609C21.55 11.8187 21.5444 11.7766 21.5359 11.7344H4.45ZM8.95 11.3125H14.35V7.51562H8.95V11.3125ZM14.8 11.3125H21.2519L17.6041 7.71259C17.4747 7.58682 17.2975 7.51562 17.1147 7.51562H14.8V11.3125ZM17.5 14.2656C16.2569 14.2656 15.25 15.2096 15.25 16.375C15.25 17.5404 16.2569 18.4844 17.5 18.4844C18.7431 18.4844 19.75 17.5404 19.75 16.375C19.75 15.2096 18.7431 14.2656 17.5 14.2656ZM8.5 18.4844C9.74312 18.4844 10.75 17.5404 10.75 16.375C10.75 15.2096 9.74312 14.2656 8.5 14.2656C7.25687 14.2656 6.25 15.2096 6.25 16.375C6.25 17.5404 7.25687 18.4844 8.5 18.4844Z" fill="#2B3445" />
    <circle cx="13" cy="20.5" r="4.5" fill="#081B3D" />
    <path d="M10.75 20.2857L12.3864 22L15.25 19" stroke="white" stroke-width="0.75" />
  </svg>
);

const UploadDocs = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="36" rx="8" fill="#F0F2F6" />
    <path d="M28 16V21C28 26 26 28 21 28H15C10 28 8 26 8 21V15C8 10 10 8 15 8H20" fill="#C2FF45" />
    <path d="M28 16V21C28 26 26 28 21 28H15C10 28 8 26 8 21V15C8 10 10 8 15 8H20" stroke="#081B3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15 23V17L13 19" stroke="#081B3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15 17L17 19" stroke="#081B3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M28 16H24C21 16 20 15 20 12V8L28 16Z" stroke="#081B3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const InviteDrivers = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="36" rx="8" fill="#F0F2F6" />
    <path d="M27.0802 14.58V21.42C27.0802 22.54 26.4802 23.58 25.5102 24.15L19.5702 27.58C18.6002 28.14 17.4002 28.14 16.4202 27.58L10.4802 24.15C9.51016 23.59 8.91016 22.55 8.91016 21.42V14.58C8.91016 13.46 9.51016 12.42 10.4802 11.85L16.4202 8.42C17.3902 7.86 18.5902 7.86 19.5702 8.42L25.5102 11.85C26.4802 12.42 27.0802 13.45 27.0802 14.58Z" fill="#C2FF45" stroke="#081B3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M17.9999 17C19.2867 17 20.3299 15.9568 20.3299 14.67C20.3299 13.3832 19.2867 12.34 17.9999 12.34C16.7131 12.34 15.6699 13.3832 15.6699 14.67C15.6699 15.9568 16.7131 17 17.9999 17Z" stroke="#081B3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M22 22.66C22 20.86 20.21 19.4 18 19.4C15.79 19.4 14 20.86 14 22.66" stroke="#081B3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const Payments = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="36" rx="8" fill="#F0F2F6" />
    <path d="M9.60127 16.239L16.2413 9.599C18.3613 7.479 19.4213 7.469 21.5213 9.569L26.4313 14.479C28.5313 16.579 28.5213 17.639 26.4013 19.759L19.7613 26.399C17.6413 28.519 16.5813 28.529 14.4813 26.429L9.57127 21.519C7.47127 19.419 7.47127 18.369 9.60127 16.239Z" fill="#C2FF45" stroke="#081B3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.92969 21.8792L21.8797 9.9292" stroke="#081B3D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M17.1016 24.279L18.3016 23.079" stroke="#081B3D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M19.793 21.5887L22.183 19.1987" stroke="#081B3D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8 27.9985H28" stroke="#081B3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const Esign = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="36" rx="8" fill="#F0F2F6" />
    <path d="M22 8H14C10 8 8 10 8 14V27C8 27.55 8.45 28 9 28H22C26 28 28 26 28 22V14C28 10 26 8 22 8Z" fill="#C2FF45" stroke="#081B3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.9098 13.84L13.7198 19.03C13.5198 19.23 13.3298 19.62 13.2898 19.9L13.0098 21.88C12.9098 22.6 13.4098 23.1 14.1298 23L16.1098 22.72C16.3898 22.68 16.7798 22.49 16.9798 22.29L22.1698 17.1C23.0598 16.21 23.4898 15.17 22.1698 13.85C20.8498 12.52 19.8098 12.94 18.9098 13.84Z" stroke="#081B3D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.1699 14.58C18.6099 16.15 19.8399 17.39 21.4199 17.83" stroke="#081B3D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const PickupInfo = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="36" rx="8" fill="#F0F2F6" />
    <path d="M16.4902 8.23L11.5002 10.11C10.3502 10.54 9.41016 11.9 9.41016 13.12V20.55C9.41016 21.73 10.1902 23.28 11.1402 23.99L15.4402 27.2C16.8502 28.26 19.1702 28.26 20.5802 27.2L24.8802 23.99C25.8302 23.28 26.6102 21.73 26.6102 20.55V13.12C26.6102 11.89 25.6702 10.53 24.5202 10.1L19.5302 8.23C18.6802 7.92 17.3202 7.92 16.4902 8.23Z" fill="#C2FF45" stroke="#081B3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.0498 17.87L16.6598 19.48L20.9598 15.18" stroke="#081B3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const CompleteStatus = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1685_2775)">
      <path d="M15.1663 7.38668V8.00001C15.1655 9.43763 14.7 10.8365 13.8392 11.9879C12.9785 13.1393 11.7685 13.9817 10.3899 14.3893C9.0113 14.7969 7.53785 14.7479 6.18932 14.2497C4.8408 13.7515 3.68944 12.8307 2.90698 11.6247C2.12452 10.4187 1.75287 8.99205 1.84746 7.55755C1.94205 6.12305 2.49781 4.75756 3.43186 3.66473C4.36591 2.57189 5.6282 1.81027 7.03047 1.49344C8.43274 1.17662 9.89985 1.32157 11.213 1.90668" stroke="#1976D2" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.18499 1.71447L6.77743 1.55573C8.16627 1.18444 9.63792 1.27205 10.9729 1.80548C12.3079 2.33892 13.4347 3.2896 14.1852 4.51575C14.9358 5.74189 15.2698 7.1778 15.1376 8.60932C15.0054 10.0408 14.414 11.3913 13.4516 12.4592C12.4892 13.5272 11.2073 14.2554 9.79723 14.5353C8.38712 14.8152 6.92432 14.6318 5.62697 14.0124C4.32962 13.3931 3.26725 12.3709 2.59828 11.0984C1.92932 9.82592 1.68961 8.37128 1.91492 6.95143" stroke="#1976D2" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    </g>
  </svg>
);

const CompletedStatus = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.1663 7.38668V8.00001C15.1655 9.43763 14.7 10.8365 13.8392 11.9879C12.9785 13.1393 11.7685 13.9817 10.3899 14.3893C9.0113 14.7969 7.53785 14.7479 6.18932 14.2497C4.8408 13.7515 3.68944 12.8307 2.90698 11.6247C2.12452 10.4187 1.75287 8.99205 1.84746 7.55755C1.94205 6.12305 2.49781 4.75756 3.43186 3.66473C4.36591 2.57189 5.6282 1.81027 7.03047 1.49344C8.43274 1.17662 9.89985 1.32157 11.213 1.90668" stroke="#78DA6C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.1667 2.66669L8.5 9.34002L6.5 7.34002" stroke="#78DA6C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const ClosedStatus = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.6667 7.33331H3.33333C2.59695 7.33331 2 7.93027 2 8.66665V13.3333C2 14.0697 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0697 14 13.3333V8.66665C14 7.93027 13.403 7.33331 12.6667 7.33331Z" stroke="#8891A4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4.66699 7.33331V4.66665C4.66699 3.78259 5.01818 2.93475 5.6433 2.30962C6.26842 1.6845 7.11627 1.33331 8.00033 1.33331C8.88438 1.33331 9.73223 1.6845 10.3573 2.30962C10.9825 2.93475 11.3337 3.78259 11.3337 4.66665V7.33331" stroke="#8891A4" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const Shield = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 3L1.69699 3.07744C4.14576 3.34953 6.60878 2.70802 8.61368 1.27594L9 1L9.38632 1.27594C11.3912 2.70802 13.8542 3.34953 16.303 3.07744L17 3V10.0557C17 13.0859 15.288 15.856 12.5777 17.2111L9 19L5.42229 17.2111C2.71202 15.856 1 13.0859 1 10.0557V3Z" fill="#C2FF45" stroke="#081B3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

);

const EmailUs = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="18"
    height="18"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.87467 10C2.70801 10 2.70801 11.4171 2.70801 13.1667V13.9583C2.70801 16.1433 2.70801 17.9167 6.66634 17.9167H12.9997C16.1663 17.9167 16.958 16.1433 16.958 13.9583V13.1667C16.958 11.4171 16.958 10 13.7913 10C12.9997 10 12.778 10.1663 12.3663 10.475L11.5588 11.33C10.6247 12.3275 9.04134 12.3275 8.09926 11.33L7.29967 10.475C6.88801 10.1663 6.66634 10 5.87467 10Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.3743 10V5.25004C15.3743 3.50046 15.3743 2.08337 12.2077 2.08337H7.45768C4.29102 2.08337 4.29102 3.50046 4.29102 5.25004V10" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.68555 7.80713H11.3218" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.02832 5.43213H11.9867" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

);

const ChangeContactName = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="18"
    height="18"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.6667 1.66663H7C3.66667 1.66663 2 3.33329 2 6.66663V17.5C2 17.9583 2.375 18.3333 2.83333 18.3333H13.6667C17 18.3333 18.6667 16.6666 18.6667 13.3333V6.66663C18.6667 3.33329 17 1.66663 13.6667 1.66663Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11.0913 6.53334L6.76633 10.8583C6.59966 11.025 6.44134 11.35 6.408 11.5833L6.17467 13.2333C6.09134 13.8333 6.508 14.25 7.108 14.1667L8.75798 13.9333C8.99132 13.9 9.31634 13.7417 9.483 13.575L13.808 9.25C14.5497 8.50834 14.908 7.64167 13.808 6.54167C12.708 5.43334 11.8413 5.78334 11.0913 6.53334Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.4746 7.15002C10.8413 8.45836 11.8663 9.49169 13.1829 9.85836" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

);

const ChangeContactEmail = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="18"
    height="18"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.3333 17.0833H6.16667C3.66667 17.0833 2 15.8333 2 12.9166V7.08329C2 4.16663 3.66667 2.91663 6.16667 2.91663H14.5C17 2.91663 18.6667 4.16663 18.6667 7.08329V9.58329" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.4993 7.5L11.891 9.58333C11.0327 10.2667 9.62435 10.2667 8.76601 9.58333L6.16602 7.5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M16.3415 12.3083L13.3915 15.2583C13.2749 15.375 13.1665 15.5917 13.1415 15.75L12.9832 16.875C12.9249 17.2833 13.2082 17.5667 13.6165 17.5083L14.7415 17.35C14.8999 17.325 15.1249 17.2167 15.2332 17.1L18.1832 14.15C18.6915 13.6417 18.9332 13.05 18.1832 12.3C17.4415 11.5583 16.8499 11.8 16.3415 12.3083Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.917 12.7333C16.167 13.6333 16.867 14.3333 17.767 14.5833" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

);

const Icons = {
  Point,
  Bagadge,
  Bycicle,
  Seat,
  DoubleArrows,
  Arrow,
  BigArrow,
  Folder,
  Close,
  BiGCamera,
  NewLogoGr,
  Check,
  NoResult,
  CompletedArrowStepper,
  ArrowStepper,
  Cart,
  Edit,
  Delete,
  OutlinedDelete,
  Circle,
  Search,
  UpArrow,
  Label,
  Warning,
  DeleteOneRow,
  LilPic,
  BigTrashBin,
  Pencil,
  Oneway,
  ReservedVan,
  UploadDocs,
  InviteDrivers,
  Payments,
  Esign,
  PickupInfo,
  CompleteStatus,
  CompletedStatus,
  ClosedStatus,
  Shield,
  EmailUs,
  ChangeContactName,
  ChangeContactEmail
};
export default Icons;
