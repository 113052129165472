import { CancelOrderState, CancelOrderActionEnum, CancelOrderAction } from "./types";

const initialState: CancelOrderState = {
    isLoading: false
};

const cancelOrder = (state = initialState, action: CancelOrderAction): CancelOrderState => {
  switch (action.type) {
    case CancelOrderActionEnum.CANCEL_ORDER:
      return {
        ...state,
        isLoading: true,
      };
    case CancelOrderActionEnum.RESULT_CANCEL_ORDER:
      return {
        ...state,
        isLoading: false,
        result: action.status,
      };
    default:
      return state;
  }
};
export default cancelOrder;