import { FC, useState, useEffect } from "react";
import { Button } from "@mui/material";
import Aside from "src/components/Aside";
import NoResult from "src/components/Items/NoResult";
import Sublocations from "./components/Sublocations";
import PreviewPageVan from "src/components/Items/previewPages/PreviewVan";
import InfiniteScrollItem from "./components/InfiniteScrollItem";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import useIsMobile from "src/hooks/useIsMobile";
import { scrollToTop } from "src/common/utils/others";
import Icons from "src/common/icons";
import "./styles.scss";

const Van: FC = () => {

  const {
    vanList,
    isLoading,
    filters
  } = useTypedSelector((state) => state.van);

  const isMobile = useIsMobile();
  const [showButton, setShowButton] = useState(false);
  const [show, setShow] = useState(10);
  const [showRegions, setShowRegions] = useState(1);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.pageYOffset > 120
        ? setShowButton(true)
        : setShowButton(false);
    });
  }, []);

  return (
    <>
      <div className="aside">
        <Aside />
      </div>
      <div className="vans-time">
        <Sublocations />
        {!isLoading.all ? (
          Object.keys(vanList).length ? (
            <>
              {filters.location ? (
                <>
                  {(vanList[filters.location] && vanList[filters.location].length)
                    ? <InfiniteScrollItem show={show} setShow={setShow} />
                    : <NoResult margin='30px auto' />
                  }
                  {
                    (vanList[filters.location]
                      && (show >= vanList[filters.location].length)
                    ) && (
                      <InfiniteScrollItem
                        isRegion
                        show={showRegions}
                        setShow={setShowRegions}
                      />
                    )}
                </>
              ) : (
                Object.keys(vanList).map(location => (
                  <InfiniteScrollItem
                    location={location}
                    show={show}
                    setShow={setShow}
                  />
                ))
              )}
            </>
          ) : (
            <NoResult margin='120px auto 0' />
          )
        ) : (
          <PreviewPageVan />
        )}
      </div>
      {isMobile && showButton && (
        <Button
          onClick={scrollToTop}
          className="back-to-top"
          children={<Icons.UpArrow />}
        />
      )}
    </>
  );
};
export default Van;
