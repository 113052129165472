import { FC, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@mui/material";
import InfiniteScrollList from "./components/InfiniteScrollList";
import InfiniteScrollRegionList from "./components/InfiniteScrollRegionList";
import { useTypedSelector } from "src/hooks/useTypeSelector";

interface IProps {
  location?: string;
  isRegion?: boolean;
  show: number;
  setShow: (arg0: number) => void
}

const InfiniteScrollItem: FC<IProps> = ({
  location,
  isRegion,
  show,
  setShow
}) => {

  const {
    vanList,
    filters
  } = useTypedSelector((state) => state.van);

  const [loading, setLoading] = useState(false);
  const [loadingRegions, setLoadingRegions] = useState(false);

  return (
    <div className='vans'>
      <InfiniteScroll
        dataLength={show}
        next={
          isRegion
            ? () => setLoadingRegions(true)
            : () => setLoading(true)
        }
        hasMore={true}
        className='infiniteScroll'
        loader={loading &&
          <div className='infiniteScroll__loading'>
            <CircularProgress size={100} />
          </div>
        }
      >
        {isRegion ? (
          <InfiniteScrollRegionList
            loading={loadingRegions}
            setLoading={setLoadingRegions}
            show={show}
            setShow={setShow}
          />
        ) : (
          <InfiniteScrollList
            data={
              location
                ? vanList[location]
                : vanList[filters.location!]
            }
            loading={loading}
            setLoading={setLoading}
            show={show}
            setShow={setShow}
          />
        )}
      </InfiniteScroll>
    </div>
  );
};
export default InfiniteScrollItem;
