export enum SubLocationActionEnum {
    REQUEST_SUBLOCATIONS = 'REQUEST_SUBLOCATIONS',
    RESULT_SUBLOCATIONS = 'RESULT_SUBLOCATIONS',
    ERROR_SUBLOCATIONS = 'ERROR_SUBLOCATIONS',
  }
  
  export interface SubLocation {
    id: number;
    name: string;
    location_id: number;
    main_location_name: string;
  }
  
  export interface SubLocationState {
    sublocationList: SubLocation[];
    isLoading: {
      all: boolean;
    };
  }
  
  export interface RequestSubLocationAction {
    type: SubLocationActionEnum.REQUEST_SUBLOCATIONS;
  }
  
  export interface ResultSubLocationAction {
    type: SubLocationActionEnum.RESULT_SUBLOCATIONS;
    data: SubLocation[];
  }
  
  export interface ErrorSubLocationAction {
    type: SubLocationActionEnum.ERROR_SUBLOCATIONS;
    payload?: any;
  }
  
  export type SubLocationAction =
    | RequestSubLocationAction
    | ResultSubLocationAction
    | ErrorSubLocationAction;
  