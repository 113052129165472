import { IVanFilter } from 'src/models/IVanFilter';
import {
  VanAction,
  VanActionEnum,
  VanFilterAction,
  VanFilterActionEnum,
  VanFilterErrors,
  VanState,
} from './types';
import { IVanFetch } from 'src/models/IVan';

export const initFilter: IVanFilter = {
  page: 1,
  take: 10,
  features: [],
};

export const initExtraFilter = {
  take: 10,
  page: 1,
  seats: undefined,
  type: undefined,
  features: [],
  price: undefined,
};

const initErrors: VanFilterErrors = {
  location: '',
  start_date: '',
  end_date: '',
};

const initialState: VanState = {
  vanList: {},
  total: null,
  minPrice: null,
  maxPrice: null,
  isLoading: {
    all: false,
    get: false,
    check: false
  },
  result: {},
  filters: { ...initExtraFilter },
  errors: { ...initErrors },
  isFilterValidate: false,
  checkFilter: false,
};

const van = (
  state = initialState,
  action: VanAction | VanFilterAction
): VanState => {
  switch (action.type) {
    case VanActionEnum.REQUEST_VANS:
      return {
        ...state,
        isLoading: { ...state.isLoading, all: true },
        result: {}
      };
    case VanActionEnum.RESULT_VANS:
      return {
        ...state,
        vanList: Array.isArray(action.vanList) ? { 'all': action.vanList as IVanFetch[] } : action.vanList as { [key: string]: IVanFetch[] },
        total: action.count,
        minPrice: action.minPrice / 100,
        maxPrice: action.maxPrice / 100,
        isLoading: { ...state.isLoading, all: !action.vanList },
        result: {}
      };
    case VanActionEnum.REQUEST_HOST_VANS:
      return { ...state, isLoading: { ...state.isLoading, all: true } };
    case VanActionEnum.RESULT_HOST_VANS:
      return {
        ...state,
        vanList: Array.isArray(action.vanList) ? { 'all': action.vanList as IVanFetch[] } : action.vanList as { [key: string]: IVanFetch[] },
        isLoading: { ...state.isLoading, all: !action.vanList },
      };
    case VanActionEnum.REQUEST_VAN:
      return { ...state, isLoading: { ...state.isLoading, get: true } };
    case VanActionEnum.RESULT_VAN:
      return {
        ...state,
        van: action.data,
        isLoading: { ...state.isLoading, get: !action.data },
      };

    case VanActionEnum.REQUEST_CHECK_VAN:
      return {
        ...state,
        isLoading: { ...state.isLoading, check: true },
        result: {}
      };
    case VanActionEnum.RESULT_CHECK_VAN:
      return {
        ...state,
        isLoading: { ...state.isLoading, check: !action.data },
        result: { ...state.result, check: action.status }
      };

    case VanFilterActionEnum.FEATURES:
      return {
        ...state,
        filters: {
          ...state.filters,
          features: action.features,
        },
      };
    case VanFilterActionEnum.TYPE:
      return {
        ...state,
        filters: { ...state.filters, type: action.vanType },
      };
    case VanFilterActionEnum.SEATS:
      return {
        ...state,
        filters: { ...state.filters, seats: Number(action.seat) },
      };
    case VanFilterActionEnum.START_PRICE:
      return {
        ...state,
        filters: {
          ...state.filters,
          price: {
            ...state.filters.price,
            startPrice: Number(action.startPrice),
          },
        },
      };
    case VanFilterActionEnum.END_PRICE:
      return {
        ...state,
        filters: {
          ...state.filters,
          price: {
            ...state.filters.price,
            endPrice: Number(action.endPrice),
          },
        },
      };

    case VanFilterActionEnum.LOCATION:
      return {
        ...state,
        filters: {
          ...state.filters,
          location: action.location,
          sub_location: action.sub_location
        },
      };
    case VanFilterActionEnum.END_LOCATION:
      return {
        ...state,
        filters: { ...state.filters, end_location: action.end_location },
      };
    case VanFilterActionEnum.REGION:
      return {
        ...state,
        filters: {
          ...state.filters,
          region: action.region
        },
      };
    case VanFilterActionEnum.START_DATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          start_date: action.startDate,
        },
      };
    case VanFilterActionEnum.END_DATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          end_date: action.endDate,
        },
      };
    case VanFilterActionEnum.SETUP_FILTERS:
      return {
        ...state,
        isLoading: { ...state.isLoading, all: true },
        filters: { ...action.filters },
      };
    case VanFilterActionEnum.ERROR_FILTER:
      return {
        ...state,
        errors: action.errors,
        isFilterValidate: action.isFilterValidate,
      };
    case VanFilterActionEnum.CLEAR_ERROR_FILTER:
      return {
        ...state,
        errors: { ...initErrors },
        isFilterValidate: false,
      };
    case VanFilterActionEnum.SET_CHECK_FILTER: {
      return {
        ...state,
        checkFilter: true,
      };
    }
    case VanFilterActionEnum.CLEAR:
      return {
        ...state,
        filters: { ...state.filters, ...initExtraFilter },
      };
    default:
      return state;
  }
};
export default van;
