import {
  RequestSubPriceAction,
    ResultSubPriceAction,
    SubPrice, SubPriceActionEnum,
  } from './types';
  
  export const SubPricesActionCreators = {
    getSubPrices: (): RequestSubPriceAction => ({
      type: SubPriceActionEnum.REQUEST_SUBPRICES,
    }),
    resultGetSubPrices: (data: SubPrice[]): ResultSubPriceAction => ({
      type: SubPriceActionEnum.RESULT_SUBPRICES,
      data,
    }),
  };
  