import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Button, Modal } from '@mui/material';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import Aside from 'src/components/Aside';
import Logo from 'src/components/Items/Logo';
import LoginModal from './LoginModal';
import GeneralFilter from 'src/components/Items/Filters/General';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import useCloseMenu from 'src/hooks/useCloseMenu';
import { useActions } from 'src/hooks/useActions';
import './styles.scss';
import './media.scss';

interface IProps {
  marginTop: number;
  setMarginTop: (arg0: number) => void
}

const Header: FC<IProps> = ({ marginTop, setMarginTop }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { clearClientResult } = useActions();
  const [closeBar, _, setSwichBar] = useCloseMenu();
  const { isLogin, result, isError } = useTypedSelector((state) => state.client);
  const [open, setOpen] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  useEffect(() => {
    isLogin && result.login && navigate('/client');
  }, [isLogin, result]);

  useEffect(() => {
    isError && (
      localStorage.removeItem('guest-token'),
      setOpenLogin(true),
      enqueueSnackbar('Your password has expired. Please login again', {
        variant: 'info',
      }),
      clearClientResult()
    );
  }, [isError]);

  const handleOpen = () => {
    isLogin ? navigate('/client') : setOpenLogin(true);
  };

  return (
    <>
      <div className={`header__wrapper`}>
        <div className='header__wrapper_sup'>
          <div className='header__container'>
            <Logo />
            <div className='header__mobile_icon-set'>
              {/* <div className='header__icon' onClick={handleOpen}>
                <PersonOutlineOutlinedIcon />
              </div> */}
              <Button
                variant='contained'
                onClick={handleOpen}
                children='My booking'
              />
              {/* <div className='header__icon'>
                <ShoppingBagOutlinedIcon />
              </div> */}
            </div>
            <div className='header__container__mobile-filters'>
              <div
                className='header__container__mobile-filters'
                onClick={() => setOpen(true)}
              >
                <TuneOutlinedIcon sx={{ fontSize: 27 }} />
                Filters
              </div>
              {/* <div className='flex_CC' onClick={() => setSwichBar()}>
                <div
                  className={`hamburger-lines ${!closeBar ? 'active' : ''}`}
                >
                  <span className='line line1' />
                  <span className='line line2' />
                  <span className='line line3' />
                </div>
              </div> */}
            </div>
          </div>
          <GeneralFilter marginTop={marginTop} setMarginTop={setMarginTop} />
          <div className='header__icon-set'>
            {/* <div className='header__icon' onClick={handleOpen}>
              <PersonOutlineOutlinedIcon />
            </div> */}
            <Button
              variant='contained'
              onClick={handleOpen}
              children='My booking'
            />
            {/* <div className='header__icon'>
              <ShoppingBagOutlinedIcon />
            </div> */}
          </div>
        </div>
        <Modal open={open} onClose={() => setOpen(false)} closeAfterTransition>
          <>
            <Aside handleClose={() => setOpen(false)} />
          </>
        </Modal>
        <LoginModal open={openLogin} onClose={() => setOpenLogin(false)} />
      </div>
    </>
  );
};

export default Header;
