import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  ThemeProvider,
} from "@mui/material";
import { deepmerge } from "@mui/utils";
import { FC } from "react";
import "./styles.scss";
import { createTheme } from "@mui/material/styles";
import theme, { colorSet, multiSelectItemStyle, typeForm } from "src/theme";

interface ISelect {
  data?: any[];
  setData: (event: any) => any;
  dataArray: any[];
  check?: boolean;
  title?: string;
  gridColumns?: number;
  maxWidth?: string;
}

const SimpleMultiSelect: FC<ISelect> = ({
  data,
  setData,
  dataArray,
  check,
  title,
  gridColumns,
  maxWidth,
}) => {
  const gridTemplateColumns = gridColumns
    ? `repeat(${gridColumns}, 1fr)`
    : `repeat(2, 1fr)`;

  const themeMultiSelect = createTheme(
    deepmerge(theme, {
      components: {
        MuiFormControl: {
          styleOverrides: {
            root: {
              maxWidth,
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              maxHeight: `260px !important`,
            },
          },
        },
        MuiList: {
          styleOverrides: {
            root: {
              display: `grid`,
              gridTemplateColumns,
            },
          },
        },
      },
    })
  );
  return (
    <ThemeProvider theme={themeMultiSelect}>
      {data && (
        <div>
          {title && <div className="filter__title">{title}</div>}
          <FormControl>
            <Select
              variant={typeForm}
              multiple
              displayEmpty
              defaultValue={[]}
              value={data}
              onChange={setData}
              error={data && check}
              renderValue={(n: string[] | number[]) =>
                !n.length || !data.length ? (
                  <div style={{ color: 'rgb(160 158 158)' }}>
                    Select {title}
                  </div>
                ) : (
                  n.join(", ")
                )
              }
            >
              {dataArray.map((d, index) => (
                <MenuItem sx={multiSelectItemStyle} value={d} key={index}>
                  <Checkbox checked={data.includes(d)} size="small" />
                  <ListItemText primary={d} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </ThemeProvider>
  );
};

export default SimpleMultiSelect;
