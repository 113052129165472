import { FC } from 'react';
import { useSnackbar } from 'notistack';
import { Backdrop, Button, Modal } from '@mui/material';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import { useActions } from 'src/hooks/useActions';
import Check from 'src/pages/Van/components/item/CheckIcon';
import './styles.scss';
import './media.scss';

interface IProps {
  handleClose: () => void;
  open: boolean;
  id: number;
  email: string;
}
const CancelModal: FC<IProps> = ({ handleClose, open, id, email }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { cancelOrder } = useActions();

  return (
    <Modal
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={handleClose}
    >
      <div className='client__modal cancel-modal'>
        <div className='cancel-modal__text'>
          {`Are you sure you want to cancel this order #${id}?`}
        </div>
        <div className='cancel-modal__policy'>
          <Check />
          Standard cancellation policy applies
        </div>
        <div className='cancel-modal__buttons'>
          <SecondTypeButton
            children='No, Keep'
            onClick={() => handleClose()}
          />
          <Button
            children='Yes, Cancel'
            variant='contained'
            onClick={() => {
              enqueueSnackbar('Canceling order...');
              cancelOrder(id, email);
              handleClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
export default CancelModal;
