import {
    SubLocationActionEnum,
    RequestSubLocationAction,
    ResultSubLocationAction,
    SubLocation,
  } from './types';
  
  export const SubLocationActionCreators = {
    getSubLocations: (): RequestSubLocationAction => ({
      type: SubLocationActionEnum.REQUEST_SUBLOCATIONS,
    }),
    resultGetSubLocations: (data: SubLocation[]): ResultSubLocationAction => ({
      type: SubLocationActionEnum.RESULT_SUBLOCATIONS,
      data,
    }),
  };
  