import { put, takeLatest } from "redux-saga/effects";
import { ExtraActionEnum } from "src/store/reducers/extra/types";
import { ExtraActionCreators } from "src/store/reducers/extra/action-creators";
import instance from "../set";

function* fetchGetExtras() {
  try {
    const { data } = yield instance.get(`extra`);
    yield put(ExtraActionCreators.resultGetExtras(data));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetExtras() {
  yield takeLatest(ExtraActionEnum.REQUEST_EXTRAS, fetchGetExtras);
}
