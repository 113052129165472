import { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { sumBy } from 'lodash';
import { Backdrop, Button, Modal } from '@mui/material';
import Filters from 'src/components/Items/Filters';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import { useActions } from 'src/hooks/useActions';
import { dineroAdd, dineroDivide, dineroFormat, dineroMultiply, dineroSubtractAmount, fromJSDateToISO } from 'src/common/utils/dataFormat';
import { IOrderForClient } from 'src/models/IOrder';
import './styles.scss';

interface IProps {
  order: IOrderForClient;
  open: boolean;
  handleClose: () => void;
}
const EditDatesModal: FC<IProps> = ({ order, handleClose, open }) => {

  const { changeOrderDates } = useActions();
  const [startDate, setStartDate] = useState(order.start_date);
  const [endDate, setEndDate] = useState(order.end_date);
  const [newPrice, setNewPrice] = useState(0);

  useEffect(() => {
    setStartDate(order.start_date);
    setEndDate(order.end_date);
  }, [order]);

  const handleChangePrice = () => {
    const newDate = DateTime.fromISO(endDate)
      .diff(DateTime.fromISO(startDate), ['days', 'hours'])
      .toObject();
    const { hours, days } = newDate;
    let vanPrice = dineroMultiply(order.van!.price, newDate.days!);

    if (hours && days) {
      if (hours <= 6 && hours > 0) {
        vanPrice = dineroAdd(
          vanPrice,
          dineroDivide(order.van?.price!, 4),
          order.currency!
        );
      } else if (hours > 6 && hours <= 12) {
        vanPrice = dineroAdd(
          vanPrice,
          dineroDivide(order.van?.price!, 2),
          order.currency!
        );
      } else if (hours > 12 && hours <= 18) {
        vanPrice = dineroAdd(
          vanPrice,
          dineroSubtractAmount(
            order.van?.price!,
            dineroDivide(order.van?.price!, 4),
            order.currency!
          ),
          order.currency!
        );
      } else if (hours > 18 && hours <= 24) {
        vanPrice = dineroAdd(
          vanPrice,
          order.van?.price!,
          order.currency!
        );
      }
    }
    if (!vanPrice) vanPrice = order.van!.price;
    const extrasPrice = sumBy(order.fee_list, 'price');
    setNewPrice(
      dineroAdd(
        vanPrice,
        extrasPrice,
        order.currency!
      )
    );
  };

  useEffect(() => {
    fromJSDateToISO(order.start_date) === fromJSDateToISO(startDate)
      && fromJSDateToISO(order.end_date) === fromJSDateToISO(endDate)
      ? setNewPrice(0)
      : handleChangePrice();
  }, [startDate, endDate]);

  const handleCloseModal = () => {
    setStartDate(order.start_date);
    setEndDate(order.end_date);
    setNewPrice(0);
    handleClose();
  };

  const handleSubmit = () => {
    const dates = {
      period: {
        start_date: startDate,
        end_date: endDate
      },
      feeList: order.fee_list
    };
    changeOrderDates(
      dates,
      order.id
    );
    handleCloseModal();
  };

  return (
    <Modal
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={handleCloseModal}
    >
      <div className='client__modal edit-dates-modal'>
        <div className='client__modal__title'>Update dates</div>
        <Filters.Times
          check={false}
          startDate={startDate}
          endDate={endDate}
          handleStartDate={setStartDate}
          handleEndDate={setEndDate}
          className={'singleVan__availability__formContainer__times'}
        />
        {newPrice ? (
          <div className='edit-dates-modal__new-price'>
            New rate would be: {dineroFormat(newPrice, order.currency)}
          </div>
        ) : <></>}
        <div className='col-2 edit-dates-modal__buttons'>
          <SecondTypeButton
            children='Cancel'
            onClick={handleCloseModal}
          />
          <Button
            onClick={handleSubmit}
            children='Update dates'
            disabled={!newPrice}
          />
        </div>
      </div>
    </Modal>
  );
};
export default EditDatesModal;
