import { createRef, FC, useEffect, useState } from "react";
import {
  CanvasPath,
  ReactSketchCanvas,
  ReactSketchCanvasProps,
  ReactSketchCanvasRef,
} from "react-sketch-canvas";
import "./styles.scss";
import SecondTypeButton from "../button/SecondTypeButton";
import { Button, CircularProgress, FormHelperText } from "@mui/material";

type Handlers = [string, () => void][];

interface IProps {
  data: any;
  setDate: any;
  check: boolean;
  onSubmit: any;
  disabled: boolean;
}

const Painter: FC<IProps> = ({ data, setDate, check, onSubmit, disabled }) => {
  const [canvasProps, setCanvasProps] = useState<
    Partial<ReactSketchCanvasProps>
  >({
    className: `react-sketch-canvas`,
    width: "100%",
    height: "130px",
    preserveBackgroundImageAspectRatio: "none",
    strokeWidth: 2,
    eraserWidth: 5,
    strokeColor: "#000000",
    canvasColor: "#FFFFFF",
    svgStyle: {},
    allowOnlyPointerType: "all",
  });

  const canvasRef = createRef<ReactSketchCanvasRef>();

  const [paths, setPaths] = useState<CanvasPath[]>([]);
  const [lastStroke, setLastStroke] = useState<{
    stroke: CanvasPath | null;
    isEraser: boolean | null;
  }>({ stroke: null, isEraser: null });

  const undoHandler = () => {
    const undo = canvasRef.current?.undo;
    setDate("");
    if (undo) {
      undo();
    }
  };

  const resetCanvasHandler = () => {
    const resetCanvas = canvasRef.current?.resetCanvas;
    if (resetCanvas) {
      resetCanvas();
      setDate("");
    }
  };
  const buttonsWithHandlers: Handlers = [
    ["UNDO", undoHandler],
    ["CLEAR", resetCanvasHandler],
  ];

  const onChange = (updatedPaths: CanvasPath[]) => {
    !disabled && setPaths(updatedPaths);
  };
  const loadPaths = (data: CanvasPath[]) => canvasRef.current?.loadPaths(data);

  useEffect(() => {
    // orderData.client.paths && loadPaths(orderData.client.paths);
    setCanvasProps({
      ...canvasProps,
      style: {
        border: check ? "1px solid red" : "",
        display: disabled ? "none" : "",
      },
    });
    paths.length &&
      canvasRef.current?.exportSvg().then((response) => setDate(response));
  }, [check, paths, disabled]);

  return (
    <div className="canvas-sign">
      <div className="col-3">
        {buttonsWithHandlers.map(([label, handler]) => (
          <SecondTypeButton
            disabled={disabled}
            key={label}
            onClick={handler}
            children={label}
          />
        ))}
        <Button
          disabled={disabled || check}
          onClick={onSubmit}
          children={
            <>
              SIGN
              {disabled && <CircularProgress size={20} sx={{ ml: 1 }} />}
            </>
          }
        />
      </div>

      <ReactSketchCanvas
        ref={canvasRef}
        onChange={onChange}
        onStroke={(stroke, isEraser) =>
          !disabled && setLastStroke({ stroke, isEraser })
        }
        {...canvasProps}
      />
      {check && <FormHelperText children="You have to draw your sign" />}
    </div>
  );
};

export default Painter;
