import { FC } from "react";
import Icons from "src/common/icons";
import "./styles.scss";

interface IProps {
  margin: string
}

const NoResult: FC<IProps> = ({ margin }) => (
  <div
    className="no-result"
    style={{ margin: margin }}
  >
    <img src='../images/noResult.jpg' alt="noResult" width={100} />
    <div>Oops - no vans for this search</div>
    <div className='no-result__container'>
      <Icons.Oneway />
      <span>Change the End location for oneway trips.</span>
    </div>
    <div>Try to clear filters or change your dates.</div>
    <div>
      You can request a manual quote
      <a href="https://sprinter-rentals.com/reserve-sprinter-van-online">
        here
      </a>
    </div>
  </div>
);
export default NoResult;
