import { SeasonPriceAction, SeasonPriceActionEnum, SeasonPriceState } from "./types";

const initialState: SeasonPriceState = {
    seasonPriceList: [],
    isLoading: {
        all: false,
    },
};
const seasonPrice = (state = initialState, action: SeasonPriceAction): SeasonPriceState => {
  switch (action.type) {
    case SeasonPriceActionEnum.REQUEST_SEASONPRICE:
      return { ...state, isLoading: { ...state.isLoading, all: true } };
    case SeasonPriceActionEnum.RESULT_SEASONPRICE:
      return {
        ...state,
        seasonPriceList: action.data,
        isLoading: { ...state.isLoading, all: !action.data },
      };
    default:
      return state;
  }
};
export default seasonPrice;
