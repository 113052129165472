import { FC } from 'react';
import { sum } from 'lodash';
import { dineroFormat, dineroSubtractPrice } from 'src/common/utils/dataFormat';
import { IOrderForClient } from 'src/models/IOrder';
import Icons from 'src/common/icons';
import './styles.scss';
import './media.scss';

interface IProps {
  order: IOrderForClient
}

const ArticlesCard: FC<IProps> = ({ order }) => {

  const {
    van_id,
    van,
    price,
    fee_list,
    currency
  } = order;

  return (
    <div className='articles-card'>
      <div className='client__order__main__header'>
        <div className='client__order__main__header__container'>
          <h4>Articles</h4>
          <span>Email us to change your van</span>
        </div>
        {/* <Icons.Pencil /> */}
      </div>
      <div className='articles-card__van'>
        <div className='articles-card__van__container'>
          <img src={van!.photos?.exterior[0]} alt='van' />
          <div className='articles-card__van__price'>
            <h6>
              {dineroSubtractPrice(
                price,
                sum(fee_list.map((i) => i.price)),
                currency
              )}
            </h6>
            <span>total for the van</span>
          </div>
        </div>
        <div className='articles-card__van__title'>
          {van!.mark}
          <a
            href={`https://van.sprinter-rentals.com/van/143${van_id}`}
            target="_blank"
          >
            here
          </a>
        </div>
      </div>
      <div className='articles-card__fees'>
        {fee_list.map((fee, i) => (
          <div className='articles-card__fees__fee' key={i}>
            <div className='articles-card__fees__fee__container'>
              <img src={fee.logo} alt='extra' loading='lazy' />
              <span>{fee.name}</span>
            </div>
            <span>
              {dineroFormat(fee.price, fee.currency)}
            </span>
          </div>
        ))}
      </div>
      <div className='articles-card__total'>
        <span>Incl. All Fees:</span>
        <b>
          {`Total ${dineroFormat(price, currency)}`}
        </b>
      </div>
    </div>
  );
};

export default ArticlesCard;
