import { FC, useState } from 'react';
import Steps from './components/Steps';
import DeleteModal from 'src/components/Items/DeleteModal';
import ActionsCard from './components/Cards/Actions';
import ArticlesCard from './components/Cards/Articles';
import ClientCard from './components/Cards/Client';
import CommonCard from './components/Cards/Common';
import DocumentsCard from './components/Cards/Documents';
import DriversCard from './components/Cards/Drivers';
import PaymentsCard from './components/Cards/Payments';
import NoteCard from './components/Cards/Note';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { IOrderForClient } from 'src/models/IOrder';
import Icons from 'src/common/icons';
import '../../styles.scss';
import '../../media.scss';

interface IProps {
  order: IOrderForClient,
  isQuoted?: boolean
}

const OrderCard: FC<IProps> = ({ order, isQuoted }) => {

  const {
    client: { client },
  } = useTypedSelector((state) => state);
  const [openLicense, setOpenLicense] = useState(false);
  const [openContract, setOpenContract] = useState(false);

  const { id, status, van } = order;

  return (
    <>
      <div className='client__order'>
        <div className='client__order__header'>
          <h3>Upcoming Trip: {id}</h3>
          <h6>See All Trips Below</h6>
          <div className='client__order__header__badges'>
            <div className='client__order__header__badge'>
              <Icons.ReservedVan />
              <span>vehicle is reserved</span>
            </div>
            <div className='client__order__header__badge'>
              <span>STATUS:</span>
              <div className={`client__order__header__badge__status ${status}`}>
                {status === 'leads' ? 'quoted' : status}
              </div>
            </div>
          </div>
        </div>
        <Steps order={order} />
        <div className='client__order__main'>
          <h3>Manage Your Booking.</h3>
          <div className='client__order__main__cards'>
            <CommonCard order={order} />
            <ClientCard id={id} van={van!} />
            <PaymentsCard order={order} isQuoted={isQuoted} />
            <ArticlesCard order={order} />
            <DocumentsCard order={order} />
            <DriversCard order={order} vanId={van!.id} />
            <ActionsCard order={order} />
            <NoteCard order={order} />
          </div>
        </div>
      </div>
      <DeleteModal
        id={client!.id}
        type='license'
        open={openLicense}
        handleClose={() => setOpenLicense(false)}
      />
      <DeleteModal
        id={id}
        type='insurance'
        open={openContract}
        handleClose={() => setOpenContract(false)}
      />
    </>
  );
};

export default OrderCard;
