
export enum CancelOrderActionEnum {
    CANCEL_ORDER = "CANCEL_ORDER",
    RESULT_CANCEL_ORDER = "RESULT_CANCEL_ORDER",
}
export interface CancelOrderState {
    isLoading: boolean,
    result?: number | string;
}

export interface RequestCancelOrderAction {
    type: CancelOrderActionEnum.CANCEL_ORDER;
    order_id: number;
    email: string;
}
export interface ResultCancelOrderAction {
    type: CancelOrderActionEnum.RESULT_CANCEL_ORDER;
    status: string | number;
}

export type CancelOrderAction =
    RequestCancelOrderAction
    | ResultCancelOrderAction;
