import {
  DriverLicenseAction,
  DriverLicenseActionEnum,
  DriverLicenseState,
} from './types';

const initialState: DriverLicenseState = {
  isLoading: {
    set: false,
    uploadingImage: false,
  },
};

const driveLicense = (
  state = initialState,
  action: DriverLicenseAction
): DriverLicenseState => {
  switch (action.type) {
    case DriverLicenseActionEnum.ADD_DRIVER_LICENSE_IMAGES:
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadingImage: true, set: true },
      };
    case DriverLicenseActionEnum.RESULT_ADD_DRIVER_LICENSE_IMAGES:
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadingImage: !action.statuses },
      };
    case DriverLicenseActionEnum.SET_DRIVER_LICENSE:
      return {
        ...state,
        isLoading: { ...state.isLoading, set: true },
      };
    case DriverLicenseActionEnum.RESULT_SET_DRIVER_LICENSE:
      return {
        ...state,
        isLoading: { ...state.isLoading, set: false },
        result: action.status,
      };
    case DriverLicenseActionEnum.ERROR_SET_DRIVER_LICENSE: {
      return {
        ...state,
        isLoading: { ...state.isLoading, set: false },
        error: action.statusText,
      };
    }
    default:
      return state;
  }
};
export default driveLicense;
