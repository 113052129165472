import { ClientActionEnum } from 'src/store/reducers/client/types';
import { takeLatest } from 'redux-saga/effects';

function* fetchExit() {
  yield localStorage.clear();
  yield location.reload();
}
export function* actionWatcherExit() {
  yield takeLatest(ClientActionEnum.EXIT, fetchExit);
}
