import { put, takeLatest } from "redux-saga/effects";
import instanceWithToken from "../setWithToken";
import { ClientActionEnum } from "src/store/reducers/client/types";
import { ClientActionCreators } from "src/store/reducers/client/action-creators";

function* fetchSendReceipt({
  orderId,
  handlePaymentId
}: ReturnType<typeof ClientActionCreators.sendReceipt>) {
  try {
    const { status, data } = yield instanceWithToken.post(`/personal/receipt/${orderId}`, {
      type: 'get receipt DIY',
      handlePaymentId
    });
    yield put(ClientActionCreators.resultSendReceipt(status, data));
  } catch (e) {
    console.log(e);
    yield put(ClientActionCreators.resultSendReceipt(400));
  }
}

export function* actionWatcherSendReceipt() {
  yield takeLatest(ClientActionEnum.REQUEST_SEND_RECEIPT, fetchSendReceipt);
}