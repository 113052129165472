import { FC, useState, useEffect } from "react";
import VanCard from '../../../item/index';
import { useTypedSelector } from "src/hooks/useTypeSelector";
import { IVanFetch } from "src/models/IVan";


interface IProps {
  data: IVanFetch[];
  loading: boolean;
  setLoading: (loading: boolean) => void;
  show: number;
  setShow: (show: number) => void;
}

const InfiniteScrollList: FC<IProps> = ({
  data,
  loading,
  show,
  setLoading,
  setShow
}) => {

  const {
    errors,
    isFilterValidate
  } = useTypedSelector((state) => state.van);

  const [list, setList] = useState(data.slice(0, 10));

  useEffect(() => {
    if (!loading) return;
    if (show >= data.length) return setLoading(false);
    setTimeout(() => {
      setList(list.concat(data.slice(show, show + 10)));
      setShow(show + 10);
      setLoading(false);
    }, 1000);
  }, [loading]);

  return (
    <>
      {list.map(van => (
        <VanCard
          van={van}
          key={van.id}
          errors={errors}
          isFilterValidate={isFilterValidate}
        />
      ))}
    </>
  );
};
export default InfiniteScrollList;