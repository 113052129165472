import { FC } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import '../styles.scss';
import '../media.scss';


const Check: FC = () => {

  return (
    <div className='van__order__extra__item__icons-container'>
      <CircleIcon
        fontSize='small'
        className='van__order__extra__item__icons-container__circleIcon'
      />
      <CheckIcon
        fontSize='small'
        className='van__order__extra__item__icons-container__checkIcon'
      />
    </div>
  );
};
export default Check;
