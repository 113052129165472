import { Oneway } from 'src/models/types';
import {
  OnewayActionEnum,
  RequestOnewayAction,
  ResultOnewayAction,
} from './types';

export const OnewayActionCreator = {
  getOneway: (): RequestOnewayAction => ({
    type: OnewayActionEnum.REQUEST_ONEWAY,
  }),
  resultGetOneway: (data: Oneway[]): ResultOnewayAction => ({
    type: OnewayActionEnum.RESULT_ONEWAY,
    data,
  }),
};
