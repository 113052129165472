import { ChangeEvent, FC } from "react";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import SimpleMultiSelect from "src/components/Items/select/SimpleMultiSelect";
import { useActions } from "src/hooks/useActions";
import { getNames } from "src/common/utils/others";

const Feature: FC = () => {
  const { setFeatureFilter } = useActions();
  const {
    feature: { featureList, isLoading },
    van: { filters },
  } = useTypedSelector((state) => state);

  const setFeatures = (features: string[]) => setFeatureFilter(features);

  const handleChangeMultiSelect = (
    event: ChangeEvent<HTMLInputElement>,
    type: any
  ) => {
    const {
      target: { value },
    } = event;
    type(typeof value === "string" ? value.split(",") : value);
  };

  return !isLoading.all ? (
    <div className="filter-features">
      <SimpleMultiSelect
        data={filters.features!}
        setData={(e) => handleChangeMultiSelect(e, setFeatures)}
        dataArray={getNames(featureList).sort()}
        gridColumns={1}
        maxWidth="230px"
        title="features"
      />
    </div>
  ) : null;
};

export default Feature;
