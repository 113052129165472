import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSnackbar } from 'notistack';
import { Skeleton } from '@mui/material';
import SmallHeader from '../Van/singleVanPage/SmallHeader';
import SmallFooter from 'src/components/Footer/SmallFooter';
import CheckoutForm from '../Order/Stripe';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { LeadData } from 'src/store/reducers/order/types';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY!);

const CheckQuotePage: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { checkQuoteOrder, setupFilters, payNow } = useActions();
  const { result, quoteCheck } = useTypedSelector((state) => state.order);

  const options: any = {
    clientSecret: quoteCheck,
    appearance: {
      theme: 'stripe',
    },
  };

  useEffect(() => {
    if (id) checkQuoteOrder(id);
  }, [id]);

  useEffect(() => {
    if (result.check) {
      if (result.check === 200) {
        if (typeof quoteCheck !== 'string') {
          navigate('/');
          setupFilters({ ...(quoteCheck as LeadData), page: 1, take: 10 });
          enqueueSnackbar(
            'Unfortunately, the van is already booked. Please choose another one.',
            { variant: 'info' }
          );
        }
      } else navigate('/');
    }
  }, [result.check]);

  return (
    <>
      <div className='header'>
        <SmallHeader />
      </div>
      <div style={{ maxWidth: '500px', margin: '200px auto 0 auto' }}>
        {typeof quoteCheck === 'string' ? (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm startDate={Date.now().toString()} />
          </Elements>
        ) : (
          <Skeleton
            sx={{ maxWidth: `100%` }}
            height={230}
            style={{ transform: `none` }}
          />
        )}
      </div>
      <SmallFooter />
    </>
  );
};

export default CheckQuotePage;
