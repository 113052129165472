const cutText = (text: string, amount: number) =>
  text.length > amount ? `${text.slice(0, amount)}...` : text;

const getNames = (dataArray: any[]) => dataArray.map(({ name }) => name);

const getListHoursAMPM = () => {
  const minuteArray: string[] = [];
  ['AM', 'PM'].forEach((time) => {
    minuteArray.push(time === 'AM' ? 'Midnight' : 'Noon');
    minuteArray.push(`12:30 ${time}`);
    for (let j = 1; j < 12; j++) {
      ['00', '30'].forEach((minute) =>
        minuteArray.push(`${j}:${minute} ${time}`)
      );
    }
  });
  return minuteArray;
};

class EnumHelpers {
  static getNamesAndValues<T extends number>(e: any) {
    return EnumHelpers.getNames(e).map((n) => ({ name: n, value: e[n] as T }));
  }

  static getNames(e: any) {
    return EnumHelpers.getObjValues(e).filter(
      (v) => typeof v === 'string'
    ) as string[];
  }

  static getValues<T extends number>(e: any) {
    return EnumHelpers.getObjValues(e).filter(
      (v) => typeof v === 'number'
    ) as T[];
  }

  static getSelectList<T extends number, U>(
    e: any,
    stringConverter: (arg: U) => string
  ) {
    const selectList = new Map<T, string>();
    this.getValues(e).forEach((val) =>
      selectList.set(val as T, stringConverter(val as unknown as U))
    );
    return selectList;
  }

  static getSelectListAsArray<T extends number, U>(
    e: any,
    stringConverter: (arg: U) => string
  ) {
    return Array.from(this.getSelectList(e, stringConverter), (value) => ({
      value: value[0] as T,
      presentation: value[1],
    }));
  }

  private static getObjValues(e: any): (number | string)[] {
    return Object.keys(e).map((k) => e[k]);
  }
}

const scrollToTop = () =>
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });

const scrollToBottom = () =>
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth',
  });

const handleScroll = (ref: any) => {
  window.scrollTo({
    top: ref.current.offsetTop,
    left: 0,
    behavior: "smooth",
  });
};

export { cutText, getNames, getListHoursAMPM, EnumHelpers, scrollToTop, scrollToBottom, handleScroll };
