import { GlobalActionCreators } from './global/action-creators';
import { ContractActionCreators } from './contract/action-creators';
import { DriverLicenseActionCreators } from './driverLicense/action-creators';
import { DriverActionCreators } from './driver/action-creators';

import { ExtraActionCreators } from './extra/action-creators';
import { FeatureActionCreators } from './feature/action-creators';
import { LocationActionCreators } from './location/action-creators';
import { OrderActionCreators } from './order/action-creators';
import { CancelOrderActionCreators } from './cancelOrder/action-creators';
import { VanActionCreators, VanFilterActionCreators } from './van/action-creators';
import { OnewayActionCreator } from './oneway/action-creator';
import { SeasonPriceActionCreators } from './seasonPrice/action-creators';
import { SubPricesActionCreators } from './subPrice/action-creators';
import { SubLocationActionCreators } from './subLocation/action-creators';
import { ClientActionCreators } from './client/action-creators';

export const allActionCreators = {
  ...FeatureActionCreators,
  ...OrderActionCreators,
  ...CancelOrderActionCreators,
  ...VanActionCreators,
  ...VanFilterActionCreators,
  ...ExtraActionCreators,
  ...ContractActionCreators,
  ...LocationActionCreators,
  ...DriverLicenseActionCreators,
  ...GlobalActionCreators,
  ...OnewayActionCreator,
  ...DriverActionCreators,
  ...SeasonPriceActionCreators,
  ...SubPricesActionCreators,
  ...SubLocationActionCreators,
  ...ClientActionCreators
};
