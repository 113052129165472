import { FC, useState } from 'react';
import { Backdrop, Button, CircularProgress, Modal } from '@mui/material';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { validateField } from 'src/common/utils/validation';
import './styles.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const LoginModal: FC<IProps> = ({ open, onClose }) => {
  const { signup } = useActions();
  const { isLoading } = useTypedSelector((state) => state.client);
  const [email, setEmail] = useState('');
  const [check, setCheck] = useState(false);
  const [error, setError] = useState('');
  const [isShowInfo, setIsShowInfo] = useState(false);

  const validateSignUp = (): boolean => {
    const validateEmail = validateField(email);
    setError(validateEmail);
    return !validateEmail;
  };

  const handleSignUp = () => {
    setCheck(true);
    if (validateSignUp()) {
      signup(email.toLowerCase().trim());
      setError('');
      setIsShowInfo(true);
    }
    setCheck(false);
  };

  const handleClose = () => {
    setEmail('');
    setError('');
    setCheck(false);
    setIsShowInfo(false);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <>
        <div className='login-modal'>
          <>
            <h3 className='login-modal__title'>Manage Your Trips</h3>
            {isLoading.signup ? (
              <CircularProgress size={100} />
            ) : (
              isShowInfo ? (
                <>
                  <p className='login-modal__signup__text'>
                    We've sent a link to your email. Please follow it to access your trips.
                  </p>
                  <div className='login-modal__buttons'>
                    <SecondTypeButton
                      children='Close this window'
                      onClick={handleClose}
                    />
                  </div>
                </>
              ) : (
                <>
                  <SimpleInput
                    data={email}
                    setData={setEmail}
                    title='Email'
                    placeholder='Enter your email'
                    style={{ marginBottom: '20px', textAlign: 'left' }}
                    check={check}
                    error={error}
                    isHelperText
                  />
                  <div className='login-modal__buttons'>
                    <SecondTypeButton
                      children='Cancel'
                      onClick={handleClose}
                    />
                    <Button
                      variant='contained'
                      onClick={handleSignUp}
                      children='Log in'
                    />
                  </div>
                </>
              )
            )}
          </>
        </div>
      </>
    </Modal >
  );
};
export default LoginModal;
