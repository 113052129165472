import { IDriver, IDriverFetch } from 'src/models/IDriver';

export enum DriverActionEnum {
  ADD_DRIVER = 'ADD_DRIVER',
  RESULT_ADD_DRIVER = 'RESULT_ADD_DRIVER',
}

export interface DriverState {
  isLoading: {
    add: boolean;
  };
  driver: IDriver | {};
  error?: string;
}
export interface AddDriverAction {
  type: DriverActionEnum.ADD_DRIVER;
  drivers: IDriver[];
  id: string;
}
export interface ResultAddDriverAction {
  type: DriverActionEnum.RESULT_ADD_DRIVER;
  driver: IDriverFetch;
}

export type DriverAction = AddDriverAction | ResultAddDriverAction;
