import { Button } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { dineroFormat } from 'src/common/utils/dataFormat';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import PreviewExtra from 'src/components/Items/previewPages/PreviewExtra';
import useIsMobile from 'src/hooks/useIsMobile';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { IExtra, IOrder } from 'src/models/IOrder';
import './styles.scss';

interface IProps {
  order: IOrder;
  onChange: (name: string, value: any) => void;
}

const Store: FC<IProps> = ({ order, onChange }) => {
  const isMobile = useIsMobile();
  const {
    extraList,
    isLoading: { all },
  } = useTypedSelector((state) => state.extra);
  const [extras, setExtras] = useState<IExtra[]>([]);

  useEffect(() => {
    if (extraList.length) {
      const newArray = extraList.filter(ex => !ex.isForHost);
      setExtras(newArray);
    }
  }, [extraList]);

  const handleChangeExtra = (extra: IExtra) => {
    const existOrder = order.extras.find((ex) => ex.id === extra.id);
    existOrder
      ? onChange(
        'extras',
        order.extras.filter((ext) => ext.id !== existOrder?.id)
      )
      : onChange('extras', [...order.extras, extra]);
  };

  const handleAddChildSeat = (extra: IExtra) =>
    onChange('extras', [...order.extras, extra]);

  return (
    <div className='order__store'>
      {(!all && extras.length) ? (
        extras.map((extra) => {
          const {
            id,
            logo,
            name,
            title,
            description,
            price,
            currency,
            isClientEdit,
          } = extra;
          return (
            <React.Fragment key={id}>
              {isClientEdit && (
                <div className='extra-card'>
                  <div className='extra-card__logo'>
                    <img src={logo} alt='logo' />
                  </div>
                  <div className='extra-card__info'>
                    <div className='extra-card__info__name'>
                      {name}
                      {isMobile && (
                        <div className='extra-card__price'>
                          {dineroFormat(price, currency)}
                        </div>
                      )}
                    </div>
                    <div className='extra-card__info__title'>{title}</div>
                    {!isMobile && (
                      <div
                        children={description}
                        className='extra-card__info__description'
                      />
                    )}
                  </div>
                  {isMobile && (
                    <div
                      children={description}
                      className='extra-card__info__description'
                    />
                  )}
                  <div className='extra-card__action'>
                    {!isMobile && (
                      <div className='extra-card__price'>
                        {dineroFormat(price, currency)}
                      </div>
                    )}

                    <div className='extra-card__action__button'>
                      {order.extras.find((ex) => ex.id === id) ? (
                        (extra.id === 12 || extra.id === 41) ? (
                          <Button
                            children='Add more'
                            variant='outlined'
                            sx={{
                              height: `inherit`,
                              width: `inherit`,
                              padding: 0
                            }}
                            onClick={() => handleAddChildSeat(extra)}
                          />
                        ) : (
                          <SecondTypeButton
                            children='Delete'
                            variant='outlined'
                            sx={{
                              height: `inherit`,
                              width: `inherit`,
                            }}
                            onClick={() => handleChangeExtra(extra)}
                          />
                        )
                      ) : (
                        <Button
                          children='Select'
                          variant='outlined'
                          sx={{
                            height: `inherit`,
                            width: `inherit`,
                          }}
                          onClick={() => handleChangeExtra(extra)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })
      ) : (
        <PreviewExtra />
      )}
    </div>
  );
};

export default Store;
