import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import "./styles.scss";
import AnotherFilter from "../Items/Filters/Another";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import { setAnotherFilter } from "src/common/utils/setFilters";
import { useActions } from "src/hooks/useActions";

const Aside: FC<{ handleClose?: any }> = ({ handleClose }) => {
  const { filters } = useTypedSelector((state) => state.van);
  const [params, setSearchParams] = useSearchParams();
  const { clearFilter } = useActions();

  const handleSetFilter = () => {
    setAnotherFilter(params, filters);
    setSearchParams(params);
    handleClose && handleClose();
  };

  const handleClearFilter = () => {
    params.delete("features");
    params.delete("seats");
    params.delete("type");
    params.delete("price");
    setSearchParams(params);
    clearFilter();
  };

  return (
    <AnotherFilter
      handleClearFilter={handleClearFilter}
      handleSetFilter={handleSetFilter}
    />
  );
};

export default Aside;
