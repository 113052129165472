import { FC } from 'react';
import { orderBy } from 'natural-orderby';
import { useSnackbar } from 'notistack';
import lodash from 'lodash';
import SimpleSelect from '../../select/SimpleSelect';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { Location } from './../../../../store/reducers/location/types';
import { getNames } from 'src/common/utils/others';
import { Oneway } from 'src/models/types';

interface IProps {
  check: boolean;
  selectedLocations?: Location[];
  className?: string;
}

const AnotherLocation: FC<IProps> = ({ check, selectedLocations, className }) => {
  const { setEndLocationFilter } = useActions();
  const { enqueueSnackbar } = useSnackbar();
  const { locationList } = useTypedSelector((state) => state.location);
  const { filters } = useTypedSelector((state) => state.van);
  const { onewayList } = useTypedSelector((state) => state.oneway);
  const setLocation = (end_location: string) => {
    const oneway = lodash.filter(onewayList, {
      start_location: filters.location,
      end_location,
    })[0] as Oneway;
    if (oneway) {
      setEndLocationFilter(end_location);
    } else if (!filters.location && end_location) {
      enqueueSnackbar('Please select a start location', { variant: 'info' });
    } else {
      (filters.location !== end_location) && (
        enqueueSnackbar('Not available yet', { variant: 'info' })
      );
      setEndLocationFilter('');
    }
  };
  const sortedLocation = orderBy(
    (selectedLocations ? selectedLocations : locationList),
    [(location) => location.country, (location) => location.name],
    ['asc', 'asc']
  );
  return (
    <div className={`filter__location ${className}`}>
      <SimpleSelect
        data={(filters.end_location && filters.end_location !== '') ? filters.end_location : 'Same'}
        setData={setLocation}
        dataArrey={getNames(sortedLocation)}
        placeholder='Where?'
        notShowSelect
        title='End in'
        check={check}
      />
    </div>
  );
};
export default AnotherLocation;
