import { IVanFilter } from "src/models/IVanFilter";
import { fromISOtoFormat } from "./dataFormat";

const transformValueToURL = (filter: keyof IVanFilter, filters: IVanFilter) => {
  switch (filter) {
    case "start_date":
      return fromISOtoFormat(filters[filter]!, "X");
    case "end_date":
      return fromISOtoFormat(filters[filter]!, "X");
    case "seats": {
      return filters[filter]!;
    }
    case "price": {
      return (
        filters.price &&
        `${filters.price.startPrice ? filters.price.startPrice : 0}-${
          filters.price.endPrice
        }`
      );
    }
    case "features": {
      return filters[filter]?.length
        ? filters[filter]?.map((value) => value.replaceAll(" ", "-")).join("_")
        : "delete";
    }
    case "tags": {
      return filters[filter]?.length
        ? filters[filter]?.map((value) => value.replaceAll(" ", "-")).join("_")
        : "delete";
    }

    default:
      return filters[filter];
  }
};
export default transformValueToURL;
