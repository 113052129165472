import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import { useActions } from "src/hooks/useActions";
import { IVanFetch } from "src/models/IVan";

interface IProps {
    van: IVanFetch;
}

const VanModalFooter: FC<IProps> = ({van}) => {

    const navigate = useNavigate();
    const { setupOrder } = useActions();
    const { isFilterValidate } = useTypedSelector((state) => state.van);

    const handleOrder = () => {
        navigate('/order');
        setupOrder(van);
    };

    return (
        <div className='vanModal__footer'>
            {isFilterValidate ? (
                <Button variant='contained' onClick={handleOrder}>
                    GET THIS VAN
                </Button>
            ) : (
                <Link to={`/van/143${van.id}`} target="_blank">
                    <Button variant='contained' onClick={() => localStorage.setItem('isScroll', 'true')}>
                        GET THIS VAN
                    </Button>
                </Link>
            )}
        </div>
    );
};
export default VanModalFooter;
