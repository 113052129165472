import { FC } from 'react';
import { Tooltip } from '@mui/material';
import DocumentsItem from '../DocumentsItem';
import useIsMobile from 'src/hooks/useIsMobile';
import { IDriverFetch } from 'src/models/IDriver';
import Icons from 'src/common/icons';
import './styles.scss';

interface IProps {
  driver: IDriverFetch;
  handleOpenDelete: (id: number) => void;
}

const DriverItem: FC<IProps> = ({ driver, handleOpenDelete }) => {

  const isMobile = useIsMobile();

  return (
    <div className='driver-docs'>
      <div className='driver-docs__container'>
        {(
          isMobile &&
          !driver.driver_license?.photos?.length &&
          !driver.driver_license?.insurance
        ) ? (
          <div>missing</div>
        ) : (
          <>
            <DocumentsItem
              title='License'
              links={driver.driver_license?.photos as string[] | undefined}
            />
            <DocumentsItem
              title='Insurance'
              links={driver.driver_license?.insurance as string[] | undefined}
            />
          </>
        )}
      </div>
      {(driver.driver_license?.photos?.length ||
        driver.driver_license?.insurance) && (
          <Tooltip title='Delete documents'>
            <div
              onClick={() => handleOpenDelete(driver.id)}
              className='driver-docs__delete'
            >
              <Icons.OutlinedDelete />
            </div>
          </Tooltip>
        )}
    </div>
  );
};

export default DriverItem;
