import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "./styles.scss";
import SwiperCore, { Zoom, Navigation, Mousewheel, Pagination } from "swiper";
SwiperCore.use([Zoom, Mousewheel, Navigation, Pagination]);

interface IProps {
  data: string[];
  onClick?: (index: number) => void;
}

const CustomSwiper: FC<IProps> = ({ data, onClick }) => {
  return (
    <>
      <Swiper
        style={{
          //@ts-ignore
          // "--swiper-pagination-color": "#C2FF45",
          "--swiper-navigation-color": "#FFFFFF",
        }}
        zoom={true}
        grabCursor={true}
        centeredSlides={true}
        spaceBetween={30}
        freeMode={true}
        mousewheel={true}
        // pagination={{
        //   clickable: true,
        //   dynamicBullets: true,
        // }}
        navigation={true}
        className="mySwiper"
      >
        {data.map((photo, index) => (
          <SwiperSlide key={index}>
            <div className="swiper-zoom-container">
              <img src={photo} alt="swiper" loading="lazy" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default CustomSwiper;
