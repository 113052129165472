import { FC } from "react";
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import { useTypedSelector } from "src/hooks/useTypeSelector";

interface IProps {
    features: string[];
    className: string;
}

const services = [
    'Text/call host prior to trip',
    'Online check-in',
    'Arrive & drive, no rental counter',
    'One Way Trip',
    'Return empty (fueling fee applies)'
];

const DetailsContainer: FC<IProps> = ({ features, className }) => {

    const extras = useTypedSelector((state) => state.extra.extraList);

    return (
        <div className='borderBottom'>
            <div className={`${className}__container`}>
                <div className={`${className}__features`}>
                    <h3>Services Offered</h3>
                    <div>
                        {services.map((s, i) => (
                            <div key={i} className={`${className}__features__item`}>
                                {/* <Icons.Check classes={'singleVan__features__item__checkIcon'} /> */}
                                <div className={`${className}__iconsContainer`}>
                                    <CircleIcon
                                        fontSize={className === 'vanModal' ? 'medium' : 'large'}
                                        className={`${className}__iconsContainer__circleIcon`}
                                    />
                                    <CheckIcon
                                        fontSize={className === 'vanModal' ? 'small' : 'medium'}
                                        className={`${className}__iconsContainer__checkIcon`}
                                    />
                                </div>
                                <h4 className={`${className}__features__item__title`}>{s}</h4>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={`${className}__extras`}>
                    <h3>Extras available at checkout</h3>
                    {/* <p className='${className}__extras__description'>
                        Add optional extras to your trip at checkout
                    </p> */}
                    <div className={`${className}__extras__container`}>
                        {extras.map((e) => (e.isClientEdit &&
                            <div style={{
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center',
                                marginBottom: '10px'
                            }}
                            >
                                <div className={`${className}__extras__container__item`}>
                                    <img src={e.logo} alt='extra' />
                                </div>
                                {e.id === 1 ? (
                                    <div className={`${className}__extras__container__miles`}>
                                        <p style={{ marginBottom: 0 }}>{e.name}</p>
                                        <div className='order-cart__item__info__description'>
                                            {e.title}
                                        </div>
                                    </div>
                                ) : (
                                    <p>{e.name}</p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={`${className}__features`} style={{ marginBottom: 0 }}>
                    <h3>Amenities / features</h3>
                    <div>
                        {features.map((f, i) => (
                            <div key={i} className={`${className}__features__item`}>
                                {/* <Icons.Check classes={'${className}__features__item__checkIcon'} /> */}
                                <div className={`${className}__iconsContainer`}>
                                    <CircleIcon
                                        fontSize={className === 'vanModal' ? 'medium' : 'large'}
                                        className={`${className}__iconsContainer__circleIcon`}
                                    />
                                    <CheckIcon
                                        fontSize={className === 'vanModal' ? 'small' : 'medium'}
                                        className={`${className}__iconsContainer__checkIcon`}
                                    />
                                </div>
                                <h4 className={`${className}__features__item__title`}>{f}</h4>
                            </div>
                        ))}
                    </div>
                </div>
                <a id="check"></a>
            </div>
        </div>
    );
};
export default DetailsContainer;
