import axios from "axios";
import { put, takeLatest } from "redux-saga/effects";
import { SubLocationActionCreators } from "src/store/reducers/subLocation/action-creators";
import { SubLocationActionEnum } from "src/store/reducers/subLocation/types";
import instance from "../set";

function* fetchGetSubLocations() {
  try {
    const { data } = yield instance.get('subLocation');
    yield put(SubLocationActionCreators.resultGetSubLocations(data));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetSubLocations() {
  yield takeLatest(SubLocationActionEnum.REQUEST_SUBLOCATIONS, fetchGetSubLocations);
}
