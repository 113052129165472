import { FC } from "react";
const Color: FC = () => {
  const colors = [
    "#1C1C1C",
    "#FF7A7A",
    "#FFC672",
    "#84FFB5",
    "#70F6FF",
    "#6B7BFF",
  ];
  return (
    <div className="filter-color">
      <div className="filter__title">Color</div>
      <div className="filter-color__wrapper">
        {colors.map((color, i) => (
          <div
            key={i}
            className="filter-color__item"
            style={{ backgroundColor: color }}
          />
        ))}
      </div>
    </div>
  );
};
export default Color;
