import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Filters from '..';
import { setMainFilter } from 'src/common/utils/setFilters';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { useActions } from 'src/hooks/useActions';
import { getNames } from 'src/common/utils/others';
import './styles.scss';

interface IProps {
  marginTop: number;
  setMarginTop: (arg0: number) => void
}

const GeneralFilter: FC<IProps> = ({ marginTop, setMarginTop }) => {

  const { setStartDateFilter, setEndDateFilter } = useActions();
  const [params, setSearchParams] = useSearchParams();
  const anchorRef = useRef<HTMLDivElement>(null);
  const isLaptop = useMemo(() => window.outerWidth < 1261, [window.outerWidth]);

  const {
    van: { filters, checkFilter },
  } = useTypedSelector((state) => state);

  const handleSetFilter = () => {
    setMainFilter(params, filters);
    setSearchParams(params);
  };

  useEffect(() => {
    handleSetFilter();
  }, [filters]);

  useEffect(() => {
    const height = anchorRef!.current!.getBoundingClientRect().height;
    isLaptop && setMarginTop(height + 45);
  }, [anchorRef]);

  let prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    const currentScrollPos = window.pageYOffset;
    if (anchorRef.current) {
      if (prevScrollpos > currentScrollPos) {
        anchorRef.current!.style.top = "38px";
      } else {
        (prevScrollpos > 120) && (
          anchorRef.current!.style.top = `-${marginTop}px`
        );
      }
      prevScrollpos = currentScrollPos;
    }
  };

  return (
    <div ref={anchorRef} className='general-filter-container'>
      <div className='general-filter'>
        <div className='general-filter__locations'>
          <Filters.Location check={checkFilter} />
          <Filters.AnotherLocation check={checkFilter} />
        </div>
        <Filters.Times
          check={checkFilter}
          startDate={filters.start_date}
          endDate={filters.end_date}
          handleStartDate={setStartDateFilter}
          handleEndDate={setEndDateFilter}
        />
      </div>
    </div>

  );
};

export default GeneralFilter;
