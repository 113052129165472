import { FC } from 'react';
import { DateTime } from 'luxon';
import { AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IOrderForClient } from 'src/models/IOrder';

interface IProps {
  order: IOrderForClient,
  status: string
}

const AccordionInfo: FC<IProps> = ({ order, status }) => {

  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
    >
      <div className='client__order__accordion'>
        <h5># {order.id}</h5>
        <div className='client__order__accordion__container'>
          <div className={`client__order__header__badge__status ${order.status}`}>
            {status}
          </div>
          <div className='client__order__accordion__info'>
            <div>
              <div>{order.location}</div>
              <div>
                {DateTime.fromISO(order.start_date, { zone: 'UTC' })
                  .plus({ minutes: order.timeOffset }).toFormat('ff')}
              </div>
            </div>
            -
            <div>
              <div>{order.end_location || order.location}</div>
              <div>
                {DateTime.fromISO(order.end_date, { zone: 'UTC' })
                  .plus({ minutes: order.timeOffset }).toFormat('ff')}
              </div>
            </div>
          </div>
          <div className='client__order__accordion__note'>
            {
              order.note.length > 20
                ? `${order.note.slice(0, 20)}...`
                : order.note.slice(0, 20)
            }
          </div>
        </div>
      </div>
    </AccordionSummary>
  );
};

export default AccordionInfo;
