import { put, takeLatest } from 'redux-saga/effects';
import { VanActionEnum } from 'src/store/reducers/van/types';
import { VanActionCreators } from 'src/store/reducers/van/action-creators';
import instance from '../set';

function* fetchGetHostVans({
  host,
}: ReturnType<typeof VanActionCreators.getHostVans>) {
  try {
    const { data } = yield instance.get(`van/host/${host}`);
    yield put(VanActionCreators.resultGetHostVans(data));
  } catch (error) {
    console.log('error :>> ', error);
  }
}
export function* actionWatcherGetHostVans() {
  yield takeLatest(VanActionEnum.REQUEST_HOST_VANS, fetchGetHostVans);
}
