import { ISeasonPrice, ISeasonPriceFetch } from "src/models/ISeasonPrice";

export enum SeasonPriceActionEnum {
  REQUEST_SEASONPRICE = "REQUEST_SEASONPRICE",
  RESULT_SEASONPRICE = "RESULT_SEASONPRICE",
}

export interface SeasonPriceState {
    seasonPriceList: ISeasonPriceFetch[];
    isLoading: {
        all: boolean;
    };
}

export interface GetSeasonPriceAction {
  type: SeasonPriceActionEnum.REQUEST_SEASONPRICE;
}

export interface ResultGetSeasonPriceAction {
  type: SeasonPriceActionEnum.RESULT_SEASONPRICE;
  data: ISeasonPriceFetch[];
}

export type SeasonPriceAction =
  | GetSeasonPriceAction
  | ResultGetSeasonPriceAction;
