import { all, fork } from 'redux-saga/effects';
import { actionWatcherGetVans } from './van/getAll';
import { actionWatcherGetHostVans } from './van/getHostVans';
import { actionWatcherGetFeatures } from './feature/getAll';
import { actionWatcherAddClient } from './order/addClient';
import { actionWatcherSignContract } from './contract/sign';
import { actionWatcherGetContract } from './contract/get';
import { actionWatcherGetVan } from './van/get';
import { actionWatcherGetLocations } from './location/getAll';
import { actionWatcherGetExtras } from './extra/getAll';
import { actionWatcherSetDriverLicense } from './driverLicense/addImages';
import { actionWatcherGetOneway } from './oneway/getAll';
import { actionWatcherAddDriver } from './driver/addDriver';
import { actionWatcherCheckVan } from './van/check';
import { actionWatcherCancelOrder } from './order/cancel';
import { actionWatcherGetSeasonPrice } from './seasonPrice/getAll';
import { actionWatcherGetSubPrice } from './subPrice/getAll';
import { actionWatcherGetSubLocations } from './subLocation/getAll';
import { actionWatcherGetClient } from './client/get';
import { actionWatcherExit } from './client/logout';
import { actionWatcherSignup } from './client/signup';
import { actionWatcherPayNow } from './client/pay';
import { actionWatcherUpdateOrder } from './client/update-order';
import { actionWatcherUpdateClient } from './client/update';
import { actionWatcherCheckToken } from './client/check';
import { actionWatcherShowContract } from './client/docs/contracts';
import { actionWatcherShowLicense } from './client/docs/license';
import { actionWatcherShowInsurance } from './client/docs/insurance';
import { actionWatcherQuoteOrder } from './order/quoteOrder';
import { actionWatcherCheckQuoteOrder } from './order/checkOrder';
import { actionWatcherSendReceipt } from './client/receipt';
import { actionWatcherChangeOrderDates } from './client/change-dates';

export default function* rootSaga() {
  yield all([
    fork(actionWatcherGetVans),
    fork(actionWatcherGetHostVans),
    fork(actionWatcherGetFeatures),
    fork(actionWatcherGetExtras),
    fork(actionWatcherGetVan),
    fork(actionWatcherCheckVan),
    fork(actionWatcherAddClient),
    fork(actionWatcherGetContract),
    fork(actionWatcherGetLocations),
    fork(actionWatcherSignContract),
    fork(actionWatcherSetDriverLicense),
    fork(actionWatcherGetOneway),
    fork(actionWatcherAddDriver),
    fork(actionWatcherCancelOrder),
    fork(actionWatcherQuoteOrder),
    fork(actionWatcherGetSeasonPrice),
    fork(actionWatcherGetSubPrice),
    fork(actionWatcherGetSubLocations),
    fork(actionWatcherGetClient),
    fork(actionWatcherExit),
    fork(actionWatcherSignup),
    fork(actionWatcherPayNow),
    fork(actionWatcherUpdateOrder),
    fork(actionWatcherUpdateClient),
    fork(actionWatcherCheckToken),
    fork(actionWatcherShowContract),
    fork(actionWatcherShowLicense),
    fork(actionWatcherShowInsurance),
    fork(actionWatcherCheckQuoteOrder),
    fork(actionWatcherSendReceipt),
    fork(actionWatcherChangeOrderDates)
  ]);
}
