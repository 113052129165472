import { put, takeLatest } from 'redux-saga/effects';
import instanceWithToken from "../setWithToken";
import { ClientActionEnum } from "src/store/reducers/client/types";
import { ClientActionCreators } from "src/store/reducers/client/action-creators";

function* fetchUpdateClient({
  currentClient,
}: ReturnType<typeof ClientActionCreators.updateClient>) {
  const { id, ...client } = currentClient;
  try {
    const { status }: Response = yield instanceWithToken.patch(`personal/customer/${id}`, {
      client
    });
    yield put(ClientActionCreators.resultUpdateClient(status));
  } catch (e) {
    console.log(e);
    yield put(ClientActionCreators.resultUpdateClient(400));
  }
}

export function* actionWatcherUpdateClient() {
  yield takeLatest(ClientActionEnum.REQUEST_UPDATE_CLIENT, fetchUpdateClient);
}
