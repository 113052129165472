import { generalKeysFilters, IVanFilter } from "src/models/IVanFilter";
import { ErrorVanFilterAction } from "src/store/reducers/van/types";
import { validateIs } from "./validation";

const isExistPartOfMainFilter = (filters: IVanFilter) =>
  filters.start_date !== null ||
  filters.end_date !== null ||
  !!filters.location;

const validateFilter = (
  filters: generalKeysFilters,
  errorFilter: (errors: any, isFilterValidate: boolean) => ErrorVanFilterAction
) => {
  if (isExistPartOfMainFilter(filters)) {
    const { location, start_date, end_date } = filters;
    const locationErr = validateIs("location", location as any);
    const startDateErr = validateIs("date from", start_date);
    const endDateErr = validateIs("date to", end_date);
    errorFilter(
      {
        location: locationErr,
        start_date: startDateErr,
        end_date: endDateErr,
      },
      [locationErr, startDateErr, endDateErr].every((el) => !el)
    );
  }
};

export { validateFilter };
