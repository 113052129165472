import { ExtraAction, ExtraActionEnum, ExtraState } from "./types";

const initialState: ExtraState = {
  extraList: [],
  isLoading: {
    all: false,
  },
};

const extra = (state = initialState, action: ExtraAction): ExtraState => {
  switch (action.type) {
    case ExtraActionEnum.REQUEST_EXTRAS:
      return { ...state, isLoading: { ...state.isLoading, all: true } };
    case ExtraActionEnum.RESULT_EXTRAS:
      return {
        ...state,
        extraList: action.data,
        isLoading: { ...state.isLoading, all: !action.data },
      };
    default:
      return state;
  }
};
export default extra;
