import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import instanceWithToken from "../setWithToken";
import { OrderActionEnum } from "src/store/reducers/order/types";
import { OrderActionCreators } from "src/store/reducers/order/action-creators";

function* fetchCheckQuoteOrder({
  id,
}: ReturnType<typeof OrderActionCreators.checkQuoteOrder>) {
  try {
    const { status, data } =
      typeof id === 'string'
        ? yield instance.get(`booking/payLead/${id}`)
        : yield instanceWithToken.get(`personal/lead/down-pay/${id}`);
    yield put(OrderActionCreators.resultCheckQuoteOrder(status, data));
  } catch (error: any) {
    console.log("error :>> ", error);
    yield put(OrderActionCreators.resultCheckQuoteOrder(400));
  }
}
export function* actionWatcherCheckQuoteOrder() {
  yield takeLatest(OrderActionEnum.REQUEST_CHECK_QUOTE, fetchCheckQuoteOrder);
}
