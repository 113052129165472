import { FC } from "react";
import Rating from "@mui/material/Rating";
import { Checkbox } from "@mui/material";

const HostRating: FC = () => {
  const stars = [5, 4, 3, 2, 1];
  return (
    <div className="filter-hostRating">
      <div className="filter__title">HostRating</div>
      <div className="filter-hostRating__wrapper">
        {stars.map((star, i) => (
          <div className="filter-hostRating__box" key={i}>
            <Checkbox defaultChecked />
            <Rating value={star} readOnly />
          </div>
        ))}
      </div>
    </div>
  );
};
export default HostRating;
