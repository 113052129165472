import { OnewayState, OnewayAction, OnewayActionEnum } from './types';

const initialState: OnewayState = {
  onewayList: [],
  isLoading: {
    all: false,
  },
};

const oneway = (state = initialState, action: OnewayAction): OnewayState => {
  switch (action.type) {
    case OnewayActionEnum.REQUEST_ONEWAY:
      return { ...state, isLoading: { ...state.isLoading, all: true } };

    case OnewayActionEnum.RESULT_ONEWAY:
      return {
        ...state,
        onewayList: action.data,
        isLoading: { ...state.isLoading, all: !action.data },
      };
    default:
      return state;
  }
};
export default oneway;
