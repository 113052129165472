import { FC, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";
import { CircularProgress } from '@mui/material';
import './styles.scss';

Geocode.setApiKey("AIzaSyDMyAS2jdzj-vdgBIFaIStYOWJtSlghndg");
Geocode.setLanguage("en");

interface IProps {
  address: string
}

const Marker = (props: any) => {
  return (
      <div className="marker"
        style={{ background: `url('/images/map-marker.png') no-repeat`, cursor: 'pointer' }}
        title={''}
      />
  );
};

const SimpleMap: FC<IProps> = ({ address }) => {
  
  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    Geocode.fromAddress(address).then(
      (response: any) => {
        const { lat, lng } = response.results[0].geometry.location;
        setCenter({ lat, lng });
      },
      (error: any) => {
        console.error(error);
      }
    );
  }, [address]);

  return (
    <div style={{ height: '50vh', width: '100%' }}>
      {(center.lat === 0 && center.lng === 0) ? (
        <CircularProgress size={100} />
      ) : (
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDMyAS2jdzj-vdgBIFaIStYOWJtSlghndg" }}
          defaultCenter={center}
          defaultZoom={10}
        >
          <Marker
            lat={center.lat}
            lng={center.lng}
          />
        </GoogleMapReact>
      )}
    </div>
  );
};

export default SimpleMap;