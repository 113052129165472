import { IDriverLicense } from 'src/models/IDriverLicense';
import {
  AddDriverLicenseImagesAction,
  DriverLicenseActionEnum,
  ErrorSetDriverLicenseAction,
  ResultAddDriverLicenseImagesAction,
  ResultSetDriverLicenseAction,
  SetDriverLicenseAction,
} from './types';

export const DriverLicenseActionCreators = {
  addDriverLicenseImage: (
    license: IDriverLicense,
    images: File[],
    insurance: File
  ): AddDriverLicenseImagesAction => ({
    type: DriverLicenseActionEnum.ADD_DRIVER_LICENSE_IMAGES,
    license,
    images,
    insurance,
  }),
  resultAddDriverLicenseImage: (
    license: IDriverLicense,
    statuses: string[]
  ): ResultAddDriverLicenseImagesAction => ({
    type: DriverLicenseActionEnum.RESULT_ADD_DRIVER_LICENSE_IMAGES,
    license,
    statuses,
  }),

  resultSetDriverLicense: (
    status: string | number
  ): ResultSetDriverLicenseAction => ({
    type: DriverLicenseActionEnum.RESULT_SET_DRIVER_LICENSE,
    status,
  }),
  errorSetDriverLicense: (statusText: string): ErrorSetDriverLicenseAction => ({
    type: DriverLicenseActionEnum.ERROR_SET_DRIVER_LICENSE,
    statusText,
  }),
};
