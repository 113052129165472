import { put, takeLatest } from "redux-saga/effects";
import instanceWithToken from "../setWithToken";
import { ClientActionEnum } from "src/store/reducers/client/types";
import { ClientActionCreators } from "src/store/reducers/client/action-creators";

function* fetchPayNow({ id }: ReturnType<typeof ClientActionCreators.payNow>) {
  try {
    const { data, status } = yield instanceWithToken.get(`/personal/pay/${id}`);
    yield put(ClientActionCreators.resultPayNow(data, status));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherPayNow() {
  yield takeLatest(ClientActionEnum.REQUEST_CLIENT_PAY, fetchPayNow);
}
