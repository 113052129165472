import { GlobalActionEnum, GlobalState } from './types';

const initialState: GlobalState = { header: false };

const global = (state = initialState, action: any): GlobalState => {
  switch (action.type) {
    case GlobalActionEnum.ERROR:
      return {
        ...state,
        error: { result: action.result, status: action.status },
      };
    case GlobalActionEnum.HIDE:
      return {
        ...state,
        header: false,
      };
    case GlobalActionEnum.SHOW:
      return {
        ...state,
        header: true,
      };
    default:
      return state;
  }
};

export default global;
