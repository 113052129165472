import { IExtra } from "src/models/IOrder";

export enum ExtraActionEnum {
  REQUEST_EXTRAS = "REQUEST_EXTRAS",
  RESULT_EXTRAS = "RESULT_EXTRAS",
  ERROR_EXTRAS = "ERROR_EXTRAS",
}

export interface ExtraState {
  extraList: IExtra[];
  total?: number;
  isLoading: {
    all: boolean;
  };
}

export interface RequestExtraAction {
  type: ExtraActionEnum.REQUEST_EXTRAS;
  payload?: any;
}

export interface ResultExtraAction {
  type: ExtraActionEnum.RESULT_EXTRAS;
  data: IExtra[];
}

export interface ErrorExtraAction {
  type: ExtraActionEnum.ERROR_EXTRAS;
  payload?: any;
}

export type ExtraAction =
  | RequestExtraAction
  | ResultExtraAction
  | ErrorExtraAction;
