import { FC } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
const Reviews: FC = () => {
  const links = [
    {
      img: (
        <img
          src={require('src/common/img/photo_2022-11-28_16-01-10.jpg')}
          height='50px'
          width='50px'
          alt='test'
        />
      ),
      link: 'https://www.google.com/maps/place/Sprinter+Rentals/@32.709904,-117.0837747,17z/data=!3m1!4b1!4m5!3m4!1s0x80d9514303fb1ce5:0x7bb96fbe5be6366!8m2!3d32.709904!4d-117.081586?shorturl=1',
      title: 'San Diego',
    },
    {
      img: (
        <img
          src={require('src/common/img/LA.jpg')}
          height='50px'
          width='50px'
          alt='test'
        />
      ),
      link: 'https://www.google.com/maps/place/Sprinter+Rentals/@33.9484485,-118.3964315,11z/data=!4m5!3m4!1s0x0:0xc6fb517df170d3ac!8m2!3d33.9483441!4d-118.3964396?shorturl=1&shorturl=1',
      title: 'Los Angeles',
    },
    {
      img: (
        <img
          src={require('src/common/img/SF.jpg')}
          height='50px'
          width='50px'
          alt='test'
        />
      ),
      link: 'https://www.google.com/maps/place/Sprinter+Rentals+San+Francisco/@37.6149317,-122.4086985,11z/data=!4m5!3m4!1s0x808f8694a91168b3:0xbd30e95df47dbc8!8m2!3d37.7703641!4d-122.2373232?shorturl=1',
      title: 'San Francisco',
    },
    {
      img: (
        <img
          src={require('src/common/img/arizona.jpg')}
          height='50px'
          width='50px'
          alt='test'
        />
      ),
      link: 'https://www.google.com/maps/place/Sprinter+Rentals+Phoenix/@33.4364728,-112.0401577,13z/data=!4m5!3m4!1s0x872b0ee74ba72c39:0x336b042684b3b293!8m2!3d33.4248791!4d-111.9763705?shorturl=1',
      title: 'Arizona',
    },
  ];
  return (
    <div className='filter-reviews'>
      {/* <div className='filter__title'>Reviews</div>
      <div className='filter__review-list'>
        {links.map((item, i) => (
          <a key={i} href={item.link} target='_blank'>
            <FormControlLabel control={item.img} label={item.title} />
          </a>
        ))}
      </div> */}
      <img
        src={require('src/common/img/reviews.jpg')}
        width='230px'
        alt='reviews'
      />
    </div>
  );
};
export default Reviews;
