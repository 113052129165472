import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { VanActionEnum } from "src/store/reducers/van/types";
import { VanActionCreators } from "src/store/reducers/van/action-creators";

function* fetchCheckVan({ id, period }: ReturnType<typeof VanActionCreators.checkVan>) {
  try {
    const { data, status } = yield instance.post(`van/check/${id}`, {
        period
    });
    yield put(VanActionCreators.resultCheckVan(data, status));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherCheckVan() {
  yield takeLatest(VanActionEnum.REQUEST_CHECK_VAN, fetchCheckVan);
}
