import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { SnackbarKey, useSnackbar } from 'notistack';
import { Button, CircularProgress } from '@mui/material';
import DriverLicenseForm from './Form';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { validateDate, validateField } from 'src/common/utils/validation';
import { IDriverLicense } from 'src/models/IDriverLicense';
import Icons from 'src/common/icons';
import './styles.scss';


export interface IErrorsLicense {
  [key: string]: string;
}

const DriverLicensePage: FC = () => {
  const navigate = useNavigate();
  const { addDriverLicenseImage } = useActions();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { isLogin } = useTypedSelector((state) => state.client);

  const { result, error, isLoading } = useTypedSelector((state) => state.driverLicense);
  const { id } = useParams();
  const [isExist, setIsExist] = useState(true);
  const [license, setLicense] = useState<IDriverLicense>({
    id: id!,
    address: '',
    number: '',
    issued: '',
  });

  const getLicenseStatus = async () => {
    try {
      const { status } = await axios.get(
        `${process.env.REACT_APP_API}/client/license/${id}`
      );
      status === 200
        ? (setIsExist(true),
          isLogin ? navigate('/client') : navigate('/'),
          enqueueSnackbar(
            `You are all good. You have already added your driver's license and insurance.`,
            {
              variant: 'info',
              persist: true
            }
          ),
          setTimeout(() => closeSnackbar(), 10000))
        : setIsExist(false);
    } catch (error) {
      setIsExist(false);
    }
  };

  const handleInput = (name: string) => (value: string | number) =>
    setLicense({ ...license, [name]: value });

  const [images, setImages] = useState<File[]>([]);
  const [deletedImages, setDeletedImages] = useState<string[]>([]);
  const [insurance, setInsurance] = useState<File>();

  const [check, setCheck] = useState(false);
  const [errors, setErrors] = useState<IErrorsLicense>({});

  const validateInputs = (): boolean => {
    const address = validateField(license.address);
    const number = validateField(license.number);
    const issued = validateField(license.issued);
    const photos =
      images.length < 2
        ? "Upload front and back of your driver's license."
        : '';
    const birthday = validateDate(license.birthday);
    const expiration_date = validateDate(license.expiration_date);
    const isInsurance = insurance ? '' : 'Upload your insurance';
    setErrors({
      address,
      number,
      issued,
      photos,
      birthday,
      expiration_date,
      isInsurance,
    });
    return [
      address,
      number,
      photos,
      issued,
      birthday,
      expiration_date,
      isInsurance,
    ].every((el) => !el);
  };

  useEffect(() => {
    getLicenseStatus();
    return () => setImages([]);
  }, []);

  useEffect(() => {
    if (result && !error) {
      enqueueSnackbar('Your info is saved', {
        variant: 'success',
      });
      isLogin
        ? navigate('/client')
        : navigate('/success/upload');
    }
  }, [result]);

  useMemo(() => {
    check && validateInputs();
  }, [images, license]);

  const handleSubmit = () => {
    setCheck(true);

    console.log(
      '🚀 ~ file: index.tsx:91 ~ handleSubmit ~ validateInputs:',
      validateInputs()
    );
    if (validateInputs()) {
      enqueueSnackbar('Just a sec,it`s uploading. Please don`t close the page', {
        variant: 'info',
        persist: true,
        key: "uploading" as SnackbarKey
      });
      addDriverLicenseImage(license, images, insurance!);
    }
  };

  useEffect(() => {
    !isLoading.set && closeSnackbar("uploading");
  }, [isLoading.set]);

  return (
    <>
      {isExist ? (
        <CircularProgress
          size={100}
          sx={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      ) : (
        <div className='driver-license'>
          <div className='driver-license__wrapper'>
            <div className='driver-license__title'>
              Please submit your details below.
            </div>
            <DriverLicenseForm
              license={license}
              setLicense={handleInput}
              images={images}
              setImages={setImages}
              insurance={insurance}
              setInsurance={setInsurance}
              deletedImages={deletedImages}
              setDeletedImages={setDeletedImages}
              check={check}
              errors={errors}
            />
            <Button
              sx={{ margin: '10px auto 0' }}
              variant='contained'
              onClick={handleSubmit}
              disabled={isLoading.set}
            >
              {isLoading.set ? (
                <>
                  <CircularProgress size={16} sx={{ mr: 1.3 }} />
                  Uploading
                </>
              ) : ('Submit Details')}
            </Button>
            <div className='terms'>
              <Icons.Check />I agree to sprinter-rentals terms & conditions.
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DriverLicensePage;
