import { FC, useState, useEffect } from 'react';
import './styles.scss';
import { FileUploader } from 'react-drag-drop-files';
import Icons from 'src/common/icons';
import { FormHelperText } from '@mui/material';

interface IProps {
  previousFiles?: string[];
  onChangeField: any;
  handleDeleteFile: any;
  nameUploader: string;
  limitImages?: number;
  error?: string;
  title?: string;
  subtitle?: string;
  label?: string;
}
const fileTypes = ['SVG', 'JPG', 'JPEG', 'GIF', 'PNG', 'PDF'];

const MultiplyDragAndDropUploadFiles: FC<IProps> = (props) => {
  const { nameUploader, onChangeField, previousFiles, handleDeleteFile } =
    props;
  const [gallery, setGallery] = useState<string[]>([]);

  useEffect(() => {
    setGallery([]);
    if (previousFiles) {
      setGallery([...previousFiles]);
    }
  }, [previousFiles]);

  const uploadFileS = (files: File[]) => {
    const newGallery = [...gallery];
    let limitFiles: File[];
    if (props.limitImages) {
      limitFiles = Array.from([...files]).slice(
        0,
        props.limitImages - newGallery.length
      );
    } else limitFiles = [...files];
    [...limitFiles].forEach((x) => {
      newGallery.push(URL.createObjectURL(x));
    });
    onChangeField(limitFiles) && setGallery(newGallery);
  };

  const deleteFile = (url: string, index: number) => {
    setGallery([...gallery.slice(0, index), ...gallery.slice(index + 1)]);
    handleDeleteFile(url, index);
  };

  const attachementCards = () =>
    gallery.map((url, index: number) => (
      <div className='multi-upload__card-preview' key={index}>
        <img src={url} alt='van' />
        <span
          className='multi-upload__btn_delete'
          onClick={() => deleteFile(url, index)}
        >
          <Icons.Close classes='multi-upload__icon' />
        </span>
      </div>
    ));

  const _uploader = () => (
    <FileUploader
      handleChange={uploadFileS}
      name={nameUploader}
      types={fileTypes}
      multiple={true}
      maxSize={51e6}
      classes={!!gallery.length && 'short'}
      children={
        <div className='uploader__wrapper'>
          <Icons.Folder />
          <div className='uploader__btn'>
            <div className='uploader__btn__label'>
              {props.label ?? 'Browse image'}
            </div>
          </div>
        </div>
      }
    />
  );

  return (
    <>
      {props.title && <div className='multi-upload__title'>{props.title}</div>}
      {props.subtitle && <div className='multi-upload__subtitle'>{props.subtitle}</div>}
      <div className={`multi-upload__wrapper ${props.error ? 'error' : ''}`}>
        {gallery.length ? (
          <div
            className={`attachments__wrapper col-${
              gallery.length === 1 ? '1' : '2'
            }`}
          >
            {attachementCards()}
          </div>
        ) : null}
        {props.limitImages
          ? gallery.length >= props.limitImages
            ? null
            : _uploader()
          : _uploader()}
        {props.error && (
          <FormHelperText children={props.error} sx={{ left: 15 }} />
        )}
      </div>
    </>
  );
};

export default MultiplyDragAndDropUploadFiles;
