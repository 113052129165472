import { FC, useEffect, useMemo, useState } from 'react';
import { validateField, validatePhone } from 'src/common/utils/validation';
import Cart from '../Cart';
import { IPropsOrder } from '..';
import ContactData from '../ContactData';
import { useActions } from 'src/hooks/useActions';
import { IClient } from 'src/models/IOrder';
import { scrollToTop } from 'src/common/utils/others';

export type IErrorsClientData = {
  [key in keyof IClient | 'delivery_address']?: string;
};

const Step2: FC<IPropsOrder> = ({ order, onChange, setStep }) => {
  const { addClient } = useActions();
  const { client, extras } = order;
  const [errors, setErrors] = useState<IErrorsClientData>({});

  const [check, setCheck] = useState(false);

  const isDeliveryExtra = useMemo(
    () => extras.some(({ name }) => name.includes('Deliver to/from you')),
    [extras]
  );

  useEffect(() => {
    !isDeliveryExtra && onChange('delivery_address', '');
    !isDeliveryExtra && onChange('return_at', '');
  }, [isDeliveryExtra]);

  const validateInputs = (): boolean => {
    const first_name = validateField(client.first_name);
    const last_name = validateField(client.last_name);
    const email = validateField(client.email);
    const phone = validatePhone(client.phone);
    const delivery_address = isDeliveryExtra
      ? order?.delivery_address?.length
        ? ''
        : 'You selected option `Delivery to you`'
      : '';

    setErrors({
      first_name,
      last_name,
      email,
      phone,
      delivery_address
    });
    return [first_name, last_name, email, phone, delivery_address].every(
      (el) => !el
    );
  };

  const handleNext = () => {
    setCheck(true);
    if (validateInputs()) {
      addClient({
        ...order.client,
        email: order.client.email.toLowerCase().trim(),
        first_name: order.client.first_name.trim(),
        last_name: order.client.last_name.trim()
      });
      setStep(2);
    } else {
      scrollToTop();
    }
  };

  useMemo(() => {
    check && validateInputs();
  }, [order.client, order.delivery_address]);

  const handleBack = () => {
    setStep(0);
  };

  return (
    <>
      <ContactData
        order={order}
        onChange={onChange}
        errors={errors}
        check={check}
        isDelivery={isDeliveryExtra}
      />
      <Cart
        order={order}
        titleButton='Book Refundable (or get quote)'
        handleNextStep={handleNext}
        handleBackStep={handleBack}
        onChange={onChange}
      />
    </>
  );
};

export default Step2;
