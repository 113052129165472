import { IVanFilter } from 'src/models/IVanFilter';
import transformValueToURL from '../transformValueToURL';

const handleChangeFilter = (
  params: URLSearchParams,
  name: string,
  data: string | string[] | number | 'delete'
) => {
  data === 'delete'
    ? params.delete(name)
    : params.has(name)
      ? params.set(name, String(data))
      : params.append(name, String(data));
};

const setAnotherFilter = (params: URLSearchParams, filters: IVanFilter) => {
  handleChangeFilter(params, 'page', filters.page);
  handleChangeFilter(params, 'take', filters.take);
  filters.features &&
    handleChangeFilter(
      params,
      'features',
      transformValueToURL('features', filters) as string
    );
  filters.price &&
    handleChangeFilter(params, 'price', transformValueToURL('price', filters)!);
  filters.seats && handleChangeFilter(params, 'seats', filters.seats);
  filters.type && handleChangeFilter(params, 'type', filters.type);
};
const setMainFilter = (params: URLSearchParams, filters: IVanFilter) => {
  filters.location && handleChangeFilter(params, 'location', filters.location);
  filters.sub_location && handleChangeFilter(params, 'sub_location', filters.sub_location);
  filters.sub_location === '' &&
    handleChangeFilter(params, 'sub_location', 'delete');
  filters.end_location &&
    handleChangeFilter(params, 'end_location', filters.end_location);
  filters.end_location === '' &&
    handleChangeFilter(params, 'end_location', 'delete');
  filters.start_date &&
    handleChangeFilter(
      params,
      'start_date',
      transformValueToURL('start_date', filters) as number
    );
  filters.end_date &&
    handleChangeFilter(
      params,
      'end_date',
      transformValueToURL('end_date', filters) as number
    );
};

const setFullFilter = (params: URLSearchParams, filters: IVanFilter) => {
  setAnotherFilter(params, filters);
  setMainFilter(params, filters);
};

export { setAnotherFilter, setFullFilter, setMainFilter, handleChangeFilter };
