import axios from "axios";
import { put, takeLatest } from "redux-saga/effects";
import { ClientActionEnum } from "src/store/reducers/client/types";
import { ClientActionCreators } from "src/store/reducers/client/action-creators";

function* fetchCheckToken(
  { token }: ReturnType<typeof ClientActionCreators.checkToken>
) {
  try {
    const { headers, status } = yield axios.get(
      `${process.env.REACT_APP_API}/auth/customer/login/callback?token=${token}`
    );
    if (headers.authorization) {
      localStorage.setItem('guest-token', headers.authorization);
    }
    yield put(ClientActionCreators.ResultCheckToken(status));
  } catch (error) {
    console.log("error :>> ", error);
    yield put(ClientActionCreators.ResultCheckToken(400));
  }
}
export function* actionWatcherCheckToken() {
  yield takeLatest(ClientActionEnum.CHECK_TOKEN, fetchCheckToken);
}
