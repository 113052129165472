import { FC } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepperProps,
} from "@mui/material";
import "./media.scss";
import React from "react";

interface IProps extends StepperProps {
  steps: string[];
  activeStep: number;
}

const StepperComponent: FC<IProps> = (props) => (
    <Stepper activeStep={props.activeStep} alternativeLabel>
      {props.steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: {
          optional?: React.ReactNode;
        } = {};
        return (
          <Step key={label} {...stepProps}
            sx={{
              "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
                border: '1.5px solid #081B3D'
              },
              "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
                border: '1.5px solid #081B3D'
              },
              "& .MuiStepConnector-root": {
                "@media (max-width: 450px)": {
                  top: "17px"
                },
              },
              '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                "@media (max-width: 450px)": {
                  display: "none",
                },
              }
            }}
          >
            <StepLabel {...labelProps} style={{ flexDirection: 'column-reverse', gap: '5px' }}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
);

export default StepperComponent;
