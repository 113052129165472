import { createTheme } from "@mui/material/styles";
const colorSet = {
  primaryGreen: "#c1ff45",
  primaryRed: "#d24057",
  primaryBiolet: "#c04fff",
  primaryBlue: "#151c97",
  secondaryBlue: '#081B3D',
  primaryBlack: "#4b566b",
  primaryDarkBlack: "#091b3d",
  primaryLightBlack: "#7d879c",
  primaryWhite: "#ffffff",
  primaryGrey: "#ccc",
  primaryLightGrey: "rgba(204, 204, 204, 0.15)",
  secondaryGrey: "#fafbfe",
  secondaryGreen: "#01c38d",
  opacityPrimaryRed: "#d2405621",
  opacitySecondaryGreen: "#01c38c18",
  primaryShadow: `(
    0 0 0 0.5px rgba(50, 50, 93, 0.1),
    0 2px 5px 0 rgba(50, 50, 93, 0.1),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.07)
  )`,
};

const typeForm = "standard";
const multiSelectItemStyle = {
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: 0,
  margin: "3px 4px",
  height: "33px",
  backgroundColor: colorSet.primaryWhite,
  border: `1px solid ${colorSet.primaryGrey} !important`,
  borderRadius: `3px`,
};

const mainColor = colorSet.primaryBlack;
const secondColor = colorSet.primaryGreen;

export { colorSet, mainColor, secondColor, multiSelectItemStyle, typeForm };

const theme = createTheme({
  palette: {
    primary: {
      main: mainColor,
    },
    secondary: {
      main: secondColor,
    },
  },
  typography: {
    fontFamily: "inherit",
    fontSize: 14,
    h1: {
      fontWeight: 800,
      fontSize: "24px",
      marginBottom: "10px",
      display: "inline-block",
      "@media (max-width: 1200px)": {
        fontSize: "20px",
      },
    },
    h2: {
      fontWeight: 600,
      fontSize: "16px",
      marginBottom: "10px",
      "@media (max-width: 1200px)": {
        fontSize: "12px",
      },
    },
    h3: {
      textTransform: "uppercase",
      fontWeight: 600,
      fontSize: "16px",
      color: "#535E6C",
      marginBottom: "20px",
      "@media (max-width: 1200px)": {
        fontSize: "14px",
        marginBottom: "14px",
      },
    },
    h4: {
      fontWeight: 600,
      fontSize: "16px",
      color: "#9EA9BA",
      marginBottom: "15px",
      "@media (max-width: 1200px)": {
        fontSize: "14px",
        marginBottom: "14px",
      },
    },
    subtitle1: {
      color: "#535E6C",
      wordWrap: "break-word",
      "@media (max-width: 1200px)": {
        fontSize: "12px",
      },
    },
    subtitle2: {
      color: "#AFB5BF",
      lineHeight: "155%",
      wordWrap: "break-word",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          color: "#4b566b",
          fontWeight: 500,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeight: "40px",
          fontFamily: "inherit",
          input: {
            padding: "5px 10px",
            transform: "translateY(2px)",
          },
          ["& .${outlinedInputClasses.notchedOutline}"]: {
            border: "1px solid #E3E3EA",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: "8px 3px",
          transform: "translateY(2px)",
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          overflowY: "scroll",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          top: 'auto',
          bottom: '10px',
          left: 'auto',
          right: '10px',
          '& .MuiPaper-root': {
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '100%',
              maxWidth: '350px',
              margin: '10px 0'
            }
          },
          '@media (max-width: 500px)': {
            left: '20px',
            right: '20px',
          },
        }
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: "#535E6C",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 3,
          "@media (max-width: 991px)": {
            padding: `0 6px`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "12px 24px",
          background: secondColor,
          borderRadius: "4px",
          textTransform: "none",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "16px",
          color: mainColor,
          border: '1px solid #4B566B',
          boxShadow: 'none !important',
          ".MuiSvgIcon-root ": { fill: mainColor },
          ":active": { backgroundColor: secondColor },
          ":focus": {
            backgroundColor: secondColor,
          },
          ":hover": {
            backgroundColor: secondColor,
          },
          "@media (max-width: 991px)": {
            padding: "10px 25px",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: colorSet.primaryRed,
          position: "absolute",
          right: 0,
          bottom: 8,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          height: "40px",
          maxWidth: "345px",
          width: "100%",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: "-3px !important",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        actions: {
          Button: {
            display: "inline-flex",
            background: "transparent",
            justifyContent: "center",
            alignItems: "center",
            padding: "8px",
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "0",
          ":last-child": {
            padding: "0",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          boxSizing: "border-box",
          borderRadius: "3px",
          padding: 0,
          paddingTop: 1,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
          color: mainColor,
          fontSize: "14px",
          borderBottom: "1px solid #E3E3EA",
          padding: "8px 10px",
          ":last-child": {
            borderBottom: "none",
          },
          ":first-of-type": {
            borderTop: "none",
          },
          "@media (max-width: 991px)": {
            minHeight: "unset",
            height: "37px",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "3px",
          boxSizing: "border-box",
          border: "1px solid #E3E3EA",
          background: "white",
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          ".MuiButtonBase-root": {
            marginRight: 0,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&::before": {
            borderBottom: `1px solid ${colorSet.primaryGrey} !important`,
          },
        },
        input: {
          fontWeight: 500,
          fontSize: "14px",
          padding: "9.5px 3px",
          color: mainColor,
          minHeight: "inherit",
          transform: "translateY(2px)",
          "&:focus": {
            backgroundColor: "unset",
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
            fontWeight: 400,
            fontSize: '14px',
            marginTop: 0,
            '&.Mui-active': {
              fontWeight: 700
            },
          }
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          top: '37px',
          left: 'calc(-50% + 12px)',
          right: 'calc(50% + 12px)',
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          border: '1.5px solid #CCCCCC',
          borderRadius: '50%',
          '&.Mui-active': {
            color: '#C2FF45 !important',
            border: `1.5px solid ${colorSet.secondaryBlue}`,
            borderRadius: '50%',
            backgroundColor: `${colorSet.secondaryBlue}`,
            '& text': {
              fill: `${colorSet.secondaryBlue}`,
              fontWeight: 700,
            },
          },
          '&.Mui-completed': {
            color: '#C2FF45 !important',
            border: `1.5px solid ${colorSet.secondaryBlue}`,
            borderRadius: '50%',
            backgroundColor: `${colorSet.secondaryBlue}`,
          }
        },
        text: {
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '26px',
          textAlign: 'center',
          fill: '#8891A4',
        }
      }
    }
  }
});

export default theme;
