import { FC, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Van from 'src/pages/Van';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import useIsMobile from 'src/hooks/useIsMobile';
import useCloseMenu from 'src/hooks/useCloseMenu';
import { initFilter } from 'src/store/reducers/van';
import transformValueFromURL from 'src/common/utils/transformValueFromURL';
import { validateFilter } from 'src/common/utils/validateFilter';
import { IVanFilter } from 'src/models/IVanFilter';
import './styles.scss';

interface IProps { }

const MainPage: FC<IProps> = () => {
  const isMobile = useIsMobile();

  const { search } = useLocation();
  const {
    setupFilters,
    getFeatures,
    errorFilter,
    getHostVans,
    getLocations,
    getSubLocations,
    getOneway
  } = useActions();
  const [params] = useSearchParams();
  const { pathname } = useLocation();
  const { filters } = useTypedSelector((state) => state.van);
  const [closeBar] = useCloseMenu();
  const [marginTop, setMarginTop] = useState(25);

  useEffect(() => {
    if (search) {
      const setupFilterRedux: any = {};
      for (const [key, value] of params.entries()) {
        setupFilterRedux[key] = transformValueFromURL(
          key as keyof IVanFilter,
          value
        );
      }
      setupFilters({ ...initFilter, ...setupFilterRedux, region: filters.region });
    } else if (pathname.indexOf('/host/') !== -1) {
      const host = pathname.split('/host/')[1];
      getHostVans(host.toUpperCase());
    } else setupFilters({ ...initFilter });
  }, [search]);

  useEffect(() => {
    validateFilter(filters, errorFilter);
  }, [filters]);

  useEffect(() => {
    getFeatures();
    getLocations();
    getSubLocations();
    getOneway();
  }, []);
  return (
    <>
      <div className='header'>
        <Header marginTop={marginTop} setMarginTop={setMarginTop} />
      </div>
      <div className='main' style={{ marginTop: `${marginTop}px` }}>
        <Van />
      </div>
      <div className='footer'>
        <Footer />
      </div>
    </>
  );
};

export default MainPage;
