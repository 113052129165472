import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { ContractActionEnum } from "src/store/reducers/contract/types";
import { ContractActionCreators } from "src/store/reducers/contract/action-creators";

function* fetchGetContract({
  id,
}: ReturnType<typeof ContractActionCreators.getContract>) {
  try {
    const { data } = yield instance.get(`contract/${id}`);
    yield put(ContractActionCreators.resultGetContract(data));
  } catch (error: any) {
    console.log("error :>> ", error);
    yield put(ContractActionCreators.errorSignContract(error.code));
  }
}
export function* actionWatcherGetContract() {
  yield takeLatest(ContractActionEnum.REQUEST_CONTRACT, fetchGetContract);
}
