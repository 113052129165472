import { IExtra } from "src/models/IOrder";
import {
  ExtraActionEnum,
  RequestExtraAction,
  ResultExtraAction,
} from "./types";

export const ExtraActionCreators = {
  getExtras: (): RequestExtraAction => ({
    type: ExtraActionEnum.REQUEST_EXTRAS,
  }),
  resultGetExtras: (data: IExtra[]): ResultExtraAction => ({
    type: ExtraActionEnum.RESULT_EXTRAS,
    data,
  }),
};
