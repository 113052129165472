import { Skeleton } from "@mui/material";
import { FC } from "react";

const PreviewExtra: FC = () => (
  <div
    style={{
      width: `100%`,
      display: `grid`,
      gridTemplateRows: `repeat(4, 1fr)`,
      gap: `20px`,
      alignItems: `center`,
    }}
  >
    {new Array(4).fill(0).map((a, index) => (
      <Skeleton key={index} variant="rectangular" width={`100%`} height={281} />
    ))}
  </div>
);

export default PreviewExtra;
