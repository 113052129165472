import { VanFilterErrors } from "src/store/reducers/van/types";

const showFilterErrors = (enqueueSnackbar: any, errors: VanFilterErrors) =>
  Object.values(errors).forEach(
    (value) =>
      !!value &&
      enqueueSnackbar(value, {
        variant: "error",
      })
  );
export default showFilterErrors;
