import { put, takeLatest } from "redux-saga/effects";
import { CancelOrderActionCreators } from "src/store/reducers/cancelOrder/action-creators";
import { CancelOrderActionEnum } from "src/store/reducers/cancelOrder/types";
import instance from "../set";

function* fetchCancelOrder({
    order_id, email
}: ReturnType<typeof CancelOrderActionCreators.cancelOrder>) {
  try {
    const { status } = yield instance.post('booking/cancel', {
        order_id,
        email
    });
    yield put(CancelOrderActionCreators.resultCancelOrder(status));
  } catch (error) {
    yield put(CancelOrderActionCreators.resultCancelOrder(400));
    console.log("error :>> ", error);
  }
}
export function* actionWatcherCancelOrder() {
  yield takeLatest(CancelOrderActionEnum.CANCEL_ORDER, fetchCancelOrder);
}
