import { FC, useState } from 'react';
import { Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CancelModal from 'src/pages/Client/components/CancelModal';
import ActionItem from './Item';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { IOrderForClient } from 'src/models/IOrder';
import Icons from 'src/common/icons';
import './styles.scss';

interface IProps {
  order: IOrderForClient
}

const ActionsCard: FC<IProps> = ({ order }) => {

  const client = useTypedSelector((state) => state.client.client);
  const [openCancel, setOpenCancel] = useState(false);

  return (
    <>
      <div className='actions-card'>
        <div className='client__order__main__header'>
          <h4>Quick Actions</h4>
          <div className='client__order__main__header__actions'>
            <Button
              children='CANCEL TRIP'
              onClick={() => setOpenCancel(true)}
            />
          </div>
        </div>
        <div className='actions-card__container'>
          <div className='actions-card__container__item'>
            <div className='actions-card__container__item__title'>
              <Icons.EmailUs />
              <h6>Email us</h6>
            </div>
            <a
              href={`https://mail.google.com/mail/?view=cm&source=mailto&to=${order.van?.host.email}&su=van rental number ${order.id}`}
              target='_blank'
            >
              <ArrowForwardIosIcon />
            </a>
          </div>
          <ActionItem
            title={'Change Primary Contact Name'}
            icon={<Icons.ChangeContactName />}
            orderId={order.id}
          />
          <ActionItem
            title={'Edit Primary Contact Email'}
            icon={<Icons.ChangeContactEmail />}
            orderId={order.id}
          />
        </div>
      </div>
      <CancelModal
        handleClose={() => setOpenCancel(false)}
        open={openCancel}
        id={order.id}
        email={client!.email}
      />
    </>
  );
};

export default ActionsCard;
