import { FC } from 'react';
import { orderBy } from 'natural-orderby';
import SimpleSelect from '../../select/SimpleSelect';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { useActions } from 'src/hooks/useActions';
import { Location as ILocation } from './../../../../store/reducers/location/types';
import { getNames } from 'src/common/utils/others';

interface IProps {
  check: boolean;
  selectedLocations?: ILocation[];
  className?: string;
}

const Location: FC<IProps> = ({ check, selectedLocations, className }) => {
  const { setLocationFilter } = useActions();
  const {
    van: {
      filters: { location, sub_location },
    },
    location: { locationList },
  } = useTypedSelector((state) => state);
  const setLocation = (location: string) =>
    setLocationFilter(location, '');
  const sortedLocation = orderBy(
    (selectedLocations ? selectedLocations : locationList),
    [(location) => location.country, (location) => location.name],
    ['asc', 'asc']
  );
  return (
    <div className={`filter__location ${className}`}>
      <SimpleSelect
        data={sub_location ? sub_location : location}
        setData={setLocation}
        dataArrey={getNames(sortedLocation)}
        placeholder='Where?'
        style={{ width: `190px` }}
        notShowSelect
        title='Start in'
        check={check}
      />
    </div>
  );
};
export default Location;
