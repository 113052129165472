import { IVanFetch } from 'src/models/IVan';
import { IVanFilter } from 'src/models/IVanFilter';

export enum VanActionEnum {
  REQUEST_VANS = 'REQUEST_VANS',
  RESULT_VANS = 'RESULT_VANS',
  ERROR_VANS = 'ERROR_VANS',

  REQUEST_VAN = 'REQUEST_VAN',
  RESULT_VAN = 'RESULT_VAN',

  REQUEST_CHECK_VAN = 'REQUEST_CHECK_VAN',
  RESULT_CHECK_VAN = 'RESULT_CHECK_VAN',

  REQUEST_HOST_VANS = 'REQUEST_HOST_VANS',
  RESULT_HOST_VANS = 'RESULT_HOST_VANS',
}
export enum VanFilterActionEnum {
  SETUP_FILTERS = 'SETUP_FILTERS',
  PAGE = 'PAGE',
  TAKE = 'TAKE',
  HOST_ID = 'HOST_ID',
  LOCATION = 'LOCATION',
  HOME_LOCATION = 'HOME_LOCATION',
  REGION = 'REGION',
  SEATS = 'SEATS',
  TYPE = 'TYPE',
  YEAR = 'YEAR',
  TAGS = 'TAGS',
  FEATURES = 'FEATURES',
  START_PRICE = 'START_PRICE',
  END_PRICE = 'END_PRICE',
  CLEAR = 'CLEAR',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  ERROR_FILTER = 'ERROR_FILTER',
  CLEAR_ERROR_FILTER = 'CLEAR_ERROR_FILTER',
  SET_CHECK_FILTER = 'SET_CHECK_FILTER',
  END_LOCATION = 'END_LOCATION',
  ONEWAY_ID = 'ONEWAY_ID',
}

export interface VanFilterErrors {
  location: string;
  start_date: string;
  end_date: string;
}
export interface VanState {
  vanList: { [key: string]: IVanFetch[] };
  van?: IVanFetch;
  total: number | null;
  isLoading: {
    all: boolean;
    get: boolean;
    check: boolean;
  };
  result: {
    check?: number | string;
  },
  filters: IVanFilter;
  errors: VanFilterErrors;
  isFilterValidate: boolean;
  minPrice: number | null;
  maxPrice: number | null;
  checkFilter: boolean;
}

export interface GetVansAction {
  type: VanActionEnum.REQUEST_VANS;
  filter: IVanFilter;
}
export interface ResultGetVansAction {
  type: VanActionEnum.RESULT_VANS;
  vanList: { [key: string]: IVanFetch[] } | IVanFetch[];
  count: number;
  minPrice: number;
  maxPrice: number;
}
export interface GetHostVansAction {
  type: VanActionEnum.REQUEST_HOST_VANS;
  host: string;
}
export interface ResultGetHostVansAction {
  type: VanActionEnum.RESULT_HOST_VANS;
  vanList: { [key: string]: IVanFetch[] } | IVanFetch[];
}
export interface GetVanAction {
  type: VanActionEnum.REQUEST_VAN;
  id: number;
}
export interface ResultGetVanAction {
  type: VanActionEnum.RESULT_VAN;
  data: IVanFetch;
}

export interface CheckVanAction {
  type: VanActionEnum.REQUEST_CHECK_VAN;
  id: number;
  period: { start_date: string, end_date: string }
}
export interface ResultCheckVanAction {
  type: VanActionEnum.RESULT_CHECK_VAN;
  data: IVanFetch;
  status: string | number;
}

export type VanAction =
  | GetVansAction
  | ResultGetVansAction
  | GetVanAction
  | ResultGetVanAction
  | GetHostVansAction
  | ResultGetHostVansAction
  | CheckVanAction
  | ResultCheckVanAction;

export interface SetupFilters {
  type: VanFilterActionEnum.SETUP_FILTERS;
  filters: IVanFilter;
}
export interface VanFilterFeatureAction {
  type: VanFilterActionEnum.FEATURES;
  features: string[];
}
export interface VanFilterTypeAction {
  type: VanFilterActionEnum.TYPE;
  vanType: string;
}
export interface VanFilterSeatsAction {
  type: VanFilterActionEnum.SEATS;
  seat?: number;
}
export interface VanFilterStartPriceAction {
  type: VanFilterActionEnum.START_PRICE;
  startPrice: number;
}
export interface VanFilterEndPriceAction {
  type: VanFilterActionEnum.END_PRICE;
  endPrice: number;
}
export interface VanFilterLocationAction {
  type: VanFilterActionEnum.LOCATION;
  location: string;
  sub_location: string;
}
export interface VanFilterEndLocationAction {
  type: VanFilterActionEnum.END_LOCATION;
  end_location: string;
}
export interface VanFilterRegionAction {
  type: VanFilterActionEnum.REGION;
  region: string[];
}
export interface VanFilterStartDateAction {
  type: VanFilterActionEnum.START_DATE;
  startDate: string;
}
export interface VanFilterEndDateAction {
  type: VanFilterActionEnum.END_DATE;
  endDate: string | undefined;
}
export interface ClearVanFilterAction {
  type: VanFilterActionEnum.CLEAR;
}
export interface ErrorVanFilterAction {
  type: VanFilterActionEnum.ERROR_FILTER;
  errors: any;
  isFilterValidate: boolean;
}
export interface ClearErrorVanFilterAction {
  type: VanFilterActionEnum.CLEAR_ERROR_FILTER;
}
export interface SetCheckFilter {
  type: VanFilterActionEnum.SET_CHECK_FILTER;
}

export type VanFilterAction =
  | VanFilterFeatureAction
  | VanFilterTypeAction
  | VanFilterSeatsAction
  | VanFilterStartPriceAction
  | VanFilterEndPriceAction
  | VanFilterLocationAction
  | VanFilterRegionAction
  | VanFilterStartDateAction
  | VanFilterEndDateAction
  | ClearVanFilterAction
  | SetupFilters
  | ErrorVanFilterAction
  | ClearErrorVanFilterAction
  | VanFilterEndLocationAction
  | SetCheckFilter;
