import { FC } from 'react';
import { Backdrop, Modal, Slide } from '@mui/material';
import ShareItems from './Items';
import './styles.scss';
import './media.scss';

interface IProps {
  id: number;
  open: boolean;
  onClose: () => void;
  isDown?: boolean
}

const ShareModal: FC<IProps> = ({ open, onClose, id, isDown }) => {

  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      closeAfterTransition
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <Slide direction="down" in={open} mountOnEnter unmountOnExit>
        <div className={`share-modal ${isDown ? 'down' : 'up'}`}>
          <ShareItems id={id} />
        </div>
      </Slide>
    </Modal >
  );
};
export default ShareModal;
