import { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ModalAction from 'src/components/Items/Actions';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import Icons from 'src/common/icons';
import { Driver, IDriver } from 'src/models/IDriver';

interface IProps { }

const AdditionalDriverForm: FC<IProps> = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { addDriver } = useActions();
  const { isLogin } = useTypedSelector((state) => state.client);

  const [drivers, setDrivers] = useState<IDriver[]>([{ ...new Driver() }]);
  const [check, setCheck] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(false);
    drivers.map(({ first_name, last_name, email }) => {
      (!first_name || !last_name || !email) && setIsError(true);
    });
  }, [drivers]);

  const removeItem = (index: number) => () =>
    setDrivers([...drivers.slice(0, index), ...drivers.slice(index + 1)]);

  const handleAddMore = () => setDrivers([...drivers, { ...new Driver() }]);

  const handleChange =
    (index: number, key: keyof Omit<IDriver, 'id'>) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        const editedArr: any[] = [...drivers];
        editedArr[index][key] = event;
        setDrivers(editedArr);
      };

  const handleClose = () => {
    isLogin
      ? navigate('/client')
      : navigate('/');
  };

  const submit = () => {
    if (isError) setCheck(true);
    else {
      const newArray = [] as IDriver[];
      drivers.map((driver) => (
        newArray.push({
          ...driver,
          email: driver.email.toLowerCase().trim(),
          first_name: driver.first_name.trim(),
          last_name: driver.last_name!.trim(),
        })
      ));
      addDriver(newArray, id as string);
      enqueueSnackbar('Adding drivers...');
      setDrivers([{ ...new Driver() }]);
      setIsError(false);
      isLogin
        ? navigate('/client')
        : navigate('/success/driver-invite');
    }
  };

  return (
    <>
      {drivers.map(({ first_name, last_name, email }, index: number) => (
        <Fragment key={index}>
          <div className='driver__item'>
            {index > 0 && (
              <div
                className='driver__item__remove-icon'
                onClick={removeItem(index)}
              >
                <Icons.DeleteOneRow />
              </div>
            )}
            <div className='col-2 driver__item__name'>
              <SimpleInput
                data={first_name}
                setData={handleChange(index, 'first_name')}
                title='First name'
                placeholder='Enter their first name'
                length={60}
                check={check}
              />
              <SimpleInput
                data={last_name!}
                setData={handleChange(index, 'last_name')}
                title='Last name'
                placeholder='Enter their last name'
                length={60}
                check={check}
              />
            </div>
            <SimpleInput
              data={email}
              setData={handleChange(index, 'email')}
              title='Drivers Email'
              placeholder='Enter their email'
              length={60}
              check={check}
            />
          </div>
        </Fragment>
      ))}
      <SecondTypeButton
        children='Add another driver'
        onClick={handleAddMore}
        className='driver__add-more'
      />
      <ModalAction
        type='driver'
        handleClose={handleClose}
        handleSubmit={submit}
        action='Invite'
      />
    </>
  );
};
export default AdditionalDriverForm;
