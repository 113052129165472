import { put, takeLatest } from "redux-saga/effects";
import { ClientActionCreators } from "src/store/reducers/client/action-creators";
import { ClientActionEnum } from "src/store/reducers/client/types";
import instanceWithToken from "src/sagas/setWithToken";

function* fetchShowLicense({
  key,
}: ReturnType<typeof ClientActionCreators.showLicense>) {
  try {
    const id = key.split('license/')[1];
    const { data } = yield instanceWithToken.get(`license/show/${id}`);
    yield put(ClientActionCreators.resultShowLicense(data));
  } catch (error: any) {
    console.log(error);
  }
}
export function* actionWatcherShowLicense() {
  yield takeLatest(ClientActionEnum.REQUEST_SHOW_LICENSE, fetchShowLicense);
}