import { FC, useEffect, useRef, useState } from 'react';
import { Button, ClickAwayListener } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { useActions } from 'src/hooks/useActions';

interface IProps {
  title: string;
  icon: JSX.Element;
  orderId: number
}

const ActionItem: FC<IProps> = ({ title, icon, orderId }) => {

  const anchorRef = useRef<HTMLDivElement>(null);
  const { updateClient, updateOrder } = useActions();
  const client = useTypedSelector((state) => state.client.client);
  const [expand, setExpand] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [currentClient, setCurrentClient] = useState(client);

  useEffect(() => {
    client && setCurrentClient(client);
  }, [client]);

  useEffect(() => {
    title.toLowerCase().includes('name') ? (
      (
        (currentClient?.first_name === client?.first_name
          && currentClient?.last_name === client?.last_name)
        || !currentClient?.first_name.length
        || !currentClient?.last_name.length
      ) ? setIsDisable(true) : setIsDisable(false)
    ) : (
      (
        currentClient?.email === client?.email
        || !currentClient?.email.length
      ) ? setIsDisable(true) : setIsDisable(false)
    );
  }, [currentClient]);

  const handleClick = () => {
    anchorRef.current!.classList.toggle('rotate');
    setExpand(!expand);
  };

  const handleClose = () => {
    expand && anchorRef.current!.classList.toggle('rotate');
    setExpand(false);
  };

  const handleChange = (name: string) => (value: string) =>
    setCurrentClient({ ...currentClient!, [name]: value });

  const onSubmit = () => {
    title.toLowerCase().includes('name') ? (
      updateClient({
        id: client!.id,
        first_name: currentClient!.first_name.trim(),
        last_name: currentClient!.last_name.trim()
      })
    ) : (
      updateClient({
        id: client!.id,
        email: currentClient!.email.toLowerCase().trim(),
      }),
      updateOrder({
        id: orderId,
        client_email: currentClient!.email.toLowerCase().trim()
      })
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className='actions-card__container__expand'>
        <div className='actions-card__container__item'>
          <div className='actions-card__container__item__title'>
            {icon}
            <h6>{title}</h6>
          </div>
          <div
            ref={anchorRef}
            onClick={handleClick}
          >
            <ArrowForwardIosIcon />
          </div>
        </div>
        <div
          className={
            `actions-card__container__expand__edit 
              ${expand ? 'show' : 'hide'}`
          }
        >
          {title.toLowerCase().includes('name') ? (
            <>
              <SimpleInput
                data={currentClient?.first_name ? currentClient?.first_name : ''}
                setData={handleChange('first_name')}
                title='First name'
                placeholder='Enter your first name'
                variant='outlined'
              />
              <SimpleInput
                data={currentClient?.last_name ? currentClient?.last_name : ''}
                setData={handleChange('last_name')}
                title='Last name'
                placeholder='Enter your last name'
                variant='outlined'
              />
            </>
          ) : (
            <SimpleInput
              data={currentClient?.email ? currentClient?.email : ''}
              setData={handleChange('email')}
              title='Email'
              placeholder='Enter your email'
              variant='outlined'
            />
          )}
          <Button
            variant='contained'
            onClick={onSubmit}
            children='Submit'
            disabled={isDisable}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default ActionItem;
