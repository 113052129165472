import axios from "axios";
import { put, takeLatest } from "redux-saga/effects";
import { ClientActionCreators } from "src/store/reducers/client/action-creators";
import { ClientActionEnum } from "src/store/reducers/client/types";

function* fetchSignup({
  email,
}: ReturnType<typeof ClientActionCreators.signup>) {
  try {
    const { status } = yield axios.post(`${process.env.REACT_APP_API}/auth/customer/signup`, {
      'destination': email,
    });
    yield put(ClientActionCreators.resultSignup(status));
  } catch (error) {
    console.log("error :>> ", error);
    yield put(ClientActionCreators.resultSignup(400));
  }
}
export function* actionWatcherSignup() {
  yield takeLatest(ClientActionEnum.REQUEST_SIGNUP, fetchSignup);
}
