import { FC } from 'react';
import { TextField, styled } from '@mui/material';
import { IErrorsClientData } from '../Stepper/Step2';
import { IClient, IOrder } from 'src/models/IOrder';
import { typeForm } from 'src/theme';
import './styles.scss';

const StyledInput = styled(TextField)({
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    'WebkitAppearance': 'none',
    margin: 0
  },
  'input[type=number]': {
    'MozAppearance': 'textfield'
  }
});

interface IProps {
  order: IOrder;
  onChange: (name: string, value: any) => void;
  errors: IErrorsClientData;
  check: boolean;
  isDelivery: boolean;
}

const ContactData: FC<IProps> = ({
  order,
  onChange,
  errors,
  check,
  isDelivery,
}) => {
  const _renderContactInput = (
    name: keyof Pick<
      IClient,
      'first_name' | 'last_name' | 'email' | 'phone' | 'country' | 'city' | 'business_name'
    >,
    span?: string
  ) => (
    <div data-title={
      name === 'business_name'
        ? 'Is this for your Business/Org?'
        : name
    }>
      <div className='filter__title'>
        {name === 'business_name'
          ? 'Is this for your Business/Org?'
          : name.replace('_', ' ')
        }
        {span &&
          <div className={`filter__title__tip ${span.includes('texts & calls') && 'remove-margin'}`}>
            {span}
          </div>
        }
      </div>
      <StyledInput
        inputProps={{
          maxLength: 50,
        }}
        sx={{
          input: {
            '::placeholder': {
              color: 'rgb(160 158 158)',
              opacity: 1,
            },
          },
        }}
        placeholder={
          name === 'business_name'
            ? 'Add your business/org IF it goes on the contract'
            : `Type ${name.replace('_', ' ')}`
        }
        variant={typeForm}
        type={name === 'phone' ? 'number' : 'text'}
        value={order.client[name]}
        onChange={(event) =>
          onChange('client', {
            ...order.client,
            [name]: name === 'phone'
              ? event.target.value.trim()
              : event.target.value
          })
        }
        error={!!errors[name]}
        helperText={errors[name]}
      />
    </div>
  );

  const _renderInputSeats = () => (
    <>
      <div className='filter__title'>How many in your group?</div>
      <div className='input-button'>
        <TextField
          fullWidth
          sx={{
            maxWidth: `none`,
            input: {
              '::placeholder': {
                color: 'rgb(160 158 158)',
                opacity: 1,
              },
            },
          }}
          placeholder='Including the person driving'
          variant={typeForm}
          InputProps={{
            inputProps: {
              max: 30,
              min: 0,
            },
          }}
          value={order.amount_seats ?? ''}
          onChange={(event) => {
            let value = parseInt(event.target.value, 10);
            if (isNaN(value)) value = 0;
            if (value > 30) value = 30;
            if (value < 0) value = 0;
            onChange('amount_seats', value);
          }}
        />
      </div>
    </>
  );

  const _renderInput = (
    name: string,
    title: string = name,
    rows: number = 0,
    span?: string,
    placeholder?: string
  ) => {
    const notLimit = name === 'delivery_address' || name === 'return_at' || name === 'note';
    return (
      <>
        <div
          className='filter__title'
          style={{ marginBottom: name === 'note' ? '10px' : 0 }}
        >
          {title}

          {span && <div className='filter__title__tip'>{span}</div>}
        </div>
        <div className='input-button'>
          <TextField
            inputProps={{
              maxLength: !notLimit ? 40 : 300,
            }}
            fullWidth
            placeholder={placeholder ?? `Type ${name === 'note' && 'us a'} ${title}`}
            variant={name === 'note' ? 'outlined' : typeForm}
            value={order[name as keyof IOrder] ?? ''}
            onChange={(event) => onChange(name, event.target.value)}
            rows={rows}
            multiline={!!rows}
            error={name === 'delivery_address' && !!errors[name]}
            helperText={name === 'delivery_address' && errors[name]}
            sx={{
              maxWidth: 'none',
              '&.MuiFormControl-root': {
                height: name === 'note' ? 'auto' : '40px',
              },
              input: {
                '::placeholder': {
                  color: 'rgb(160 158 158)',
                  opacity: 1,
                },
              },
              '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                color: 'rgb(160 158 158)',
                opacity: 1,
              }
            }}
          />
        </div>
      </>
    );
  };

  return (
    <div className='order__contact'>
      <div className='order__contact__title'>Your details</div>
      <div className='order__contact__fields'>
        {_renderContactInput('first_name')}
        {_renderContactInput('last_name')}
        {_renderContactInput('email', 'TIP: Check for correctness')}
        {_renderContactInput('phone', 'I consent to receive texts & calls about this reservation')}
        <div className='order__contact__amount-seats'>
          {_renderInputSeats()}
        </div>
        {_renderContactInput('business_name')}
      </div>
      {isDelivery && (
        <div className='order__contact__delivery-address'>
          <div style={{ marginBottom: '20px' }} >
            {_renderInput(
              'delivery_address',
              'delivery address',
              0,
              '',
              'Address or Business Name'
            )}
          </div>
          <div style={{ marginBottom: '10px' }} >
            {_renderInput(
              'return_at',
              'Return Pick-up address',
              0,
              '',
              'Same as delivery or other'
            )}
          </div>
        </div>
      )}
      <p style={{ color: '#4b566b', fontWeight: 500 }}>Invite drivers for free at a later step</p>
      <div className='order__contact__note'>
        {_renderInput('note', 'note', 3)}
      </div>
    </div>
  );
};

export default ContactData;
