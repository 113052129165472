import { put, takeLatest } from "redux-saga/effects";
import instanceWithToken from "src/sagas/setWithToken";
import { ClientActionCreators } from "src/store/reducers/client/action-creators";
import { ClientActionEnum } from "src/store/reducers/client/types";


function* fetchShowInsurance({
  key,
}: ReturnType<typeof ClientActionCreators.showInsurance>) {
  try {
    const id = key.split('insurance/')[1];
    const { data } = yield instanceWithToken.get(`insurance/show/${id}`);
    yield put(ClientActionCreators.resultShowInsurance(data));
  } catch (error: any) {
    console.log(error);
  }
}
export function* actionWatcherShowInsurance() {
  yield takeLatest(ClientActionEnum.REQUEST_SHOW_INSURANCE, fetchShowInsurance);
}
