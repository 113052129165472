import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { store } from 'src/store';
import { GlobalActionEnum } from 'src/store/reducers/global/types';

const config: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_API}/client/`,
};

const instance: AxiosInstance = axios.create(config);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('error', error);
    store.dispatch({
      type: GlobalActionEnum.ERROR,
      result: error.response.data,
      status: error.response.status,
    });
  }
);

export default instance;
