import React, { FC, useMemo, useState } from 'react';
import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import ChatIcon from '@mui/icons-material/Chat';
import './styles.scss';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const Help: FC = () => {

  const isMobile = useMemo(() => window.outerWidth < 501, [window.outerWidth]);
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        className='help'
        onClick={() => setOpen(true)}
      >
        <ChatIcon />
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
      >
        <iframe
          height={isMobile ? '400px' : '500px'}
          width={isMobile ? 'auto' : '400px'}
          frameBorder='0'
          src="https://widget.botsonic.com/CDN/index.html?service-base-url=https%3A%2F%2Fapi-azure.botsonic.ai&token=e15a9cad-62f1-46a3-9d7b-e395aa6f5262&base-origin=https%3A%2F%2Fbot.writesonic.com&instance-name=Botsonic&standalone=true&page-url=https%3A%2F%2Fbot.writesonic.com%2Fbots%2F758b0a21-af89-46cb-b5ee-6f463da76611%2Fconnect">
        </iframe>
      </Dialog>
    </>
  );
};

export default Help;
