import { CancelOrderActionEnum, RequestCancelOrderAction, ResultCancelOrderAction } from "./types";

export const CancelOrderActionCreators = {
  cancelOrder: (order_id: number, email: string): RequestCancelOrderAction => ({
    type: CancelOrderActionEnum.CANCEL_ORDER,
    order_id,
    email,
  }),
  resultCancelOrder: (status: string | number): ResultCancelOrderAction => ({
    type: CancelOrderActionEnum.RESULT_CANCEL_ORDER,
    status
  }),
};
