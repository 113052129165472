import { FC } from 'react';
import { ThemeProvider, styled } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import MainRouters from 'src/components/Routes/MainRouters';
import SnackbarIcon from '../Items/SnackbarIcon';
import theme from 'src/theme';
import 'src/fonts/stylesheet.css';
import './styles.scss';

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarContent-root {
    min-width: 220px;
  },
  &.SnackbarItem-contentRoot {
    background-color: #24242e;
    border-radius: 13px;
    padding-left: 16px;
  }
`;

const App: FC = () => (
  <StyledSnackbarProvider
    maxSnack={4}
    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    autoHideDuration={3000}
    iconVariant={{
      success: <SnackbarIcon type={'success'} />,
      error: <SnackbarIcon type={'error'} />,
      warning: <SnackbarIcon type={'warning'} />,
      info: <SnackbarIcon type={'info'} />
    }}
  >
    <div className='App'>
      <ThemeProvider theme={theme}>
        <MainRouters />
      </ThemeProvider>
    </div>
  </StyledSnackbarProvider>
);

export default App;
