import { put, takeLatest } from "redux-saga/effects";
import { FeatureActionEnum } from "src/store/reducers/feature/types";
import { FeatureActionCreators } from "src/store/reducers/feature/action-creators";
import instance from "../set";

function* fetchGetFeatures() {
  try {
    const { data } = yield instance.get(`feature`);
    yield put(FeatureActionCreators.resultGetFeatures(data));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetFeatures() {
  yield takeLatest(FeatureActionEnum.REQUEST_FEATURES, fetchGetFeatures);
}
