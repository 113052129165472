import { FC } from 'react';
import { TextField } from '@mui/material';
import { colorSet, typeForm } from 'src/theme';

interface IProps {
  check?: boolean;
  data: string | number;
  setData: (event: any) => void;
  title?: string;
  placeholder?: string;
  length?: number;
  type?: string;
  rows?: number;
  multiline?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  index?: boolean;
  name?: string;
  style?: {};
  inputStyle?: {};
  variant?: 'standard' | 'filled' | 'outlined';
  error?: string;
  isHelperText?: boolean
}

const SimpleInput: FC<IProps> = ({
  data,
  setData,
  check,
  title,
  length,
  type,
  index,
  rows,
  multiline,
  fullWidth,
  placeholder = title,
  required,
  style,
  inputStyle,
  variant,
  error,
  isHelperText
}) => {
  const helperError = () => {
    if (check) {
      if (error) return true;
      else if (!data) return true;
      else return false;
    }
  };
  const helperText = () => {
    if (check) {
      if (error) return error;
      else if (!data) return 'Fill the field';
      else return;
    } else return placeholder;
  };

  return (
    <div style={style}>
      {title && (
        <div
          className='filter__title'
          style={{ marginBottom: variant === 'outlined' ? '10px' : '3px' }}
        >
          {title}
        </div>
      )}
      <TextField
        inputProps={{
          maxLength: length,
        }}
        sx={{
          ...inputStyle,
          '&.MuiFormControl-root': {
            height: multiline ? 'auto' : '40px',
          },
          input: {
            '::placeholder': {
              color:
                check && !data ? colorSet.primaryRed : 'rgb(160 158 158)',
              opacity: 1,
            },
          },
        }}
        error={helperError()}
        placeholder={helperText()}
        variant={variant ?? typeForm}
        type={type ?? 'text'}
        value={data ?? ''}
        onChange={
          index
            ? setData
            : (event) => {
              setData(event.target.value);
            }
        }
        autoComplete='off'
        rows={rows}
        multiline={multiline}
        fullWidth={fullWidth}
        required={required}
        data-title={title}
        helperText={isHelperText && error}
      />
    </div>
  );
};

export default SimpleInput;
