import Times from './Item/Times';
import Location from './Item/Location';
import Color from './Item/Color';
import Feature from './Item/Feature';
import HostRating from './Item/HostRating';
import Price from './Item/Price';
import Type from './Item/Type';
import Extra from './Item/Extra';
import Reviews from './Item/Reviews';
import './styles.scss';
import AnotherLocation from './Item/AnotherLocation';

const Filters = {
  Location,
  AnotherLocation,
  Times,
  Type,
  Color,
  Feature,
  Extra,
  HostRating,
  Price,
  Reviews,
};

export default Filters;
