import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import lodash from 'lodash';
import { useSnackbar } from 'notistack';
import { Avatar, Button, Tooltip } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import NoImages from 'src/components/Items/NoImages';
import CustomSwiper from 'src/components/Items/CustomSwiper';
import ScrollItem from 'src/components/Items/ScrollItem';
import ShareModal from 'src/components/Items/ShareModal';
import GalleryModal from '../Modal/Gallery';
import SingleVanModal from '../Modal/SingleVanModal';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import useIsMobile from 'src/hooks/useIsMobile';
import { useActions } from 'src/hooks/useActions';
import { VanFilterErrors } from 'src/store/reducers/van/types';
import { cutText, scrollToTop } from 'src/common/utils/others';
import showFilterErrors from 'src/common/utils/showFilterErrors';
import { dineroFormat } from 'src/common/utils/dataFormat';
import { IVanFetch } from 'src/models/IVan';
import Check from './CheckIcon';
import './styles.scss';
import './media.scss';

interface IProps {
  van: IVanFetch;
  region?: string;
  isFilterValidate: boolean;
  errors: VanFilterErrors;
}

const VanItem: FC<IProps> = ({ van, region, isFilterValidate, errors }) => {

  const { enqueueSnackbar } = useSnackbar();
  const { setupOrder, setCheckFilter, setLocationFilter } = useActions();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const {
    location: { locationList }
  } = useTypedSelector((state) => state);
  const selectedLocation = useTypedSelector((state) => state.van.filters.location);
  const { id, description, photos, mark, price, currency, near_van, tags, host, features } = van;

  // nearVan logic
  // const [deliveryPrice, setDeliveryPrice] = useState('');
  // useEffect(() => {
  //   selectedLocation &&
  //     near_van.map((nearVan) => (
  //       nearVan.location === selectedLocation
  //       && setDeliveryPrice(dineroFormat(nearVan.price, nearVan.currency))
  //     ));
  // }, [selectedLocation]);

  // subLocation logic
  const [subLocationNames, setSubLocationNames] = useState<string[]>([]);
  useEffect(() => {
    const locationTags = tags
      .filter((tag) => tag.category === 'sublocation')
      .map((i) => i.name);
    const subNames = locationList
      .find((location) => location.name === selectedLocation)
      ?.sub_location.map((sub) => sub.name);
    if (subNames) {
      const intersection = lodash.intersection(locationTags, subNames);
      setSubLocationNames(intersection);
    }
  }, [selectedLocation]);

  const [openVanModal, setOpenVanModal] = useState(false);
  const [isOpenShareInModal, setIsOpenShareInModal] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const handleOpenVanModal = () => setOpenVanModal(true);
  const handleOpenShare = () => setOpenShare(true);
  const handleOpenVanModalWithShare = () => {
    setOpenVanModal(true);
    setIsOpenShareInModal(true);
  };
  const handleCloseVanModal = () => {
    setOpenVanModal(false);
    setIsOpenShareInModal(false);
  };

  const handleOrder = () => {
    if (isFilterValidate) {
      // if (region) {
      //   selectedLocation !== region
      //     && setLocationFilter(region, '');
      // }
      setupOrder(van);
      navigate('/order');
    } else {
      setCheckFilter();
      scrollToTop();
      !isMobile && showFilterErrors(enqueueSnackbar, errors);
    }
  };

  const [index, setIndex] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const handleCloseGallery = () => setOpen(false);
  const handleOpenGallery = (index: number) => {
    setIndex(index);
    setOpen(true);
  };

  return (
    <div className='van__card'>
      <div className='van__imgs'>
        <div className='van__main-imgs'>
          {photos.exterior.length || photos.interior.length ? (
            <CustomSwiper
              data={
                isMobile
                  ? [...photos.exterior, ...photos.interior]
                  : photos.exterior
              }
            />
          ) : (
            <NoImages />
          )}
        </div>
        <div className='van__info__imgs'>
          {photos.interior.slice(0, 2).map((photo, index) => (
            <img
              key={index}
              src={photo}
              alt='inner'
              loading='lazy'
              onClick={() => handleOpenGallery(index)}
            />
          ))}
        </div>
      </div>
      <div>
        <div className='van__info'>
          {isMobile ? (
            <Link to={`/van/143${id}`}
              target="_blank"
              className='van__info__name'
            >
              {mark}
            </Link>
          ) : (
            <div className='van__info__name' onClick={handleOpenVanModal}>
              {mark}
            </div>
          )}
          <Tooltip title='Share van link'>
            <IosShareIcon onClick={isMobile ? handleOpenShare : handleOpenVanModalWithShare} />
          </Tooltip>
          <div className='van__info__price'>
            {dineroFormat(price, currency)}
            <div className='van__info__price__description'>average per day</div>
          </div>
        </div>
        {subLocationNames.length ? (
          <ScrollItem array={subLocationNames} id={id} className='sub-locations' />
        ) : <></>}
        <div className='van__info__description'>
          {cutText(description, 220)}
          {isMobile ? (
            <Link to={`/van/143${id}`}
              target="_blank"
              className='van__info__description_more'
            >
              read more
            </Link>
          ) : (
            <div
              className='van__info__description_more'
              onClick={handleOpenVanModal}
            >
              read more
            </div>
          )}
        </div>
        <div className='van__capacity'>
          <div className='van__capacity__title'>Features:</div>
          <ScrollItem
            id={id}
            van={van}
            array={features}
            className='van__capacity__container'
          />
        </div>
        <div className='van__order'>
          {/* {selectedLocation && (selectedLocation !== location) &&
            <div className='van__info__near-van mobile-show'>
              {deliveryPrice ? (
                <>
                  Delivery from {location} will cost <b>{deliveryPrice}</b>
                </>
              ) : (
                <span className='van__info__near-van__no-price'>
                  <Icons.Warning />
                  <span>
                    Please contact the owner to clarify the price for delivery.
                  </span>
                </span>
              )}
            </div>
          } */}
          <div className={`
            van__order__container ${van.type === 'passenger' && 'van__order__delivery-container'}
          `}>
            <div className='van__order__host'>
              {host.photo ? (
                <img
                  src={host.photo}
                  alt='user photo'
                  loading='lazy'
                  className='user-photo'
                />
              ) : (
                <Avatar src="/broken-image.jpg" />
              )}
              <div className='van__order__host__container'>
                <p className='van__order__host__title'>Hosted by:</p>
                <p className='van__order__host__name'>
                  {`${host.first_name.split(' ')[0]} ${host.last_name.slice(0, 1)}.`}
                </p>
              </div>
            </div>
            <div className='van__order__extras'>
              <div className='van__order__extra'>
                <div className='van__order__extra__item van__order__extra__delivery'>
                  <Check />
                  <div className='van__order__extra__delivery__text'>
                    Can be delivered
                  </div>
                </div>
              </div>
              {van.type === 'passenger' && (
                <div className='van__order__extra'>
                  <div className='van__order__extra__item van__order__extra__miles'>
                    <Check />
                    <div>
                      <div>
                        <b>Unlimited Miles</b>
                      </div>
                      <div>by Leaving a Review</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='van__order__block'>
            <Button
              className='van__order__btn'
              onClick={handleOrder}
              children='Book your van'
              variant='contained'
            />
          </div>
        </div>
      </div>
      {/* {selectedLocation && (selectedLocation !== location) &&
        <div className='van__info__near-van mobile-hide'>
          {deliveryPrice ? (
            <>
              Delivery from {location} will cost <b>{deliveryPrice}</b>
            </>
          ) : (
            <span className='van__info__near-van__no-price'>
              <Icons.Warning />
              <span>
                Please contact the owner to clarify the price for delivery.
              </span>
            </span>
          )}
        </div>
      } */}
      <GalleryModal
        open={open}
        handleClose={handleCloseGallery}
        imgs={[...photos.interior, ...photos.exterior]}
        index={index!}
      />
      <SingleVanModal
        open={openVanModal}
        handleClose={handleCloseVanModal}
        van={van}
        isOpenShare={isOpenShareInModal}
      />
      <ShareModal
        id={van.id}
        open={openShare}
        onClose={() => setOpenShare(false)}
        isDown
      />
    </div>
  );
};
export default VanItem;
