import { FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Button, CircularProgress } from "@mui/material";
import Filters from "src/components/Items/Filters";
import VanCard from "../../components/item";
import { Location } from './../../../../store/reducers/location/types';
import { useTypedSelector } from "src/hooks/useTypeSelector";
import { useActions } from "src/hooks/useActions";
import useIsMobile from "src/hooks/useIsMobile";
import showFilterErrors from "src/common/utils/showFilterErrors";
import { validateFilter } from "src/common/utils/validateFilter";
import { handleChangeFilter } from "src/common/utils/setFilters";
import { IVanFetch } from "src/models/IVan";

interface IProps {
    van: IVanFetch;
    selectedLocations: Location[]
}

const AvailabilityContainer: FC<IProps> = ({ van, selectedLocations }) => {

    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const {
        setCheckFilter,
        errorFilter,
        checkVan,
        setStartDateFilter,
        setEndDateFilter
    } = useActions();
    const { enqueueSnackbar } = useSnackbar();
    const [params, setSearchParams] = useSearchParams();
    const {
        checkFilter,
        filters,
        errors,
        isFilterValidate,
        isLoading,
        result
    } = useTypedSelector((state) => state.van);

    useEffect(() => {
        van && handleChangeFilter(params, "model", van.model);
        van && handleChangeFilter(params, "type", van.type);
        van && handleChangeFilter(params, "seats", van.seats);
        van && handleChangeFilter(params, "location", van.location);
        setSearchParams(params);
    }, [van]);

    useEffect(() => {
        validateFilter(filters, errorFilter);
    }, [filters]);

    const handleCheckVan = () => {
        if (isFilterValidate) {
            checkVan(van!.id, {
                start_date: filters.start_date!,
                end_date: filters.end_date!
            });
        } else {
            setCheckFilter();
            !isMobile && showFilterErrors(enqueueSnackbar, errors);
        }
    };

    return (
        <div className='borderBottom'>
            <div className='singleVan__availability'>
                <h3>Check availability</h3>
                <div className='singleVan__availability__formContainer'>
                    <div className='singleVan__availability__formContainer__locations'>
                        <Filters.Location
                            selectedLocations={selectedLocations}
                            check={checkFilter}
                            className={'location'}
                        />
                        <Filters.AnotherLocation
                            selectedLocations={selectedLocations}
                            check={checkFilter}
                            className={'location'}
                        />
                    </div>
                    <Filters.Times
                        className={'singleVan__availability__formContainer__times'}
                        check={checkFilter}
                        startDate={filters.start_date}
                        endDate={filters.end_date}
                        handleStartDate={setStartDateFilter}
                        handleEndDate={setEndDateFilter}
                    />
                    <Button
                        onClick={handleCheckVan}
                        variant='contained'
                        className='singleVan__availability__formContainer__button'
                    >
                        {isLoading.check ? (
                            <CircularProgress size={16} />
                        ) : ('GET THIS VAN')}
                    </Button>
                </div>
                {result.check && (
                    result.check === 200 ? (
                        <div className='singleVan__availability__van'>
                            <VanCard
                                van={van}
                                key={van.id}
                                errors={errors}
                                isFilterValidate={isFilterValidate}
                            />
                        </div>
                    ) : (
                        <div className='singleVan__availability__noVan singleVan__description'>
                            This van is not available on the selected dates. Please select other dates or another van
                            <Button
                                children='Select another van'
                                onClick={() => navigate('/')}
                                variant='contained'
                            />
                        </div>
                    )
                )}
            </div>
        </div>
    );
};
export default AvailabilityContainer;
