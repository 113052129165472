import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { ContractActionEnum } from "src/store/reducers/contract/types";
import { ContractActionCreators } from "src/store/reducers/contract/action-creators";

function* signContract({
  sign,
  id,
}: ReturnType<typeof ContractActionCreators.signContract>) {
  try {
    const { status, data } = yield instance.post(`sign/${id}`, {
      sign,
    });
    yield put(ContractActionCreators.resultSignContract(status, data));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherSignContract() {
  yield takeLatest(ContractActionEnum.SIGN_CONTRACT, signContract);
}
