import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import SmallFooter from 'src/components/Footer/SmallFooter';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import Logo from 'src/components/Items/Logo';
import './styles.scss';

const QuotePage: FC = () => {

  const navigate = useNavigate();

  return (
    <>
      <div className='quote__header'>
        <Logo />
      </div>
      <div className='quote'>
        <div className='quote__card'>
          <div className='quote__card__top'>
            <div className='quote__card__top__icon'>
              <CircleIcon
                fontSize='small'
                className='quote__card__top__icon__circleIcon'
              />
              <CheckIcon
                fontSize='small'
                className='quote__card__top__icon__checkIcon'
              />
            </div>
            <h3>Quote Sent</h3>
            <p>
              We sent you an email with the quote.<br />
              Don't let this van get away, quotes may expire.
            </p>
          </div>
          <div className='quote__card__bottom'>
            <h5>Here are the next steps:</h5>
            <div className='quote__card__bottom__item'>
              <h6>Reserve the van SOON,</h6>
              <p>
                Your van is not blocked. Please us the link in the email to reserve your van with a fully refundable down payment.
              </p>
            </div>
            <div className='quote__card__bottom__item'>
              <h6>Reserve the van NOW</h6>
              <p>
                Simply add a credit card now to hold the van (change the card any time) with a fully refundable down payment.
              </p>
            </div>
          </div>
          <div className='quote__card__buttons'>
            <SecondTypeButton
              onClick={() => navigate('/')}
              children='Get more vans / quotes'
            />
            {/* <Button
              onClick={() => { }}
              children='Go back to BOOK NOW'
            /> */}
          </div>
        </div>
      </div>
      <SmallFooter />
    </>
  );
};

export default QuotePage;
