import {
  LocationAction,
  LocationActionEnum,
  LocationState,
} from './types';

const initialState: LocationState = {
  locationList: [],
  isLoading: {
    all: false,
  },
};

const location = (
  state = initialState,
  action: LocationAction
): LocationState => {
  switch (action.type) {
    case LocationActionEnum.REQUEST_LOCATIONS:
      return { ...state, isLoading: { ...state.isLoading, all: true } };
    case LocationActionEnum.RESULT_LOCATIONS:
      return {
        ...state,
        locationList: action.data,
        isLoading: { ...state.isLoading, all: !action.data },
      };
    default:
      return state;
  }
};
export default location;
