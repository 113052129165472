import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { OrderActionEnum } from "src/store/reducers/order/types";
import { OrderActionCreators } from "src/store/reducers/order/action-creators";

function* fetchQuoteOrder({
  id,
}: ReturnType<typeof OrderActionCreators.quoteOrder>) {
  try {
    const { data } = yield instance.get(`client/getQuote/${id}`);
    yield put(OrderActionCreators.resultQuoteOrder(data));
  } catch (error: any) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherQuoteOrder() {
  yield takeLatest(OrderActionEnum.REQUEST_QUOTE_ORDER, fetchQuoteOrder);
}
