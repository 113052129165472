import { FC } from "react";

interface IProps {
  classes?: string;
  icon?: JSX.Element;
  text?: string;
}

const MiniSign: FC<IProps> = ({ classes, icon, text }) => (
  <div className={classes}>
    {icon}
    {text}
  </div>
);

export default MiniSign;
