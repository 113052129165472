import { FC } from 'react';
import './styles.scss';
import Filters from '..';
import SecondTypeButton from '../../button/SecondTypeButton';
import { Button } from '@mui/material';

interface IProps {
  handleClearFilter: () => any;
  handleSetFilter: () => any;
}

const AnotherFilter: FC<IProps> = ({ handleClearFilter, handleSetFilter }) => (
  <>
    <div className='filter-aside'>
      <Filters.Type />
      <div className='filter-line' />
      <Filters.Feature />
      <div className='filter-line' />
      <Filters.Extra />
      {/* <Filters.Color />  */}
      {/* <div className="filter-line" />
      <Filters.Price /> */}
      <div className='filter-actions'>
        <SecondTypeButton
          onClick={handleClearFilter}
          children='Clear'
          sx={{ height: 35 }}
        />
        <Button
          variant='contained'
          onClick={handleSetFilter}
          sx={{ height: 35 }}
          children='Apply'
        />
      </div>
      <div className='filter-line' />
      <Filters.Reviews />
    </div>
  </>
);
export default AnotherFilter;
