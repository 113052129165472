import { ClientAction, ClientActionEnum, ClientState } from "./types";

const initialState: ClientState = {
  isLogin: localStorage.getItem("guest-token") ? true : false,
  isError: false,
  isLoading: {
    login: false,
    signup: false,
    get: false,
    update: false,
    pay: false,
    order: false,
    check: false,
    doc: false,
    receipt: false,
    dates: false
  },
  result: {},
};

const client = (state = initialState, action: ClientAction): ClientState => {
  switch (action.type) {
    case ClientActionEnum.CHECK_TOKEN:
      return {
        ...state,
        isLoading: { ...state.isLoading, check: true },
        result: { ...state.result, check: '' }
      };
    case ClientActionEnum.RESULT_CHECK_TOKEN:
      return {
        ...state,
        isLogin: action.status === 200 ? true : false,
        isLoading: { ...state.isLoading, check: false },
        result: { ...state.result, check: action.status },
      };
    case ClientActionEnum.ERROR_LOGIN:
      return {
        ...state,
        isLogin: false,
        isError: true
      };
    case ClientActionEnum.EXIT:
      localStorage.removeItem('guest-token');
      return {
        ...state,
        isLogin: false,
        isError: false,
        result: { ...state.result, login: action.result ?? "" },
      };
    case ClientActionEnum.REQUEST_SIGNUP:
      return {
        ...state,
        isError: false,
        isLoading: { ...state.isLoading, signup: true },
        result: { ...state.result, signup: '' }
      };
    case ClientActionEnum.RESULT_SIGNUP:
      return {
        ...state,
        isLoading: { ...state.isLoading, signup: false },
        result: { ...state.result, signup: action.status },
      };
    case ClientActionEnum.REQUEST_CLIENT:
      return {
        ...state,
        isLoading: { ...state.isLoading, get: true },
        result: { ...state.result, get: '' }
      };
    case ClientActionEnum.RESULT_CLIENT:
      return {
        ...state,
        isLoading: { ...state.isLoading, get: false },
        result: { ...state.result, get: action.status },
        client: action.data.client,
        orderList: action.data.orderList
      };
    case ClientActionEnum.REQUEST_UPDATE_CLIENT:
      return {
        ...state,
        isLoading: { ...state.isLoading, update: true },
        result: { ...state.result, update: '' }
      };
    case ClientActionEnum.RESULT_UPDATE_CLIENT:
      return {
        ...state,
        isLoading: { ...state.isLoading, update: false },
        result: { ...state.result, update: action.status },
      };
    case ClientActionEnum.REQUEST_UPDATE_ORDER:
      return {
        ...state,
        isLoading: { ...state.isLoading, order: true },
        result: { ...state.result, order: '' }
      };
    case ClientActionEnum.RESULT_UPDATE_ORDER:
      return {
        ...state,
        isLoading: { ...state.isLoading, order: false },
        result: { ...state.result, order: action.status },
      };
    case ClientActionEnum.REQUEST_CLIENT_PAY:
      return {
        ...state,
        isLoading: { ...state.isLoading, pay: true },
        result: { ...state.result, pay: '' }
      };
    case ClientActionEnum.RESULT_CLIENT_PAY:
      return {
        ...state,
        isLoading: { ...state.isLoading, pay: false },
        result: { ...state.result, pay: action.data },
      };
    case ClientActionEnum.CLEAR_CLIENT_RESULT:
      return { ...state, isError: false, result: {} };

    case ClientActionEnum.REQUEST_SHOW_CONTRACT:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          doc: true,
        },
      };

    case ClientActionEnum.RESULT_SHOW_CONTRACT:
      return {
        ...state,
        isLoading: { ...state.isLoading, doc: false },
        result: { ...state.result, doc: action.data }
      };

    case ClientActionEnum.REQUEST_SHOW_LICENSE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          doc: true,
        },
      };

    case ClientActionEnum.RESULT_SHOW_LICENSE:
      return {
        ...state,
        isLoading: { ...state.isLoading, doc: false },
        result: { ...state.result, doc: action.data }
      };

    case ClientActionEnum.REQUEST_SHOW_INSURANCE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          doc: true,
        },
      };

    case ClientActionEnum.RESULT_SHOW_INSURANCE:
      return {
        ...state,
        isLoading: { ...state.isLoading, doc: false },
        result: { ...state.result, doc: action.data }
      };

    case ClientActionEnum.REQUEST_SEND_RECEIPT:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          receipt: true,
        },
      };

    case ClientActionEnum.RESULT_SEND_RECEIPT:
      return {
        ...state,
        isLoading: { ...state.isLoading, receipt: false },
        result: { ...state.result, receipt: action.data }
      };

    case ClientActionEnum.REQUEST_CHANGE_DATES:
      return {
        ...state,
        isLoading: { ...state.isLoading, dates: true },
        result: {}
      };
    case ClientActionEnum.RESULT_CHANGE_DATES:

      if (action.data) {
        const index = state.orderList!.findIndex(
          el => el.id === action.data!.id
        );
        state.orderList![index] = action.data!;
      }

      return {
        ...state,
        isLoading: { ...state.isLoading, dates: false },
        result: { ...state.result, dates: action.status },
      };

    default:
      return state;
  }
};
export default client;
