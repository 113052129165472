import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useClipboard } from 'use-clipboard-copy';
import { useSnackbar } from 'notistack';
import { Backdrop, CircularProgress, Modal, Slide, Tooltip } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IosShareIcon from '@mui/icons-material/IosShare';
import AvailableLocations from "../../../singleVanPage/AvailableLocations";
import PhotosContainer from "../../../singleVanPage/PhotosContainer";
import DetailsContainer from "../../../singleVanPage/DetailsContainer";
import ShareModal from "src/components/Items/ShareModal";
import Map from "../../../singleVanPage/Map/index";
import VanModalFooter from "./VanModalFooter";
import { useActions } from "src/hooks/useActions";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import { Location } from '../../../../../store/reducers/location/types';
import { IVanFetch } from "src/models/IVan";
import './styles.scss';

interface IProps {
    open: boolean;
    handleClose: () => void;
    van: IVanFetch;
    isOpenShare?: boolean
}

const SingleVanModal: FC<IProps> = ({ open, handleClose, van, isOpenShare }) => {

    const clipboard = useClipboard();
    const { enqueueSnackbar } = useSnackbar();
    const { getExtras } = useActions();
    const locations = useTypedSelector((state) => state.location.locationList);
    const [selectedLocations, setSelectedLocations] = useState<Location[]>();
    const [openShare, setOpenShare] = useState(false);

    useEffect(() => {
        getExtras();
    }, []);

    useEffect(() => {
        isOpenShare && setOpenShare(true);
    }, [isOpenShare]);

    useEffect(() => {
        setSelectedLocations(
            locations.filter((l) => {
                return van?.tags.some((t) => t.name === l.name);
            })
        );
    }, [van, locations]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            BackdropComponent={Backdrop}
            closeAfterTransition
        >
            <Slide direction="right" in={open} mountOnEnter unmountOnExit>
                <div className="right-float-modal modal">
                    {van && selectedLocations ? (
                        <div className='vanModal'>
                            <div className='vanModal__top-panel'>
                                <ArrowBackIosIcon onClick={handleClose} />
                                <div className='vanModal__top-panel__container'>
                                    <Tooltip title='Share van link'>
                                        <IosShareIcon onClick={() => setOpenShare(true)} />
                                    </Tooltip>
                                    <Link to={`/van/143${van.id}`} target="_blank">
                                        Open in a new window
                                        <OpenInNewIcon />
                                    </Link>
                                    <ShareModal
                                        id={van.id}
                                        open={openShare}
                                        onClose={() => setOpenShare(false)}
                                    />
                                </div>
                            </div>
                            <div className='vanModal__title-container'>
                                <div>
                                    <div className='vanModal__title'>
                                        {van?.mark}
                                    </div>
                                    <AvailableLocations
                                        location={van.location}
                                        tags={van.tags}
                                        className={'vanModal__title-container__description'}
                                    />
                                </div>
                            </div>
                            <PhotosContainer
                                interior={van?.photos.interior}
                                exterior={van?.photos.exterior}
                                className={'vanModal'}
                            />
                            <div className='vanModal__main-info borderBottom'>
                                <h2><b>Make: </b>{van.make ? van.make : 'Mercedes Benz Sprinter'}</h2>
                                <h3>{`${van?.seats} seats | ${van?.cargo_space} cargo space | Year Made: ${van?.year}`}</h3>
                            </div>
                            <div className='vanModal__description borderBottom'>
                                <p>{van?.description}</p>
                            </div>
                            <DetailsContainer features={van.features} className={'vanModal'} />
                            <div className='vanModal__map'>
                                <h2 style={{ fontWeight: 700, marginBottom: '20px' }}>Location</h2>
                                <Map address={van.location} />
                                <div className='vanModal__description vanModal__map__description'>
                                    <b>We delivery anywhere, here are just a few pick up points for this van or similar vans. </b><br />
                                    <AvailableLocations
                                        location={van?.location}
                                        tags={van?.tags}
                                        style={{ background: '#fff', marginTop: '10px' }}
                                        className={'vanModal__title-container__description'}
                                    />
                                </div>
                            </div>
                            <VanModalFooter van={van} />
                        </div>
                    ) : (
                        <CircularProgress
                            size={100}
                            sx={{ position: 'absolute', top: '50%', left: '50%' }}
                        />
                    )}
                </div>
            </Slide>
        </Modal>
    );
};
export default SingleVanModal;
