import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { LinearProgress } from '@mui/material';
import Painter from 'src/components/Items/Painter';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import './styles.scss';

const ContractPage: FC = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { getContract, signContract } = useActions();
  const {
    contract: { contract, result, isLoading, error },
    client: { isLogin }
  } = useTypedSelector((state) => state);
  const [sign, setSign] = useState('');
  const [check, setCheck] = useState(false);

  useEffect(() => {
    sign && setCheck(false);
    error &&
      enqueueSnackbar('The contract has already been signed', {
        variant: 'info',
      });
    result &&
      enqueueSnackbar('Contract has been signed', {
        variant: 'success',
      });
    (result || error) && (
      isLogin
        ? navigate('/client')
        : navigate('/signed')
    );
  }, [sign, result, error]);

  useEffect(() => {
    id && getContract(id);
  }, [id]);

  const [url, setUrl] = useState('');
  useEffect(() => {
    if (contract) {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(contract.data).buffer], {
          type: 'application/pdf',
        })
      );

      setUrl(`${url}`);
    }
  }, [contract]);

  const handleSign = () => {
    if (!sign) {
      setCheck(true);
    } else {
      enqueueSnackbar('Waiting for ending of signing...', {
        variant: 'info',
      });
      id && signContract(sign, id);
    }
  };

  return !isLoading.get ? (
    <div className='contract'>
      <div className='contract__frame'>
        <iframe src={url} />
      </div>
      <Painter
        data={sign}
        setDate={setSign}
        check={check}
        onSubmit={handleSign}
        disabled={isLoading.sign}
      />
    </div>
  ) : (
    <LinearProgress />
  );
};

export default ContractPage;
