import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime, Interval } from 'luxon';
import { Button, Popover, Tooltip } from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import DriverItem from '../../DriverItem';
import DeleteModal from 'src/components/Items/DeleteModal';
import ShareItems from 'src/components/Items/ShareModal/Items';
import { useActions } from 'src/hooks/useActions';
import { IDriverFetch } from 'src/models/IDriver';
import { IOrderForClient } from 'src/models/IOrder';
import Icons from 'src/common/icons';
import './styles.scss';

interface IProps {
  vanId: number;
  order: IOrderForClient
}

const DriversCard: FC<IProps> = ({ vanId, order }) => {

  const {
    id,
    signRaHash,
    inviteDriverHash,
    pdf_order,
    start_date,
    additional_driver
  } = order;

  const navigate = useNavigate();
  const { showContract } = useActions();
  const [openDriver, setOpenDriver] = useState(false);
  const [openDocs, setOpenDocs] = useState(false);
  const [drivers, setDrivers] = useState<IDriverFetch[] | null>();
  const [selectedDriver, setSelectedDriver] = useState<number>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const popover = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleDeleteDriver = (driverId: number) => {
    const newDriverList = additional_driver.filter(
      (driver) => driver.id !== driverId
    );
    setDrivers(newDriverList);
    setOpenDriver(true);
  };

  const handleDeleteDocs = (id: number) => {
    setSelectedDriver(id);
    setOpenDocs(true);
  };

  return (
    <>
      <div className='drivers-card'>
        <div className='drivers-card__contract'>
          <div className='drivers-card__contract__title'>
            <div className='drivers-card__contract__title__container'>
              <h4>Your Contract</h4>
              {order.pdf_order && (
                <Tooltip title='Signed contract'>
                  {order.pdf_order.startsWith('https://') ? (
                    <a
                      href={order.pdf_order}
                      target='_blank'
                      className='icon_stroke'
                    >
                      <Icons.LilPic />
                    </a>
                  ) : (
                    <div
                      className='icon_stroke'
                      onClick={() => showContract(order.pdf_order)}
                    >
                      <Icons.LilPic />
                    </div>
                  )}
                </Tooltip>
              )}
            </div>
            <h6>Due later: Sign 48h before your trip</h6>
          </div>
          <Button
            children='E-SIGN AGREEMENT'
            onClick={() => navigate(`/contract/${signRaHash}`)}
            disabled={
              (pdf_order ||
                Interval.fromDateTimes(
                  DateTime.now(),
                  DateTime.fromISO(start_date)
                ).toDuration(["days"]).days > 2
              ) ? true : false
            }
          />
        </div>
        <div className='drivers-card__container'>
          <div className='drivers-card__header'>
            <h4>Additional Drivers</h4>
            <span>(optional)</span>
          </div>
          <div className='drivers-card__columns'>
            <div className='drivers-card__column'>
              <div className='drivers-card__column__title'>Invited</div>
              {additional_driver.map((driver, i) => (
                <div
                  key={i}
                  className='drivers-card__column__data'
                >
                  <h6>
                    {`${driver.first_name} ${driver.last_name}`}
                  </h6>
                </div>
              ))}
            </div>
            <div className='drivers-card__column'>
              <div className='drivers-card__column__title'>
                Documents
              </div>
              {additional_driver.map((driver) => (
                <DriverItem
                  driver={driver}
                  handleOpenDelete={() => handleDeleteDocs(driver.id)}
                />
              ))}
            </div>
            <div className='drivers-card__column actions'>
              {additional_driver.map((driver, i) => (
                <div key={i} className='drivers-card__column__data'>
                  <div className='drivers-card__column__data__action'>
                    {/* {isLoading.email ? (
                      <CircularProgress size={15} />
                    ) : ( */}
                    <Tooltip title='Sent email'>
                      <AlternateEmailIcon
                      // onClick={handleSentNotification(
                      //   sentEmail,
                      //   'Upload Docs/DL' as keyof OrderNotificationToGuestAutomaticallyEnum,
                      //   driver.id
                      // )}
                      />
                    </Tooltip>
                    {/* )} */}
                    <Tooltip title='Delete driver'>
                      <div
                        onClick={() => handleDeleteDriver(driver.id)}
                      >
                        <Icons.OutlinedDelete />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='drivers-card__invite'>
          <Button
            aria-describedby={popover}
            children='+ Invite Additional Drivers, it’s free'
            onClick={handleClick}
          />
          <Popover
            id={popover}
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <ShareItems hash={inviteDriverHash} />
          </Popover>
        </div>
      </div>
      <DeleteModal
        id={selectedDriver!}
        type='additional driver license'
        open={openDocs}
        handleClose={() => setOpenDocs(false)}
      />
      <DeleteModal
        id={id}
        type='driver'
        drivers={drivers!}
        open={openDriver}
        handleClose={() => setOpenDriver(false)}
      />
    </>
  );
};

export default DriversCard;
