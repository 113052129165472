import { FC } from "react";
import { FormControl, Select, MenuItem, createTheme, ThemeProvider } from "@mui/material";
import { deepmerge } from "@mui/utils";
import theme, { colorSet, typeForm } from "src/theme";

interface ISelect {
  check?: boolean;
  data?: string | number;
  setData: (event: any) => void;
  title?: string;
  dataArrey: string[] | number[];
  style?: {};
  placeholder?: string;
  notShowSelect?: boolean;
}

const themeMultiSelect = createTheme(
  deepmerge(theme, {
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            maxHeight: '260px !important',
          },
        },
      },
    },
  })
);

const SimpleSelect: FC<ISelect> = ({
  data,
  setData,
  dataArrey,
  check,
  title,
  style,
  placeholder = title,
  notShowSelect,
}) => {

  return (
    <ThemeProvider theme={themeMultiSelect}>
      {title && <div className="filter__title">{title}</div>}
      <FormControl sx={style}>
        <Select
          variant={typeForm}
          displayEmpty
          value={data ?? ""}
          onChange={(e) => setData(e.target.value)}
          renderValue={(n) =>
            !n || !data ? (
              check ? (
                <div style={{ color: colorSet.primaryRed }}>
                  Must be selected
                </div>
              ) : (
                <div style={{ color: 'rgb(160 158 158)' }}>
                  {!notShowSelect && "Select"} {placeholder}
                </div>
              )
            ) : (
              n
            )
          }
          title={`${data!}`}
        >
          {dataArrey.map((d, i) => (
            <MenuItem key={i} value={d}>
              {d}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};

export default SimpleSelect;
