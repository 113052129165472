import AdditionalDriverForm from './Form';
import './styles.scss';

const AdditionalDriverPage = () => {
  return (
    <div className='driver'>
      <div className='driver__wrapper'>
        <div className='driver__title'>Invite additional driver</div>
        <AdditionalDriverForm />
      </div>
    </div>
  );
};
export default AdditionalDriverPage;
