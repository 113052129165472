import feature from './feature';
import order from './order';
import cancelOrder from './cancelOrder';
import van from './van';
import extra from './extra';
import contract from './contract';
import location from './location';
import driver from './driver';
import global from './global';
import oneway from './oneway';
import driverLicense from './driverLicense';
import seasonPrice from './seasonPrice';
import subPrice from './subPrice';
import subLocation from './subLocation';
import client from './client';

const reducers = {
  van,
  feature,
  order,
  cancelOrder,
  extra,
  location,
  contract,
  driverLicense,
  global,
  oneway,
  driver,
  seasonPrice,
  subLocation,
  subPrice,
  client
};
export default reducers;
