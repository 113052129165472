import { FC, useEffect, useState } from "react";
import CustomSwiper from "src/components/Items/CustomSwiper";
import NoImages from "src/components/Items/NoImages";
import GalleryModal from "../../components/Modal/Gallery";


interface IProps {
    interior: string[];
    exterior: string[];
    className: string;
}

const PhotosContainer: FC<IProps> = ({ interior, exterior, className }) => {

    const [interiorPhotos, setInteriorPhotos] = useState<string[]>();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const photos = [] as string[];
        interior.map((photo) => {
            photo !== 'https://together-media-upload.s3.amazonaws.com/vans/cargo-sprinter-van-seating-layout.jpg' &&
                photo !== 'https://together-media-upload.s3.amazonaws.com/vans/1.png' &&
                photo !== 'https://together-media-upload.s3.amazonaws.com/vans/Cargo%20Sprinter-interior-space-diagram.png' &&
                photos.push(photo);
        });
        setInteriorPhotos(photos);
    }, [interior]);

    return (
        <>
            <div className={`${className}__imgs`}>
                <div className={`${className}__imgs__bigImg`}>
                    {exterior.length || interior.length ? (
                        <>
                            <CustomSwiper
                                data={[...exterior, ...interior]}
                            />
                            <div
                                onClick={() => setOpen(true)}
                                className={`${className}__imgs__bigImg__open-gallery`}
                            />
                        </>
                    ) : (
                        <NoImages />
                    )}
                </div>
                <div className={`${className}__imgs__smallImgs`}>
                    {interiorPhotos && interiorPhotos.slice(0, 2).map((photo, index) => (
                        <img
                            key={index}
                            src={photo}
                            alt='inner'
                            loading='lazy'
                            onClick={() => setOpen(true)}
                        />
                    ))}
                    {interiorPhotos?.length === 1 &&
                        <img
                            src={exterior[1]}
                            alt='inner'
                            loading='lazy'
                            onClick={() => setOpen(true)}
                        />
                    }
                    {/* <img
                    src={require('src/common/img/tempVanPhoto_1.jpg')}
                    alt='inner'
                    loading='lazy'
                />
                <img
                    src={require('src/common/img/tempVanPhoto_2.jpg')}
                    alt='inner'
                    loading='lazy'
                /> */}
                </div>
            </div>
            <GalleryModal
                open={open}
                handleClose={() => setOpen(false)}
                imgs={[...exterior, ...interior]}
                index={0}
            />
        </>
    );
};

export default PhotosContainer;
