import { FC } from 'react';
import Logo from 'src/components/Items/Logo';
import './styles.scss';
import './media.scss';

interface IProps { }

const SmallFooter: FC<IProps> = () => {

  const endArray = {
    FAQ: 'https://www.sprinter-rentals.com/faq',
    'Terms of Service': 'https://www.sprinter-rentals.com/tos',
    'Privacy Policy': 'https://www.sprinter-rentals.com/privacy',
  };

  return (
    <div className='small-footer'>
      <h6>© 2024 Sprinter Rentals Inc.</h6>
      <Logo isSmall />
      <div className='small-footer__items'>
        {Object.keys(endArray).map((sec) => (
          <a
            href={endArray[sec as keyof typeof endArray]}
            key={sec}
            target='_blank'
          >
            {sec}
          </a>
        ))}
      </div>
    </div>
  );
};

export default SmallFooter;
