import { IVanTag } from "src/models/IVan";
import { SubLocation } from "../subLocation/types";

export enum LocationActionEnum {
  REQUEST_LOCATIONS = 'REQUEST_LOCATIONS',
  RESULT_LOCATIONS = 'RESULT_LOCATIONS',
  ERROR_LOCATIONS = 'ERROR_LOCATIONS',
}

export interface Location {
  id: number;
  name: string;
  description: string;
  country: string;
  review_link?: string;
  sub_location: SubLocation[];
  tags?: IVanTag[];
}

export interface LocationState {
  locationList: Location[];
  isLoading: {
    all: boolean;
  };
}

export interface RequestLocationAction {
  type: LocationActionEnum.REQUEST_LOCATIONS;
}

export interface ResultLocationAction {
  type: LocationActionEnum.RESULT_LOCATIONS;
  data: Location[];
}

export interface ErrorLocationAction {
  type: LocationActionEnum.ERROR_LOCATIONS;
  payload?: any;
}

export type LocationAction =
  | RequestLocationAction
  | ResultLocationAction
  | ErrorLocationAction;
