import { FC, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button } from '@mui/material';
import { fromISOtoFormat } from 'src/common/utils/dataFormat';
import './styles.scss';

interface IProps {
  startDate?: string;
}

const CheckoutForm: FC<IProps> = ({ startDate }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const nowTime = DateTime.now().toFormat('D');

  useEffect(() => {
    startDate && fromISOtoFormat(startDate, 'D') === nowTime && (
      enqueueSnackbar(
        'We are working to get your van ready. We will contact you soon. Please check your email to contact your host in case you do not hear from us within an hour.',
        {
          variant: 'success',
          persist: true,
        },
      ),
      setTimeout(() => closeSnackbar(), 10000)
    );
  }, [startDate]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return enqueueSnackbar('Something go wrong', {
        variant: 'error',
      });
    }
    await stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: `https://${window.location.host}/success/payment`,
        },
      })
      .then((response) =>
        !response.error
          ? enqueueSnackbar(
            'Payment success,we will email you about next steps',
            {
              variant: 'success',
            }
          )
          : enqueueSnackbar(response.error.message, {
            variant: 'error',
          })
      );
    setTimeout(() => closeSnackbar(), 2000);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        fullWidth
        sx={{ mt: 2 }}
        type='submit'
        children={startDate ? 'Reserve van' : 'Hold pay'}
        disabled={!stripe || elements === null}
      />
    </form>
  );
};

export default CheckoutForm;
