import { put, takeLatest } from 'redux-saga/effects';
//@ts-ignore
import { DriverLicenseActionCreators } from 'src/store/reducers/driverLicense/action-creators';
import { DriverLicenseActionEnum } from 'src/store/reducers/driverLicense/types';
import instance from '../set';

// window.Buffer = window.Buffer || require('buffer').Buffer;
export function* fetchAddDriverLicenseImages({
  images,
  insurance,
  license,
}: ReturnType<typeof DriverLicenseActionCreators.addDriverLicenseImage>) {
  const formData = new FormData();
  images.map((file) => formData.append('images', file));
  formData.append('insurance', insurance);
  try {
    const {
      data: { id },
    } = yield instance.post('license', { license });
    const { data } = yield instance.post(
      `license/${id}`,

      formData,

      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    const { status } = yield instance.patch(`license/${id}`, {
      license: data,
    });
    yield put(DriverLicenseActionCreators.resultSetDriverLicense(status));
  } catch (e) {
    console.log(e);
  }
}
export function* actionWatcherSetDriverLicense() {
  yield takeLatest(
    DriverLicenseActionEnum.ADD_DRIVER_LICENSE_IMAGES,
    fetchAddDriverLicenseImages
  );
}
