import { FeatureAction, FeatureActionEnum, FeatureState } from "./types";

const initialState: FeatureState = {
  featureList: [],
  isLoading: {
    all: false,
  },
};

const feature = (state = initialState, action: FeatureAction): FeatureState => {
  switch (action.type) {
    case FeatureActionEnum.REQUEST_FEATURES:
      return { ...state, isLoading: { ...state.isLoading, all: true } };
    case FeatureActionEnum.RESULT_FEATURES:
      return {
        ...state,
        featureList: action.data,
        isLoading: { ...state.isLoading, all: !action.data },
      };
    default:
      return state;
  }
};
export default feature;
