import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { VanActionCreators } from "src/store/reducers/van/action-creators";
import { VanFilterActionEnum } from "src/store/reducers/van/types";
import { IVanFilter } from "src/models/IVanFilter";

function* fetchGetVans({
  filters,
}: ReturnType<typeof VanActionCreators.setupFilters>) {

  Object.keys(filters).forEach((f) => {
    if (!filters[f as keyof IVanFilter]) delete filters[f as keyof IVanFilter];
  });
  !filters.features?.length && delete filters.features;

  const tags = filters.tags || [];
  if (filters.location) {
    tags.push(filters.location);
    // delete filters.location;
  }
  if (filters.end_location) {
    tags.push(filters.end_location);
    delete filters.end_location;
  }
  if (filters.sub_location) {
    tags.push(filters.sub_location);
    delete filters.sub_location;
  }
  if (filters.region) {
    filters.region.map((region) => tags.push(region));
    delete filters.region;
  }
  if (tags.length) filters.tags = tags;

  try {
    const {
      data: { vanList, count, minPrice, maxPrice },
    } = yield instance.post(`van`, {
      filters,
    });
    yield put(
      VanActionCreators.resultGetVans(vanList, count, minPrice, maxPrice)
    );
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetVans() {
  yield takeLatest(VanFilterActionEnum.SETUP_FILTERS, fetchGetVans);
}
