import Dinero, { Currency } from 'dinero.js';
import { DateTime } from 'luxon';
import Decimal from 'decimal.js';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// const fromFormat = (data: string, format: string) =>
//   fromFormat(data, format, {
//     zone: timezone,
//   });

const fromISOtoFormat = (date: string, format: string) =>
  DateTime.fromISO(date).toFormat(format);

const changeISOset = (
  date: string,
  object: {
    hour?: number;
    minute?: number;
    second?: number;
  }
) => DateTime.fromISO(date).set(object).toISO();

const fromJSDateToISO = (date: any) =>
  DateTime.fromJSDate(new Date(date)).toISO();

const dineroFormat = (amount: number, currency: Currency) =>
  Dinero({ amount, currency }).toFormat('$0,0.00');

const dineroGetPercentage = (sum: number, percentage: number): number => {
  const res = Dinero({ amount: sum }).percentage(percentage).toObject();
  return res.amount;
};
const dineroGetSum = (first: number, second: number): number => {
  return Dinero({ amount: first })
    .add(Dinero({ amount: second }))
    .getAmount();
};
const dineroSubtract = (first: number, second: number): number => {
  return Dinero({ amount: first })
    .subtract(Dinero({ amount: second }))
    .getAmount();
};
const dineroSubtractAmount = (
  firstAmount: number,
  secondAmount: number,
  currency: Currency
) => {
  return Dinero({ amount: firstAmount, currency })
    .subtract(Dinero({ amount: secondAmount, currency }))
    .getAmount();
};

const dineroMultiply = (
  firstAmount: number,
  secondAmount: number
) => {
  return Dinero({ amount: firstAmount })
    .multiply(secondAmount).getAmount();
};

const dineroAdd = (
  firstAmount: number,
  secondAmount: number,
  currency: Currency
) => {
  return Dinero({ amount: firstAmount, currency })
    .add(Dinero({ amount: secondAmount, currency })).getAmount();
};

const dineroSubtractPrice = (
  firstAmount: number,
  secondAmount: number,
  currency: Currency
) => {
  return Dinero({ amount: firstAmount, currency })
    .subtract(Dinero({ amount: secondAmount, currency }))
    .toFormat('$0,0.00');
};

const dineroDivide = (
  firstAmount: number,
  secondAmount: number
) => {
  return Dinero({ amount: firstAmount })
    .divide(secondAmount).getAmount();
};

const rounder = (value: number) => {
  return Decimal.set({ rounding: 4 }).round(value).toNumber();
};
export {
  // fromFormat,
  fromISOtoFormat,
  changeISOset,
  fromJSDateToISO,
  timezone,
  dineroFormat,
  dineroGetPercentage as dineroGetPercentage,
  dineroGetSum,
  rounder,
  dineroSubtract,
  dineroMultiply,
  dineroAdd,
  dineroDivide,
  dineroSubtractAmount,
  dineroSubtractPrice
};
