import { FC, useEffect, useState } from 'react';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { getNames } from 'src/common/utils/others';

const Sublocations: FC = () => {

  const {
    location: { locationList },
    subLocation: { sublocationList, isLoading },
    van: { filters },
  } = useTypedSelector((state) => state);
  const { setLocationFilter, setRegionFilter } = useActions();
  const [tags, setTags] = useState<string[]>([]);
  const [mainLocation, setMainLocation] = useState('');

  useEffect(() => {
    if (filters.location && !isLoading.all) {
      const location = locationList.find((el) => el.name === filters.location);
      if (location) {
        setMainLocation(location.name);
        const subs = location.sub_location.map((sub) => sub.name);
        subs.length ? setTags([location.name, ...subs]) : setTags([]);
      } else if (locationList.length && sublocationList.length) {
        const sub = sublocationList.filter((el) => el.name === filters.location);
        const location = locationList.filter((el) => el.name === sub[0].main_location_name);
        setMainLocation(location[0].name);
        const subs = location[0].sub_location.map((sub) => sub.name);
        subs.length ? setTags([location[0].name, ...subs]) : setTags([]);
      }
      if (location?.tags?.length) {
        const regions = location?.tags.filter((tag) => tag.category === 'region');
        setRegionFilter(getNames(regions));
      } else setRegionFilter([]);
    }
  }, [filters.location, locationList, sublocationList]);

  return (
    <>
      {tags.length ? (
        <div className='filter-sub'>
          {tags.map((sub) => (
            <div
              className={
                `sub-locations__item ${(
                  (filters.location === sub && !filters.sub_location) ||
                  filters.sub_location === sub) && 'filter-sub__selected'
                }`
              }
              style={{ cursor: 'pointer' }}
              onClick={() => setLocationFilter(
                mainLocation, sub === mainLocation ? '' : sub
              )}
            >
              {sub === filters.location ? 'All' : sub}
            </div>
          ))}
        </div>
      ) : <></>}
    </>
  );
};
export default Sublocations;
