import { Oneway } from 'src/models/types';

export enum OnewayActionEnum {
  REQUEST_ONEWAY = 'REQUEST_ONEWAY',
  RESULT_ONEWAY = 'RESULT_ONEWAY',
  ERROR_ONEWAY = 'ERROR_ONEWAY',
}

export interface OnewayState {
  onewayList: Oneway[];
  isLoading: {
    all: boolean;
  };
}

export interface RequestOnewayAction {
  type: OnewayActionEnum.REQUEST_ONEWAY;
}

export interface ResultOnewayAction {
  type: OnewayActionEnum.RESULT_ONEWAY;
  data: Oneway[];
}

export interface ErrorOnewayAction {
  type: OnewayActionEnum.ERROR_ONEWAY;
  payload?: any;
}

export type OnewayAction =
  | RequestOnewayAction
  | ResultOnewayAction
  | ErrorOnewayAction;
