export interface IDriver {
  first_name: string;
  last_name?: string;
  email: string;
}
export class Driver implements IDriver {
  email = '';
  first_name = '';
  client_id = 0;
  last_name = '';
  phone = '';
  constructor() { }
}
export interface IDriverLicense {
  id: string;
  photos?: File[] | string[];
  insurance?: File | string;
  issued: string;
  address: string;
  number: string;
  birthday?: Date;
  expiration_date?: Date;
  s3_keys: string[]
}
export interface IDriverFetch extends IDriver {
  readonly id: number;
  client_id: number;
  driver_license_id: number;
  driver_license?: IDriverLicense;
  order: [];
  created_at: Date;
  updated_at: Date;
}
