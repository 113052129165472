import { FC } from 'react';
import { useSnackbar } from 'notistack';
import { Backdrop, Button, Modal } from '@mui/material';
import SecondTypeButton from '../button/SecondTypeButton';
import { useActions } from 'src/hooks/useActions';
import { IDriverFetch } from 'src/models/IDriver';
import Icons from 'src/common/icons';
import './styles.scss';
import './media.scss';

const DeleteModal: FC<{
  id: number;
  type: string;
  open: boolean;
  handleClose: () => void;
  drivers?: IDriverFetch[]
}> = ({ id, type, handleClose, open, drivers }) => {

  const { enqueueSnackbar } = useSnackbar();
  const { updateOrder, updateClient } = useActions();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <>
        <div className='modal-delete'>
          <div className='modal-delete__container'>
            <div className='modal-delete__icon'>
              <Icons.BigTrashBin />
            </div>
            <div className='modal-delete__text'>
              {`Are you sure you want to delete this ${type}?`}
            </div>
          </div>
          <div className='col-2 modal-delete__buttons'>
            <SecondTypeButton
              children='Cancel'
              onClick={() => handleClose()}
            />
            <Button
              children='Delete'
              variant='contained'
              onClick={() => {
                enqueueSnackbar(`Deleting ${type}...`);
                type === 'driver' && updateOrder({ id: id, additional_driver: drivers });
                (type === 'documents')
                  && updateClient({ id: id, driver_license_id: null });
                handleClose();
              }}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};
export default DeleteModal;
