import { IClient } from "src/models/IOrder";

export enum ContractActionEnum {
  REQUEST_CONTRACT = "REQUEST_CONTRACT",
  RESULT_CONTRACT = "RESULT_CONTRACT",
  ERROR_CONTRACT = "ERROR_CONTRACT",

  SIGN_CONTRACT = "SIGN_CONTRACT",
  RESULT_SIGN_CONTRACT = "RESULT_SIGN_CONTRACT",
  ERROR_SIGN_CONTRACT = "ERROR_SIGN_CONTRACT",
}

export interface ContractState {
  contract?: any;
  isLoading: {
    get: boolean;
    sign: boolean;
  };
  result?: string | number;
  error?: string;
}

export interface RequestContractAction {
  type: ContractActionEnum.REQUEST_CONTRACT;
  id: string;
}

export interface ResultContractAction {
  type: ContractActionEnum.RESULT_CONTRACT;
  data: any;
}
export interface ErrorContractAction {
  type: ContractActionEnum.ERROR_CONTRACT;
  statusText: string;
}
export interface SignContractAction {
  type: ContractActionEnum.SIGN_CONTRACT;
  sign: any;
  id: string;
}
export interface ResultSignContractAction {
  type: ContractActionEnum.RESULT_SIGN_CONTRACT;
  status: string | number;
  data: {id: number, host: IClient}
}
export interface ErrorSignContractAction {
  type: ContractActionEnum.ERROR_SIGN_CONTRACT;
  statusText: string;
}

export type ContractAction =
  | RequestContractAction
  | ResultContractAction
  | ErrorContractAction
  | SignContractAction
  | ResultSignContractAction
  | ErrorSignContractAction;
