import { IVanFetch } from 'src/models/IVan';
import { IVanFilter } from 'src/models/IVanFilter';
import {
  VanFilterFeatureAction,
  VanFilterTypeAction,
  GetVanAction,
  GetVansAction,
  ResultGetVanAction,
  ResultGetVansAction,
  VanActionEnum,
  VanFilterActionEnum,
  VanFilterSeatsAction,
  VanFilterStartPriceAction,
  VanFilterEndPriceAction,
  VanFilterLocationAction,
  VanFilterStartDateAction,
  VanFilterEndDateAction,
  ClearVanFilterAction,
  SetupFilters,
  ErrorVanFilterAction,
  ClearErrorVanFilterAction,
  SetCheckFilter,
  VanFilterEndLocationAction,
  GetHostVansAction,
  ResultGetHostVansAction,
  CheckVanAction,
  ResultCheckVanAction,
  VanFilterRegionAction,
} from './types';

export const VanActionCreators = {
  setupFilters: (filters: IVanFilter): SetupFilters => ({
    type: VanFilterActionEnum.SETUP_FILTERS,
    filters,
  }),

  getVans: (filter: IVanFilter): GetVansAction => ({
    type: VanActionEnum.REQUEST_VANS,
    filter,
  }),
  resultGetVans: (
    vanList: { [key: string]: IVanFetch[] } | IVanFetch[],
    count: number,
    minPrice: number,
    maxPrice: number
  ): ResultGetVansAction => ({
    type: VanActionEnum.RESULT_VANS,
    vanList,
    count,
    minPrice,
    maxPrice,
  }),
  getVan: (id: number): GetVanAction => ({
    type: VanActionEnum.REQUEST_VAN,
    id,
  }),
  resultGetVan: (data: IVanFetch): ResultGetVanAction => ({
    type: VanActionEnum.RESULT_VAN,
    data,
  }),
  checkVan: (id: number, period: { start_date: string, end_date: string }): CheckVanAction => ({
    type: VanActionEnum.REQUEST_CHECK_VAN,
    id,
    period
  }),
  resultCheckVan: (data: IVanFetch, status: string | number): ResultCheckVanAction => ({
    type: VanActionEnum.RESULT_CHECK_VAN,
    data,
    status
  }),
  getHostVans: (host: string): GetHostVansAction => ({
    type: VanActionEnum.REQUEST_HOST_VANS,
    host,
  }),
  resultGetHostVans: (vanList: { [key: string]: IVanFetch[] } | IVanFetch[]): ResultGetHostVansAction => ({
    type: VanActionEnum.RESULT_HOST_VANS,
    vanList,
  }),
};
export const VanFilterActionCreators = {
  setFeatureFilter: (features: string[]): VanFilterFeatureAction => ({
    type: VanFilterActionEnum.FEATURES,
    features,
  }),
  setTypeFilter: (vanType: string): VanFilterTypeAction => ({
    type: VanFilterActionEnum.TYPE,
    vanType,
  }),
  setSeatFilter: (seat?: number): VanFilterSeatsAction => ({
    type: VanFilterActionEnum.SEATS,
    seat,
  }),
  setStartPriceFilter: (startPrice: number): VanFilterStartPriceAction => ({
    type: VanFilterActionEnum.START_PRICE,
    startPrice,
  }),
  setEndPriceFilter: (endPrice: number): VanFilterEndPriceAction => ({
    type: VanFilterActionEnum.END_PRICE,
    endPrice,
  }),
  setLocationFilter: (location: string, sub_location: string): VanFilterLocationAction => ({
    type: VanFilterActionEnum.LOCATION,
    location,
    sub_location
  }),
  setEndLocationFilter: (end_location: string): VanFilterEndLocationAction => ({
    type: VanFilterActionEnum.END_LOCATION,
    end_location,
  }),
  setRegionFilter: (region: string[]): VanFilterRegionAction => ({
    type: VanFilterActionEnum.REGION,
    region
  }),
  setStartDateFilter: (startDate: string): VanFilterStartDateAction => ({
    type: VanFilterActionEnum.START_DATE,
    startDate,
  }),
  setEndDateFilter: (endDate?: string): VanFilterEndDateAction => ({
    type: VanFilterActionEnum.END_DATE,
    endDate,
  }),
  clearFilter: (): ClearVanFilterAction => ({
    type: VanFilterActionEnum.CLEAR,
  }),
  errorFilter: (
    errors: any,
    isFilterValidate: boolean
  ): ErrorVanFilterAction => ({
    type: VanFilterActionEnum.ERROR_FILTER,
    errors,
    isFilterValidate,
  }),
  clearErrorFilter: (): ClearErrorVanFilterAction => ({
    type: VanFilterActionEnum.CLEAR_ERROR_FILTER,
  }),
  setCheckFilter: (): SetCheckFilter => ({
    type: VanFilterActionEnum.SET_CHECK_FILTER,
  }),
};
