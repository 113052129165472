import { FC } from "react";
import { scrollToTop } from "src/common/utils/others";
import { IPropsOrder } from "..";
import Cart from "../Cart";
import Store from "../Store";

const Step1: FC<IPropsOrder & { handleClearOrder: any }> = ({
  order,
  onChange,
  setStep,
  handleClearOrder,
}) => {
  const handleNextStep = () => {
    setStep(1);
    scrollToTop();
  };
  return (
    <>
      <Store order={order} onChange={onChange} />
      <Cart
        order={order}
        titleButton='Book Refundable (or get quote)'
        handleNextStep={handleNextStep}
        handleBackStep={handleClearOrder}
        onChange={onChange}
      />
    </>
  );
};

export default Step1;
