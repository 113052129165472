import { IClient } from "src/models/IOrder";
import {
  ContractActionEnum,
  ErrorContractAction,
  ErrorSignContractAction,
  RequestContractAction,
  ResultContractAction,
  ResultSignContractAction,
  SignContractAction,
} from "./types";

export const ContractActionCreators = {
  getContract: (id: string): RequestContractAction => ({
    type: ContractActionEnum.REQUEST_CONTRACT,
    id,
  }),
  resultGetContract: (data: any): ResultContractAction => ({
    type: ContractActionEnum.RESULT_CONTRACT,
    data,
  }),
  errorGetContract: (statusText: string): ErrorContractAction => ({
    type: ContractActionEnum.ERROR_CONTRACT,
    statusText,
  }),
  signContract: (sign: any, id: string): SignContractAction => ({
    type: ContractActionEnum.SIGN_CONTRACT,
    sign,
    id,
  }),
  resultSignContract: (status: string | number, data: {id: number, host: IClient}): ResultSignContractAction => ({
    type: ContractActionEnum.RESULT_SIGN_CONTRACT,
    status,
    data,
  }),
  errorSignContract: (statusText: string): ErrorSignContractAction => ({
    type: ContractActionEnum.ERROR_SIGN_CONTRACT,
    statusText,
  }),
};
