
import { put, takeLatest } from "redux-saga/effects";
import { ClientActionCreators } from "src/store/reducers/client/action-creators";
import { ClientActionEnum } from "src/store/reducers/client/types";
import instanceWithToken from "src/sagas/setWithToken";

function* fetchShowContract({
  key,
}: ReturnType<typeof ClientActionCreators.showContract>) {
  try {
    const id = key.split('orders/')[1];
    const { data } = yield instanceWithToken.get(`contract/show/${id}`);
    yield put(ClientActionCreators.resultShowContract(data));
  } catch (error: any) {
    console.log(error);
  }
}
export function* actionWatcherShowContract() {
  yield takeLatest(ClientActionEnum.REQUEST_SHOW_CONTRACT, fetchShowContract);
}
