import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime, Interval } from 'luxon';
import Step from './Step';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { IOrderForClient } from 'src/models/IOrder';

interface IProps {
  order: IOrderForClient
}

const Steps: FC<IProps> = ({ order }) => {

  const { payNow } = useActions();
  const navigate = useNavigate();
  const client = useTypedSelector((state) => state.client.client);

  return (
    <div className='client__order__steps'>
      <h3>Take Next Steps for Check-in</h3>
      <div className='client__order__steps__container'>
        <Step
          title='Upload Docs'
          status={
            (!!client!.driver_license?.insurance
              && client!.driver_license?.photos?.length === 2
            ) ? 'completed' : 'complete'
          }
          statusText={
            (!!client!.driver_license?.insurance
              && client!.driver_license?.photos?.length === 2
            ) ? 'completed' : 'complete now'
          }
          handleClick={
            (!!client!.driver_license?.insurance
              && client!.driver_license?.photos?.length === 2)
              ? () => { }
              : () => navigate(`/license/${order.licenseHash}`)
          }
        />
        <Step
          title='Invite Drivers'
          status={
            order.additional_driver.length ? 'completed' : 'complete'
          }
          statusText={
            order.additional_driver.length ? 'invite more' : 'invite now'
          }
          handleClick={() => navigate(`/driver/add/${order.inviteDriverHash}`)}
        />
        <Step
          title='Payment'
          status={
            (!client!.driver_license?.insurance
              && (
                !client!.driver_license?.photos
                || client!.driver_license?.photos.length < 2
              )
            ) ? 'closed'
              : order.status === 'paid'
                ? 'completed'
                : 'complete'
          }
          statusText={
            (!client!.driver_license?.insurance
              && (
                !client!.driver_license?.photos
                || client!.driver_license?.photos.length < 2
              )
            ) ? 'available later'
              : order.status === 'paid'
                ? 'completed'
                : 'pay now'
          }
          handleClick={
            order.status === 'paid'
              || (!client!.driver_license?.insurance
                && (
                  !client!.driver_license?.photos
                  || client!.driver_license?.photos.length < 2
                )
              ) ? () => { } : () => payNow(order.id)
          }
        />
        <Step
          title='E-sign'
          status={
            (Interval.fromDateTimes(
              DateTime.now(),
              DateTime.fromISO(order.start_date)
            ).toDuration(["days"]).days > 2
              || order.status !== 'paid')
              ? 'closed'
              : order.pdf_order
                ? 'completed'
                : 'complete'
          }
          statusText={
            Interval.fromDateTimes(
              DateTime.now(),
              DateTime.fromISO(order.start_date)
            ).toDuration(["days"]).days > 2
              ? 'available later'
              : order.pdf_order
                ? 'completed'
                : 'complete now'
          }
          handleClick={
            (Interval.fromDateTimes(
              DateTime.now(),
              DateTime.fromISO(order.start_date)
            ).toDuration(["days"]).days > 2) || order.pdf_order
              ? () => { }
              : () => navigate(`/contract/${order.signRaHash}`)
          }
        />
        <Step
          title='Pickup info & keys'
          status={
            (Interval.fromDateTimes(
              DateTime.now(),
              DateTime.fromISO(order.start_date)
            ).toDuration(["days"]).days > 2) || !order.pdf_order
              ? 'closed'
              : 'completed'
          }
          statusText={
            (Interval.fromDateTimes(
              DateTime.now(),
              DateTime.fromISO(order.start_date)
            ).toDuration(["days"]).days > 2) || !order.pdf_order
              ? 'available later'
              : 'completed'
          }
          handleClick={() => { }}
        />
      </div>
    </div>
  );
};

export default Steps;
