import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField, Tooltip } from '@mui/material';
import { FC } from 'react';
import { typeForm } from 'src/theme';

interface IProps {
  date?: Date;
  setDate: any;
  title?: string;
  placeholder?: string;
  style?: { [key: string]: string };
  max?: Date;
  min?: Date;
  check: boolean;
  error?: string;
}

const SimpleDatePicker: FC<IProps> = ({
  date,
  setDate,
  title,
  placeholder,
  style,
  max,
  min,
  check,
  error,
}) => {
  const helperError = () => {
    if (check) {
      if (error) return true;
      else if (!date) return true;
      else return false;
    }
  };
  const helperText = () => {
    if (check) {
      if (error) return error;
      else if (!date) return 'Fill the field';
      else return;
    }
  };

  return (
    <div style={style}>
      {title && <div className='filter__title'>{title}</div>}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={date ?? ''}
          onChange={setDate}
          maxDate={max}
          minDate={min}
          defaultCalendarMonth={max}
          renderInput={(props) => (
            <Tooltip title='Select any future date if no expiration'>
              <TextField
                {...props}
                autoComplete='off'
                variant={typeForm}
                placeholder={placeholder ?? 'mm/dd/yyyy'}
                error={helperError()}
                helperText={helperText()}
              />
            </Tooltip>
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

export default SimpleDatePicker;
