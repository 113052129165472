import {
    SubPriceState,
    SubPriceActionEnum,
    SubPriceAction,
  } from './types';
  
  const initialState: SubPriceState = {
    subPriceList: [],
    isLoading: {
      all: false,
    },
  };
  
  const subPrice = (
    state = initialState,
    action: SubPriceAction
  ): SubPriceState => {
    switch (action.type) {
        case SubPriceActionEnum.REQUEST_SUBPRICES:
          return { ...state, isLoading: { ...state.isLoading, all: true } };
        case SubPriceActionEnum.RESULT_SUBPRICES:
          return {
            ...state,
            subPriceList: action.data,
            isLoading: { ...state.isLoading, all: !action.data },
          };
      default:
        return state;
    }
  };
  export default subPrice;
  