import { FC, useEffect, useMemo, useState } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MiniSign from "../another/MiniSign";
import { dineroFormat } from "src/common/utils/dataFormat";
import { IVanFetch } from "src/models/IVan";
import Icons from "src/common/icons";
import './styles.scss';

interface IProps {
    array: string[];
    id: number;
    className: string;
    van?: IVanFetch;
}

const ScrollItem: FC<IProps> = ({ array, id, className, van }) => {

    const [isActiveButtons, setIsActiveButtons] = useState({ left: true, right: false });
    const [isShowButtons, setIsShowButtons] = useState(true);
    const container = document.getElementById(`container_${className}_${id}`);
    const isSmallScreen = useMemo(() => (
        className === 'van__capacity__container' ? true : window.outerWidth < 431
    ), [window.outerWidth, className]);

    useEffect(() => {
        if (container) {
            if (container!.scrollWidth > container!.offsetWidth) {
                setIsShowButtons(true);
            } else setIsShowButtons(false);
        }
    }, [container, array]);

    const sideScroll = (direction: string) => {
        let scrollAmount = 0;
        const slideTimer = setInterval(function () {
            if (container) {
                if (direction == 'left') {
                    container!.scrollLeft -= 10;
                } else {
                    container!.scrollLeft += 10;
                }
                scrollAmount += 10;
            }
            if (scrollAmount >= 100) {
                window.clearInterval(slideTimer);
                if (
                    (container!.scrollWidth === Math.floor(container!.scrollLeft) + container!.clientWidth) ||
                    (container!.scrollWidth === Math.ceil(container!.scrollLeft) + container!.clientWidth)
                ) {
                    setIsActiveButtons({ left: false, right: true });
                } else if (!container!.scrollLeft) {
                    setIsActiveButtons({ left: true, right: false });
                } else setIsActiveButtons({ left: false, right: false });
            }
        }, 25);
    };

    return (
        <>
            <div className={`${className} scroll-item`}>
                <div className={`${className}__items`} id={`container_${className}_${id}`}>
                    {className === 'van__capacity__container' && (
                        <>
                            <div className='van__capacity__container__item 
                                van__capacity__container__item__price'
                            >
                                <MiniSign
                                    classes='van__capacity__container__item__black'
                                    text={dineroFormat(van!.price, van!.currency)}
                                />
                                <div style={{ fontSize: '10px', lineHeight: '8px' }}>
                                    average rate
                                </div>
                            </div>
                            <MiniSign
                                classes='van__capacity__container__item'
                                icon={<Icons.Seat />}
                                text={`x ${van!.seats}`}
                            />
                            <MiniSign
                                classes='van__capacity__container__item'
                                icon={<Icons.Bagadge />}
                                text='x 15'
                            />
                        </>
                    )}
                    {array.map((sub, i) => (
                        <div className={`${className}__item`} key={i}>
                            {sub}
                        </div>
                    ))}
                </div>
                {isSmallScreen && (
                    <>
                        <ArrowBackIosIcon
                            className={`scroll-item__button 
                                ${className}__prev-button
                                ${isActiveButtons.left && `scroll-item__inactive`}
                                ${!isShowButtons && `scroll-item__invisible`}
                            `}
                            onClick={() => sideScroll('left')}
                            fontSize='small'
                        />
                        <ArrowForwardIosIcon
                            className={`scroll-item__button 
                                ${className}__next-button
                                ${isActiveButtons.right && `scroll-item__inactive`}
                                ${!isShowButtons && `scroll-item__invisible`}
                            `}
                            onClick={() => sideScroll('right')}
                            fontSize='small'
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default ScrollItem;
