import { FC } from 'react';
import './styles.scss';
import './media.scss';

interface IProps { }

const Footer: FC<IProps> = () => {
  const typeNeedsArray = {
    'Passenger Vans': [
      [
        '15 - Recliner Sprinter w. Luggage',
        'https://www.sprinter-rentals.com/van-rental/mercedes-15-recliner-seater-van',
      ],
      [
        '15 - Seater Sprinter w. Luggage',
        'https://www.sprinter-rentals.com/15-seater-sprinter',
      ],
      [
        '15 - Recliner Transit w. Luggage',
        'https://www.sprinter-rentals.com/van-rental/united-states/san-diego-ca/15-seater/ford-transit-van/0001005',
      ],
      [
        '12 - Seater Sprinter XL, 5x5ft trunk',
        'https://www.sprinter-rentals.com/van',
      ],
      [
        '12 - Seater Sprinter, 3x5 ft trunk',
        'https://www.sprinter-rentals.com/12-seater-sprinter',
      ],
      [
        '4X4 Sprinter 12 Seater w. 4WD',
        'https://www.sprinter-rentals.com/4x4-sprinter-van-4WD',
      ],
    ],
    'Cargo Vans': [
      [
        'Crew Cargo 144 Sprinter 5 seater',
        'https://www.sprinter-rentals.com/5-seater-crew-van-mercedes-benz-sprinter',
      ],
      [
        'Crew Cargo Sprinter 5 seater',
        'https://www.sprinter-rentals.com/crew-cargo-sprinter',
      ],
      [
        'Crew Cargo Transit 5 seater',
        'https://www.sprinter-rentals.com/van-rental/united-states/san-diego-ca/crew-cargo/ford-transit-van/0001001',
      ],
      [
        'Cargo Sprinter XL with 2 seats',
        'https://www.sprinter-rentals.com/cargo-van',
      ],
      [
        'Cargo Transit with 2 seats',
        'https://www.sprinter-rentals.com/cargo-transit-van',
      ],
    ],
    'Executive & Business Vans': [
      [
        'VIP 12-Seater w. trunk',
        'https://www.sprinter-rentals.com/business-class-sprinter-van',
      ],
      [
        'VIP 11-Seater w. trunk / couch',
        'https://www.sprinter-rentals.com/11-seater-VIP-van',
      ],
      [
        'VIP 9-Seater w. large trunk',
        'https://www.sprinter-rentals.com/9-seater-business-class-sprinter',
      ],
      [
        '8 Seater Mercedes Mini Van w. trunk',
        'https://www.sprinter-rentals.com/8-passenger-metris-minivan',
      ],
    ],
    'Europe vans': [
      [
        'Euro 8 Seater Metris V-Class',
        'https://www.sprinter-rentals.com/8-passenger-metris-minivan',
      ],
      [
        'Euro 9 Seater Sprinter',
        'https://www.sprinter-rentals.com/munich-sprinter-van-rental',
      ],
      [
        'Euro 9 Seater XL Sprinter',
        'https://www.sprinter-rentals.com/van-rental/germany/9-seater/sprinter-van/de0002001',
      ],
    ],
  };
  const EuropeLocationArray = {
    Germany: [
      ['Munich', ''],
      ['Stuttgart', ''],
      ['Frankfurt', ''],
      ['Berlin', 'https://www.sprinter-rentals.com/berlin-van-rental'],
      ['Hamburg', ''],
    ],
    Austria: [
      ['Salzburg', ''],
      ['Linz', ''],
    ],
  };
  const USALocationArray = {
    ['USA West']: [
      ['San Diego', 'https://www.sprinter-rentals.com/san-diego-van-rentals'],
      [
        'Orange County',
        'https://www.sprinter-rentals.com/orange-county-van-rentals',
      ],
      [
        'Los Angeles',
        'https://www.sprinter-rentals.com/los-angeles-van-rentals',
      ],
      ['San Jose', 'https://www.sprinter-rentals.com/san-jose_silicon-valley'],
      [
        'San Francisco',
        'https://www.sprinter-rentals.com/san-francisco-van-rentals',
      ],
      [
        'Portland',
        'https://www.sprinter-rentals.com/portland-van-rental-salem',
      ],
      ['Seattle', 'https://www.sprinter-rentals.com/seattle-vans'],
    ],
    ['USA Mid']: [
      ['Las Vegas', 'https://www.sprinter-rentals.com/las-vegas-van-rentals'],
      ['Phoenix', 'https://www.sprinter-rentals.com/phoenix-van-rentals'],
      ['Atlanta', 'https://www.sprinter-rentals.com/atlanta-van-rentals'],
      [
        'Dallas',
        'https://www.sprinter-rentals.com/dallas-fort-worth-airport-van-rentals',
      ],
      [
        'Austin',
        'https://www.sprinter-rentals.com/austin-san-antonio-rent-a-van',
      ],
      [
        'Houston',
        'https://www.sprinter-rentals.com/houston-rent-a-van',
      ],
    ],
    ['USA East']: [
      ['Newark', 'https://www.sprinter-rentals.com/new-jersey-van-rentals'],
      ['New York', 'https://www.sprinter-rentals.com/new-jersey-van-rentals'],
      ['Richmond', 'https://www.sprinter-rentals.com/richmond-van-rentals'],
      ['Tampa', 'https://www.sprinter-rentals.com/tampa-rent-a-van'],
      ['Miami', 'https://www.sprinter-rentals.com/miami-rent-a-van'],
    ],

  };
  const AdditionalArray = {
    ['Other vans']: [
      ['Wrapping a van', 'https://www.sprinter-rentals.com/sprinter-van-rentals-usa-wrap-you'],
    ],
    ['Rental Support']: [
      ['Contact us', 'https://www.sprinter-rentals.com/contact'],
      ['FAQ', 'https://www.sprinter-rentals.com/faq'],
      ['Cancellation Policy', 'https://www.sprinter-rentals.com/cancellation'],
      ['24h Road Side Asssistance', 'https://www.sprinter-rentals.com/faq#road-side-assistance'],
    ],
  };
  const endArray = {
    FAQ: 'https://www.sprinter-rentals.com/faq',
    Terms: 'https://www.sprinter-rentals.com/tos',
    'Privacy Policy': 'https://www.sprinter-rentals.com/privacy',
  };

  const _renderSection = (array: any, type: string) =>
    Object.keys(array).map((sec) => (
      <div className={`${type === 'additional' ? 'footer__section-category additional' : 'footer__section-category'}`} key={sec}>
        <div className='footer__section-category__title'> {sec}</div>
        {array[sec].map(([text, link]: string[], index: number) =>
          link ? (
            <a
              href={link}
              className='footer__section-category__item'
              key={index}
              target='_blank'
            >
              {text}
            </a>
          ) : (
            <div className='footer__section-category__item' key={index}>
              {text}
            </div>
          )
        )}
      </div>
    ));
  return (
    <div className='footer__wrapper'>
      <div className='footer__section two-column'>
        <div className='footer__section-title'>
          Needs/Van Types
        </div>
        {_renderSection(typeNeedsArray, 'normal')}
      </div>
      <div className='footer__section locations'>
        <div className='footer__section-title'>
          Locations
        </div>
        {_renderSection(USALocationArray, 'normal')}
        <div>
          {_renderSection(EuropeLocationArray, 'normal')}
        </div>
        {_renderSection(AdditionalArray, 'additional')}
      </div>
      <div className='footer__section-end'>
        Copyright © 2009 - 2024 Sprinter Rentals, All rights reserved.
        <div className='footer__section-end__items'>
          {Object.keys(endArray).map((sec) => (
            <a
              href={endArray[sec as keyof typeof endArray]}
              key={sec}
              target='_blank'
            >
              {sec}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
