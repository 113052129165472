import { FC } from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  XIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { useClipboard } from 'use-clipboard-copy';
import { useSnackbar } from 'notistack';
import { Button, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CircleIcon from '@mui/icons-material/Circle';

interface IProps {
  id?: number;
  hash?: string;
}

const ShareItems: FC<IProps> = ({ id, hash }) => {

  const clipboard = useClipboard();
  const { enqueueSnackbar } = useSnackbar();
  const url = id
    ? `https://van.sprinter-rentals.com/van/143${id}`
    : `https://van.sprinter-rentals.com/driver/add/${hash}`;

  const handleCopy = () => {
    clipboard.copy(url);
    enqueueSnackbar('Link copied successfully', { variant: 'success' });
  };

  return (
    <div className='share-modal__items'>
      <Tooltip title='Facebook'>
        <FacebookShareButton url={url}>
          <FacebookIcon size={40} round={true} />
        </FacebookShareButton>
      </Tooltip>
      <Tooltip title='Telegram'>
        <TelegramShareButton url={url}>
          <TelegramIcon size={40} round={true} />
        </TelegramShareButton>
      </Tooltip>
      <Tooltip title='Whatsapp'>
        <WhatsappShareButton url={url}>
          <WhatsappIcon size={40} round={true} />
        </WhatsappShareButton>
      </Tooltip>
      <Tooltip title='X'>
        <TwitterShareButton url={url}>
          <XIcon size={40} round={true} />
        </TwitterShareButton>
      </Tooltip>
      <Tooltip title='Email'>
        <EmailShareButton url={url}>
          <EmailIcon size={40} round={true} />
        </EmailShareButton>
      </Tooltip>
      <Tooltip title='Copy van link'>
        <Button
          className='share-modal__copy-link'
          onClick={handleCopy}
        >
          <ContentCopyIcon
            className='share-modal__copy-link__copy-icon'
          />
          <CircleIcon
            className='share-modal__copy-link__circle-icon'
          />
        </Button>
      </Tooltip>
    </div>
  );
};
export default ShareItems;
