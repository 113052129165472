import {
  LocationActionEnum,
  RequestLocationAction,
  ResultLocationAction,
  Location,
} from './types';

export const LocationActionCreators = {
  getLocations: (): RequestLocationAction => ({
    type: LocationActionEnum.REQUEST_LOCATIONS,
  }),
  resultGetLocations: (data: Location[]): ResultLocationAction => ({
    type: LocationActionEnum.RESULT_LOCATIONS,
    data,
  }),
};
