import { IDriverLicense } from 'src/models/IDriverLicense';

export enum DriverLicenseActionEnum {
  ADD_DRIVER_LICENSE_IMAGES = 'ADD_DRIVER_LICENSE_IMAGES',
  RESULT_ADD_DRIVER_LICENSE_IMAGES = 'RESULT_ADD_DRIVER_LICENSE_IMAGES',

  SET_DRIVER_LICENSE = 'SET_DRIVER_LICENSE',
  RESULT_SET_DRIVER_LICENSE = 'RESULT_SET_DRIVER_LICENSE',
  ERROR_SET_DRIVER_LICENSE = 'ERROR_SET_DRIVER_LICENSE',
}

export interface DriverLicenseState {
  isLoading: {
    set: boolean;
    uploadingImage: boolean;
  };
  result?: string | number;
  error?: string;
}
export interface AddDriverLicenseImagesAction {
  type: DriverLicenseActionEnum.ADD_DRIVER_LICENSE_IMAGES;
  license: IDriverLicense;
  images: File[];
  insurance: File;
}
export interface ResultAddDriverLicenseImagesAction {
  type: DriverLicenseActionEnum.RESULT_ADD_DRIVER_LICENSE_IMAGES;
  license: IDriverLicense;
  statuses: string[];
}

export interface SetDriverLicenseAction {
  type: DriverLicenseActionEnum.SET_DRIVER_LICENSE;
  data: IDriverLicense;
  id: string;
}
export interface ResultSetDriverLicenseAction {
  type: DriverLicenseActionEnum.RESULT_SET_DRIVER_LICENSE;
  status: string | number;
}
export interface ErrorSetDriverLicenseAction {
  type: DriverLicenseActionEnum.ERROR_SET_DRIVER_LICENSE;
  statusText: string;
}

export type DriverLicenseAction =
  | AddDriverLicenseImagesAction
  | ResultAddDriverLicenseImagesAction
  | SetDriverLicenseAction
  | ResultSetDriverLicenseAction
  | ErrorSetDriverLicenseAction;
