import { put, takeLatest } from "redux-saga/effects";
import instanceWithTokenNoAlert from "../instanceWithTokenNoAlert";
import { ClientActionEnum } from "src/store/reducers/client/types";
import { ClientActionCreators } from "src/store/reducers/client/action-creators";

function* fetchChangeDates({
  dates,
  orderId
}: ReturnType<typeof ClientActionCreators.changeOrderDates>) {
  try {
    const { status, data } = yield instanceWithTokenNoAlert.post(`/personal/order/change_date/${orderId}`, {
      'data': dates
    });
    yield put(ClientActionCreators.resultChangeOrderDates(status, data));
  } catch (e) {
    console.log(e);
    yield put(ClientActionCreators.resultChangeOrderDates(400));
  }
}

export function* actionWatcherChangeOrderDates() {
  yield takeLatest(ClientActionEnum.REQUEST_CHANGE_DATES, fetchChangeDates);
}