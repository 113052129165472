import { FC } from 'react';
import useIsMobile from 'src/hooks/useIsMobile';
import { IVanTag } from 'src/models/IVan';
import Icons from 'src/common/icons';

interface IProps {
    location: string;
    tags: IVanTag[];
    style?: { [key: string]: string };
    className: string;
}

const AvailableLocations: FC<IProps> = ({ location, tags, style, className }) => {
    
    const isMobile = useIsMobile();
    const deliveryLocations = tags.filter((t) => (
        t.category === 'location' && t.name !== location
    ));

    return (
        <div className={className} style={style}>
            {isMobile ? (
                <>
                    <Icons.Label classes='gap-icon' />
                    <span>
                        <b>Available in and around: </b>
                        {`${location.split('-')[1]}, ${deliveryLocations!.map((t) => t.name.split('-')[1])}.`}
                    </span>
                </>
            ) : (
                <>
                    <div>
                        <Icons.Label />
                        <b>Available in and around: </b>
                    </div>
                    {`${location.split('-')[1]}, ${deliveryLocations!.map((t) => t.name.split('-')[1])}.`}
                </>
            )}
        </div>
    );
};

export default AvailableLocations;
