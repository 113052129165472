import { GlobalActionEnum, SetHeaderClose, SetHeaderOpen } from './types';

export const GlobalActionCreators = {
  headerClose: (): SetHeaderClose => ({
    type: GlobalActionEnum.HIDE,
    header: false,
  }),
  headerOpen: (): SetHeaderOpen => ({
    type: GlobalActionEnum.SHOW,
    header: true,
  }),
};
