export enum GlobalActionEnum {
  ERROR = 'ERROR',
  HIDE = 'HIDE',
  SHOW = 'SHOW',
}

export interface GlobalState {
  error?: {
    result: string | number;
    status: string | number;
  };
  header: boolean;
}
export interface SetHeaderOpen {
  type: GlobalActionEnum.SHOW;
  header: boolean;
}
export interface SetHeaderClose {
  type: GlobalActionEnum.HIDE;
  header: boolean;
}
