import { put, takeLatest } from "redux-saga/effects";
import instanceWithToken from "../setWithToken";
import { ClientActionEnum } from "src/store/reducers/client/types";
import { ClientActionCreators } from "src/store/reducers/client/action-creators";

function* fetchGetClient({ id }: ReturnType<typeof ClientActionCreators.getClient>) {
  try {
    const { data, status } = yield instanceWithToken.get(`/personal/${id}`);
    yield put(ClientActionCreators.resultGetClient(data, status));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetClient() {
  yield takeLatest(ClientActionEnum.REQUEST_CLIENT, fetchGetClient);
}
