import { put, takeLatest } from 'redux-saga/effects';
import instanceWithToken from "../setWithToken";
import { ClientActionEnum } from "src/store/reducers/client/types";
import { ClientActionCreators } from "src/store/reducers/client/action-creators";

function* fetchUpdateOrder({
  currentOrder,
}: ReturnType<typeof ClientActionCreators.updateOrder>) {
  const { id, ...data } = currentOrder;
  try {
    const { status } = yield instanceWithToken.patch(`personal/order/${id}`, {
      data,
    });
    yield put(ClientActionCreators.resultUpdateOrder(status));
  } catch (e) {
    console.log(e);
    yield put(ClientActionCreators.resultUpdateOrder(400));
  }
}

export function* actionWatcherUpdateOrder() {
  yield takeLatest(ClientActionEnum.REQUEST_UPDATE_ORDER, fetchUpdateOrder);
}