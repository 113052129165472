import { FC, useEffect, useRef, useState } from "react";
import {
    Stepper,
    Step,
    StepLabel,
} from "@mui/material";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import "./styles.scss";

const steps = [
    { label: 'Upload Docs', step: 4 },
    { label: 'Inivite Drivers', step: 5 },
    { label: 'E-sign', step: 6 },
    { label: 'Payment', step: 7 },
];

const InfoStepper: FC = () => {

    const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
    const [canScrollRight, setCanScrollRight] = useState<boolean>(false);

    const listRef = useRef<any>(null);

    const checkForScrollPosition = () => {
        const { current } = listRef;
        if (current) {
            const { scrollLeft, scrollWidth, clientWidth } = current;
            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(scrollLeft !== scrollWidth - clientWidth);
        }
    };

    const scrollContainerBy = (distance: number) =>
        listRef.current?.scrollBy({ left: distance, behavior: "smooth" });

    useEffect(() => {
        const { current } = listRef;
        checkForScrollPosition();
        current?.addEventListener("scroll", checkForScrollPosition);

        return () => {
            current?.removeEventListener("scroll", checkForScrollPosition);
        };
    }, []);

    return (
        <>
            <Stepper ref={listRef} alternativeLabel className='infoStepperContainer'>
                <Step completed className='firstStep'>
                    <p className='firstStep__mainText'>
                        Online Check-in
                    </p>
                    <p className='firstStep__secondaryText'>
                        due later
                    </p>
                </Step>
                {steps.map((step, index) => {
                    return (
                        <Step key={step.step} active={false}
                            sx={{
                                "& .MuiStepConnector-root .MuiStepConnector-line": {
                                    border: '1.5px dashed rgba(0, 0, 0, 0.3)'
                                },
                                "& .MuiStepIcon-root": {
                                    border: '1.5px dashed rgba(0, 0, 0, 0.3)',
                                },
                                minWidth: '100px'
                            }}
                        >
                            <StepLabel
                                icon={index + 4}
                                style={{ flexDirection: 'column-reverse', gap: '5px' }}
                            >
                                {step.label}
                            </StepLabel>
                        </Step>
                    );
                })}
                <Step completed
                    sx={{
                        "& .MuiStepConnector-root": {
                            border: '1.5px dashed rgba(0, 0, 0, 0.3)',
                            right: 'calc(50% + 55px)',
                            "@media (max-width: 650px)": {
                                left: 'calc(-50% + 18px)'
                            },
                        },
                        "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                            fontWeight: '700 !important',
                            fontSize: '12px !important',
                            lineHeight: '22px',
                            color: '#4B566B',
                        },
                        minWidth: 'fit-content'
                    }}
                >
                    <StepLabel icon={<DoneAllIcon />} sx={{ marginTop: '10px' }}>
                        Pickup info & keys
                    </StepLabel>
                </Step>
            </Stepper>
            {/* <button
                type="button"
                disabled={!canScrollLeft}
                onClick={() => scrollContainerBy(-400)}
                className='swiper-button-prev arrowButton prev'
            />
            <button
                type="button"
                disabled={!canScrollRight}
                onClick={() => scrollContainerBy(400)}
                className='swiper-button-next arrowButton next'
            /> */}
        </>
    );
};

export default InfoStepper;
