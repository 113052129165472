import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { OnewayActionEnum } from 'src/store/reducers/oneway/types';
import { OnewayActionCreator } from 'src/store/reducers/oneway/action-creator';

function* fetchGetOneways() {
  try {
    const { data } = yield axios.get(`${process.env.REACT_APP_API}/oneway`);
    yield put(OnewayActionCreator.resultGetOneway(data));
  } catch (error) {
    console.log('error :>> ', error);
  }
}
export function* actionWatcherGetOneway() {
  yield takeLatest(OnewayActionEnum.REQUEST_ONEWAY, fetchGetOneways);
}
