import { DateTime } from 'luxon';
import { FC } from 'react';
import SimpleDatePicker from 'src/components/Items/input/SimpleDatePicker';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import MultiplyUploadFiles from 'src/components/Items/MultiplyUploadFiles';
import UploaderFile from 'src/components/Items/UploaderFile';
import useIsMobile from 'src/hooks/useIsMobile';
import { IDriverLicense } from 'src/models/IDriverLicense';
import { IErrorsLicense } from '..';

interface IProps {
  license: IDriverLicense;
  setLicense: any;
  images: File[];
  setImages: any;
  insurance?: File | string;
  setInsurance: any;
  deletedImages: string[];
  setDeletedImages: any;
  check: boolean;
  errors: IErrorsLicense;
}

const DriverLicenseForm: FC<IProps> = ({
  license,
  setLicense,
  images,
  setImages,
  deletedImages,
  setDeletedImages,
  check,
  errors,
  insurance,
  setInsurance,
}) => {
  const isMobile = useIsMobile();
  const { photos, issued, address, number, birthday, expiration_date } =
    license;

  const handleMultipleUploadFile = (files: File[] | File) => {
    const newPhotos = [...images];
    let isAdding;
    Object.values(files).forEach((file) => {
      if (newPhotos.length) {
        if(newPhotos[0].name !== file.name) {
          newPhotos.push(file);
          isAdding = true;
        } else isAdding = false;
      } else {
        newPhotos.push(file);
        isAdding = true;
      }
    });
    setImages(newPhotos);
    return isAdding;
  };

  const handleDeleteFile = (url: string, index: number) => {
    setImages([...images.slice(0, index), ...images.slice(index + 1)]);
    setDeletedImages([...deletedImages, url]);
  };

  const handleUploadInsurance = (file: File) => setInsurance(file);

  const _dates = () => (
    <>
      <SimpleDatePicker
        date={expiration_date}
        setDate={setLicense('expiration_date')}
        title='Expires'
        min={new Date()}
        check={check}
        error={errors.expiration_date}
      />
      <SimpleDatePicker
        date={birthday}
        setDate={setLicense('birthday')}
        title='Your date of birth'
        max={DateTime.now().minus({ years: 18 }).toJSDate()}
        check={check}
        error={errors.birthday}
      />
    </>
  );

  return (
    <>
      <div className='col-2'>
        <SimpleInput
          data={number}
          setData={setLicense('number')}
          title='Drivers License'
          placeholder='number'
          check={check}
          error={errors.number}
        />
        <SimpleInput
          data={address}
          setData={setLicense('address')}
          title='Your Address'
          placeholder='Address'
          check={check}
          error={errors.address}
        />
        <SimpleInput
          data={issued}
          setData={setLicense('issued')}
          title='Issued in'
          placeholder='state or country'
          check={check}
          error={errors.issued}
        />
        {isMobile ? <div className='col-2'>{_dates()}</div> : _dates()}
        <MultiplyUploadFiles
          nameUploader='photos'
          previousFiles={photos as string[]}
          onChangeField={handleMultipleUploadFile}
          handleDeleteFile={handleDeleteFile}
          limitImages={2}
          error={check ? errors.photos : ''}
          title="Photo or scan of your driver's license, front & back."
          subtitle="Two Individual files don’t upload the same files twice."
          label='Browse/drag files/photos or take photo'
        />
        <UploaderFile
          nameUploader='insurance'
          previousFile={insurance as string}
          onChangeField={handleUploadInsurance}
          handleDeleteFile={handleDeleteFile}
          error={check ? errors.isInsurance : ''}
          title='Photo or scan of your proof of auto insurance.'
          subtitle="One file, don’t upload files used above."
          label='Browse your insurance'
        />
      </div>
      <div className='driver-license__description'>
        <p>
          Invite free additional drivers using the link in an upcoming email.
          Reply to any of our emails to make changes or cancel your reservation.
        </p>
        <p>
          Whats next: We’ll email all details to get your van, sign your
          contract, make your payment and get your keys from your host.
        </p>
      </div>
    </>
  );
};
export default DriverLicenseForm;
