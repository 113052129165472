import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ReCAPTCHA from "react-google-recaptcha";
import { Button, CircularProgress, TextField, styled } from '@mui/material';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { useActions } from 'src/hooks/useActions';
import { validateField } from 'src/common/utils/validation';
import SmallHeader from '../Van/singleVanPage/SmallHeader';
import Footer from 'src/components/Footer';
import { typeForm } from 'src/theme';
import './styles.scss';

interface ICancelBooking {
    order_id: number;
    email: string;
}

export type IErrorsCancelBooking = {
    [key in keyof ICancelBooking]?: string;
};

const NumberInput = styled(TextField)({
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
        'WebkitAppearance': 'none',
    },
    'input[type=number]': {
        'MozAppearance': 'textfield'
    }
});

const CancelBookingPage: FC = () => {

    const navigate = useNavigate();
    const { cancelOrder } = useActions();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { isLoading, result } = useTypedSelector((state) => state.cancelOrder);

    const [cancelBooking, setCancelBooking] = useState<ICancelBooking>();
    const [check, setCheck] = useState(false);
    const [errors, setErrors] = useState<IErrorsCancelBooking>({});
    const [disable, setDisable] = useState(true);

    const validateInputs = (): boolean => {
        const order_id = validateField(String(cancelBooking?.order_id));
        const email = validateField(cancelBooking?.email!);

        setErrors({
            order_id,
            email,
        });
        return [order_id, email].every(
            (el) => !el
        );
    };

    const handleSubmit = () => {
        setCheck(true);
        if (validateInputs()) {
            cancelOrder(
                cancelBooking?.order_id!,
                cancelBooking!.email!.toLowerCase().trim()
            );
        }
        setDisable(true);
    };

    useMemo(() => {
        check && validateInputs();
    }, [cancelBooking]);

    useEffect(() => {
        result === 200 && (
            enqueueSnackbar('Your cancellation request has been submitted', {
                variant: 'success',
                persist: true
            }),
            setTimeout(() => closeSnackbar(), 7000),
            navigate('/')
        );
    }, [result]);

    return (
        <>
            <div className='header'>
                <SmallHeader />
            </div>
            <div className='cancel-booking'>
                <p className='cancel-booking__description'>
                    We made canceling easy.. Let's get started.
                </p>
                <div className='cancel-booking__form-container'>
                    <div>
                        <div className='filter__title'>
                            Reservation number
                        </div>
                        <NumberInput
                            placeholder='Please enter your reservation number'
                            variant={typeForm}
                            type='number'
                            value={cancelBooking?.order_id}
                            onChange={(event) => {
                                let value = Number(event.target.value);
                                if (value > 99999) value = 99999;
                                if (value < 0) value = 0;
                                setCancelBooking({
                                    ...cancelBooking!,
                                    'order_id': value
                                });
                            }}
                            error={!!errors.order_id}
                            helperText={errors.order_id}
                        />
                    </div>
                    <div>
                        <div className='filter__title'>
                            Email
                        </div>
                        <TextField
                            inputProps={{ maxLength: 50 }}
                            placeholder='Please enter your email as used in the reservation'
                            variant={typeForm}
                            value={cancelBooking?.email}
                            onChange={(event) =>
                                setCancelBooking({
                                    ...cancelBooking!,
                                    'email': event.target.value
                                })
                            }
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                    </div>
                </div>
                <p className='cancel-booking__agreement'>
                    I agree to cancel my booking effective immediately. Refunds, if
                    applicable, are processed according to our&nbsp;
                    <a href='https://www.sprinter-rentals.com/cancellation'
                        style={{ textDecoration: 'underline', color: '#4b566b' }}
                        target='_blank'
                    >
                        cancellation policies
                    </a>
                    &nbsp;and may take up to 10 days before showing on your credit card.
                </p>
                <p className='cancel-booking__agreement'>
                    Once the booking has been canceled, it cannot be reinstated.
                    You may make a new reservation at any time. <b>Thank you.</b>
                </p>
                <ReCAPTCHA
                    sitekey="6Lf6ATkoAAAAAIh010uJm6Q-BSNmeFb2DC0sh226"
                    onChange={() => setDisable(false)}
                    style={{ marginBottom: '30px' }}
                />
                <Button
                    className='order-cart__button'
                    onClick={handleSubmit}
                    variant='contained'
                    disabled={disable}
                >
                    {isLoading ? (
                        <CircularProgress size={16} />
                    ) : ('Cancel Now')}
                </Button>
            </div>
            <div className='footer'>
                <Footer />
            </div></>
    );
};

export default CancelBookingPage;
