import { FC } from "react";
import { Link } from "react-router-dom";

const ExpiredPage: FC = () => (
  <div className="result-page">
    <img
      className="result-page__img"
      src="./images/stopwatch.gif"
      alt="expired"
    />
    <div className="result-page__main">
      <p>
        We are so sorry, session expired! Your van might still be there, please
        click here to book your van now.
      </p>
    </div>
    <Link to="/" children="Back to home page" />
  </div>
);
export default ExpiredPage;
