import { ISeasonPrice, ISeasonPriceFetch } from "src/models/ISeasonPrice";
import { 
  GetSeasonPriceAction, 
  SeasonPriceActionEnum, 
  ResultGetSeasonPriceAction
} from "./types";

export const SeasonPriceActionCreators = {
  getSeasonPrice: (): GetSeasonPriceAction => ({
    type: SeasonPriceActionEnum.REQUEST_SEASONPRICE,
  }),
  resultGetSeasonPrice: (data: ISeasonPriceFetch[]): ResultGetSeasonPriceAction => ({
    type: SeasonPriceActionEnum.RESULT_SEASONPRICE,
    data,
  }),
};
