import { IDriver, IDriverFetch } from 'src/models/IDriver';
import {
  AddDriverAction,
  DriverActionEnum,
  ResultAddDriverAction,
} from './types';

export const DriverActionCreators = {
  addDriver: (drivers: IDriver[], id: string): AddDriverAction => ({
    type: DriverActionEnum.ADD_DRIVER,
    drivers,
    id,
  }),
  resultAddDriver: (result: IDriverFetch): ResultAddDriverAction => ({
    type: DriverActionEnum.RESULT_ADD_DRIVER,

    driver: result,
  }),
};
