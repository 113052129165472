import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { OrderActionCreators } from "src/store/reducers/order/action-creators";
import { OrderActionEnum } from "src/store/reducers/order/types";

function* fetchAddClient({
  client,
}: ReturnType<typeof OrderActionCreators.addClient>) {
  try {
    const { data, status } = yield instance.post(`client/add`, {
      client,
    });
    yield put(OrderActionCreators.resultAddClient(data, status));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherAddClient() {
  yield takeLatest(OrderActionEnum.REQUEST_ADD_CLIENT, fetchAddClient);
}
