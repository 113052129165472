import { useEffect, useState } from 'react';

const useCloseMenu = (): [boolean, (value: boolean) => void, () => void] => {
  const [closeBar, setCloseBar] = useState(false);

  const handleManual = (value: boolean) => setCloseBar(true);
  const switcher = () => setCloseBar(!closeBar);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.pageYOffset > 0 ? setCloseBar(true) : setCloseBar(false);
    });
  }, []);

  return [closeBar, handleManual, switcher];
};

export default useCloseMenu;
