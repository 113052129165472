import { FC } from 'react';
import './styles.scss';

interface IProps {
  isSmall?: boolean
}

const Logo: FC<IProps> = ({ isSmall }) => (

  <a
    href='https://www.sprinter-rentals.com/'
    target='_blank'
    style={{ height: isSmall ? '30px' : 'auto' }}
  >
    <div className='logo'
    >
      <img
        src={`${window.location.origin}/images/logo1.png`}
        alt='LOGO'
        className={isSmall ? 'logo__small-img' : 'logo__img'}
      />
    </div>
  </a>

);
export default Logo;
