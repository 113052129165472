import { FC, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from 'src/components/Items/Logo';
import useIsMobile from 'src/hooks/useIsMobile';
import { useActions } from 'src/hooks/useActions';
import './styles.scss';
import './media.scss';

const links = [
    {
        'title': '12 Seater',
        'link': 'https://www.sprinter-rentals.com/12-seater-sprinter'
    },
    {
        'title': '12 Seater XL',
        'link': 'https://www.sprinter-rentals.com/van'
    },
    {
        'title': '15 Seater',
        'link': 'https://www.sprinter-rentals.com/15-seater-sprinter'
    },
    {
        'title': '9 Seater VIP Van',
        'link': 'https://www.sprinter-rentals.com/9-seater-business-class-sprinter'
    },
    {
        'title': '11 Seater VIP Van',
        'link': 'https://www.sprinter-rentals.com/11-seater-VIP-van'
    },
    {
        'title': '5 Seater Crew',
        'link': 'https://www.sprinter-rentals.com/crew-cargo-sprinter'
    },
    {
        'title': 'Cargo',
        'link': 'https://www.sprinter-rentals.com/cargo-van'
    },
    {
        'title': 'More',
        'link': 'https://www.sprinter-rentals.com/van-rentals'
    }
];

const SmallHeader: FC = () => {
    const { logOut } = useActions();
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        navigate('/');
        logOut();
    };

    return (
        <div className='smallHeader__wrapper'>
            <div className='smallHeader__wrapper_sup'>
                <div className='smallHeader__container'>
                    <Logo />
                    {!isMobile && (
                        <div className='smallHeader__container__links'>
                            {links.map((link) => (
                                <a href={link.link}
                                    className='smallHeader__container__links__link'
                                    target='_blank'
                                >
                                    {link.title}
                                </a>
                            ))}
                            <div
                                className='smallHeader__container__links__logout'
                                onClick={logout}
                            >
                                <Tooltip title='Log out'>
                                    <ExitToAppOutlinedIcon />
                                </Tooltip>
                            </div>
                        </div>
                    )}
                    <div className='smallHeader__container__button-container'>
                        <Button
                            onClick={() => navigate('/')}
                            variant='contained'
                            className='smallHeader__container__button-container__button'
                        >
                            FIND A VAN
                        </Button>
                    </div>
                    {isMobile && (
                        <div className='smallHeader__burger'>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {links.map((link) => (
                                    <MenuItem onClick={handleClose}>
                                        <a href={link.link}
                                            className='smallHeader__container__links__link'
                                            target='_blank'
                                        >
                                            {link.title}
                                        </a>
                                    </MenuItem>
                                ))}
                                <MenuItem onClick={logout}>
                                    <div className='
                                        smallHeader__container__links__link
                                        smallHeader__container__links__menu-logout
                                    '>
                                        Log out
                                        <ExitToAppOutlinedIcon />
                                    </div>
                                </MenuItem>
                            </Menu>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SmallHeader;
