import { TextField } from "@mui/material";
import { FC } from "react";
import { typeForm } from "src/theme";

interface IProps {
  data: number;
  setData: any;
  min?: number;
  max?: number;
  title?: string;
  placeholder?: string;
  check?: boolean;
  unlimit?: boolean;
  error?: string;
}

const NumberInput: FC<IProps> = ({
  data,
  setData,
  min = 0,
  max,
  title,
  placeholder = title,
  unlimit,
  check,
  error,
}) => {
  const minValue = unlimit ? undefined : min;

  const helperError = () => {
    if (check) {
      if (error) return true;
      else if (!data) return true;
      else return false;
    }
  };
  const helperText = () => {
    if (check) {
      if (error) return error;
      else if (!data) return "Fill the field";
      else return;
    } else return placeholder;
  };

  return (
    <div>
      {title && <div className="filter__title">{title}</div>}
      <TextField
        error={helperError()}
        placeholder={helperText()}
        variant={typeForm}
        autoComplete="off"
        InputProps={{
          inputProps: {
            min: minValue,
            max,
          },
        }}
        value={data ?? 0}
        onChange={(event) => {
          let value = parseInt(event.target.value, 10);
          if (isNaN(value)) value = 0;
          if (minValue && value < minValue) value = minValue;
          if (max && value > max) value = max;
          setData(value);
        }}
      />
    </div>
  );
};
export default NumberInput;
