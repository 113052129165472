import { FC, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import Icons from 'src/common/icons';
import { FormHelperText } from '@mui/material';

interface IProps {
  previousFile: string;
  onChangeField: any;
  handleDeleteFile: any;
  nameUploader: string;
  error?: string;
  title?: string;
  subtitle?: string;
  label?: string;
}
const fileTypes = ['SVG', 'JPG', 'JPEG', 'GIF', 'PNG', 'PDF'];

const UploaderFile: FC<IProps> = (props) => {
  const { nameUploader, onChangeField, previousFile, handleDeleteFile } = props;

  const [gallery, setGallery] = useState(previousFile);

  const uploadFileS = (file: File) => {
    const image = URL.createObjectURL(file);
    setGallery(image);
    onChangeField(file);
  };

  const deleteFile = (url: string) => () => {
    setGallery('');
    onChangeField('');
    handleDeleteFile(url);
  };

  return (
    <>
      {props.title && <div className='multi-upload__title'>{props.title}</div>}
      {props.subtitle && <div className='multi-upload__subtitle'>{props.subtitle}</div>}
      <div className={`multi-upload__wrapper ${props.error ? 'error' : ''}`}>
        {!gallery && (
          <FileUploader
            handleChange={uploadFileS}
            name={nameUploader}
            types={fileTypes}
            maxSize={51e6}
            classes={!!previousFile && 'short'}
            children={
              <div className='uploader__wrapper'>
                <Icons.Folder />
                <div className='uploader__btn'>
                  <div className='uploader__btn__label'>
                    {props.label ?? 'Browse image'}
                  </div>
                </div>
              </div>
            }
          />
        )}
        {props.error && (
          <FormHelperText children={props.error} sx={{ left: 15 }} />
        )}
        {gallery && (
          <div className='attachments__wrapper col-2'>
            <div className='multi-upload__card-preview'>
              <img src={gallery} alt='van' />
              <span
                className='multi-upload__btn_delete'
                onClick={deleteFile(gallery)}
              >
                <Icons.Close classes='multi-upload__icon' />
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UploaderFile;
