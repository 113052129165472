import styled from "@emotion/styled";
import { Button } from "@mui/material";

const SecondTypeButton = styled(Button)({
  background: `white`,
  border: `1px solid #7d879c`,

  "&:hover": {
    backgroundColor: `#ECF2FC`,
    boxShadow: `0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)`,
  },
  "&:active": { backgroundColor: `#DAE8FF` },
  "&:focus": { backgroundColor: `#ECF2FC` },
});

export default SecondTypeButton;
