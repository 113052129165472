import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import Map from "./Map";
import SmallHeader from "./SmallHeader";
import Footer from "src/components/Footer";
import AvailableLocations from "./AvailableLocations";
import PhotosContainer from "./PhotosContainer";
import DetailsContainer from "./DetailsContainer";
import AvailabilityContainer from "./AvailabilityContainer";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import { useActions } from "src/hooks/useActions";
import { initFilter } from 'src/store/reducers/van';
import { Location } from './../../../store/reducers/location/types';
import { handleScroll } from "src/common/utils/others";
import "./styles.scss";
import "./media.scss";

const VanPage: FC = () => {

    const { id } = useParams();
    const checkAnchor = useRef(null);
    const { getLocations, getExtras, setupFilters, getVan, getOneway } = useActions();
    const { van, isFilterValidate } = useTypedSelector((state) => state.van);
    const locations = useTypedSelector((state) => state.location.locationList);
    const [selectedLocations, setSelectedLocations] = useState<Location[]>();

    useEffect(() => {
        setupFilters({ ...initFilter });
        getVan(Number(id));
        getLocations();
        getExtras();
        getOneway();
    }, []);

    useEffect(() => {
        const isScroll = localStorage.getItem('isScroll');
        isScroll === 'true' && !isFilterValidate && (
            setTimeout(() => handleScroll(checkAnchor), 1000),
            setTimeout(() => localStorage.setItem('isScroll', 'false'), 2000)
        );
    }, [isFilterValidate]);

    useEffect(() => {
        setSelectedLocations(
            locations.filter((l) => {
                return van?.tags.some((t) => t.name === l.name);
            })
        ); 
    }, [van, locations]);

    return (
        <>
            {van && selectedLocations ? (
                <>
                    <div className='header'>
                        <SmallHeader />
                    </div>
                    <div className='singleVan'>
                        <div className='singleVan__title-container'>
                            <div>
                                <div className='singleVan__title'>
                                    {van?.mark}
                                </div>
                                <AvailableLocations
                                    location={van?.location}
                                    tags={van?.tags}
                                    className={'singleVan__title-container__description'}
                                />
                            </div>
                            <a href="#check" className='anchor'>
                                <Button variant='contained'>
                                    GET THIS VAN
                                </Button>
                            </a>
                        </div>
                        <PhotosContainer
                            interior={van?.photos.interior}
                            exterior={van?.photos.exterior}
                            className={'singleVan'}
                        />
                        <div className='singleVan__main-info borderBottom'>
                            <h2><b>Make: </b>{van.make ? van.make : 'Mercedes Benz Sprinter'}</h2>
                            <h3>{`${van?.seats} seats | ${van?.cargo_space} cargo space | Year Made: ${van?.year}`}</h3>
                        </div>
                        <div className='singleVan__description borderBottom'>
                            <p>{van?.description}</p>
                        </div>
                        <div ref={checkAnchor}></div>
                        <DetailsContainer features={van.features} className={'singleVan'} />
                        <AvailabilityContainer van={van} selectedLocations={selectedLocations} />
                        <div className='singleVan__map'>
                            <h3>Location</h3>
                            <Map address={van.location} />
                            <div className='singleVan__description singleVan__map__description'>
                                <b>We delivery anywhere, here are just a few pick up points for this van or similar vans. </b><br />
                                <AvailableLocations
                                    location={van?.location}
                                    tags={van?.tags}
                                    style={{ background: '#fff', marginTop: '10px' }}
                                    className={'singleVan__title-container__description'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='footer'>
                        <Footer />
                    </div>
                </>
            ) : (
                <CircularProgress
                    size={100}
                    sx={{ position: 'absolute', top: '50%', left: '50%' }}
                />
            )}
        </>
    );
};
export default VanPage;
